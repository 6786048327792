import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { ref, listAll, deleteObject } from 'firebase/storage';
import './Base.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faHome, faChevronLeft, faCrown, faChevronRight, faSignOutAlt,faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, doc, getDoc,deleteDoc } from 'firebase/firestore'; 
import { useAuth } from '../authContext';

interface SidebarProps {
  onLogout: () => void;
  onHomeClick: () => Promise<void>;
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ onLogout, onHomeClick,className }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [isMinimized, setIsMinimized] = useState(true);
  const user = auth.currentUser;
  const [plan, setPlan] = useState<string | null>(null); // State to track user plan
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    // Set the active button based on the current location
    const path = location.pathname;
    if (path === '/uploadInfo') setActiveButton('upload');          
    else if (path === '/chat') setActiveButton('chat');    
    else if (path === '/') setActiveButton('home');

    const fetchUserPlan = async () => {
      if (user && !user.isAnonymous) {
        const userPlan = await getUserPlan();
        setPlan(userPlan);
      }
    };

    fetchUserPlan();
  }, [location.pathname, user]);

  const getUserPlan = async (): Promise<string> => {
    const currentUser = auth.currentUser;
    if (!currentUser) return 'Starter'; 
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus;
      const chatServiceOrdered = userData?.chatServiceOrdered;
      const civilProOrdered = userData?.civilProOrdered;

      if (subscriptionStatus === true && chatServiceOrdered !== true) {
        return 'Premium';
      }
      if (subscriptionStatus !== true && chatServiceOrdered === true) {
        return 'Chat';
      }
      if (subscriptionStatus === true && chatServiceOrdered === true) {
        return 'PremiumChat';
      }
      if (subscriptionStatus === true && civilProOrdered === true) {
        return 'civilProChat';
      }      
    }
    return 'Starter';
  };
  
  const handleLogout = async () => {
    try {
      const firestore = getFirestore(); // Initialize Firestore
      const currentUser = auth.currentUser;
      
      if (currentUser?.isAnonymous) {
        const userUID = currentUser.uid;
  
        // Delete uploaded files
        const listRef = ref(storage, 'uploads/');
        const res = await listAll(listRef);
        const userFiles = res.items.filter(item => item.name.includes(userUID));
  
        for (const file of userFiles) {
          await deleteObject(ref(storage, file.fullPath));
        }
  
        // Delete generated Q&A files
        const listRef1 = ref(storage, 'Q&A/');
        const res1 = await listAll(listRef1);
        const userFiles1 = res1.items.filter(item => item.name.includes(userUID));
  
        for (const file of userFiles1) {
          await deleteObject(ref(storage, file.fullPath));
        }
  
        // Remove the Firestore document for the anonymous user
        const userDocRef = doc(firestore, 'users', userUID);
        await deleteDoc(userDocRef);
        
        await currentUser.delete(); // Delete the anonymous user
      }
  
      await auth.signOut();
      setIsLoggedIn(false);
      onLogout(); 
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred during logout. Please try again.');
    }
  };
  

  const handleButtonClick = (buttonName: string, path: string) => {
    setActiveButton(buttonName);
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`${className}`}> 
    <div className={`sidebar ${isMinimized ? 'minimized' : ''}`}>
      <nav>
        <ul className="nav">
          <li className="nav-item">
            <button
              title="Home" 
              onClick={() => { handleButtonClick('home', '/'); }}
              className={`nav-button ${activeButton === 'home' ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faHome} className="icon" />
              {!isMinimized && <span>Home</span>}
            </button>
          </li>
          <li className="nav-item">
            <button
              title="Upload" 
              onClick={() => {
                if (isLoggedIn) {
                  handleButtonClick('upload', '/uploadInfo');
                } else {
                  alert('Please Login to Upload Files.');
                }
              }}

              className={`nav-button ${activeButton === 'upload' ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faUpload} className="icon" />
              {!isMinimized && <span>Upload</span>}
            </button>
          </li>

            {(plan === 'Chat'|| plan === 'PremiumChat' || plan === 'civilProChat') && (
            <li className="nav-item">
              <button
                title="Chat" 
                onClick={() => handleButtonClick('chat', '/chat')}
                className={`nav-button ${activeButton === 'chat' ? 'active' : ''}`}
              >
                <FontAwesomeIcon icon={faCommentDots} className="icon" />
                {!isMinimized && <span>Chat</span>}
              </button>
            </li>
          )}

          {user?.isAnonymous && (
            <li className="nav-item">
              <button
                title="Plans" 
                onClick={() => handleButtonClick('plans', '/plans')}
                className={`nav-button ${activeButton === 'plans' ? 'active' : ''}`}
              >
                <FontAwesomeIcon icon={faCrown} className="icon" />
                {!isMinimized && <span>Plans</span>}
              </button>
            </li>
          )}

          {plan === 'Starter' && (
            <li className="nav-item">
              <button
                title="My Plans" 
                onClick={() => handleButtonClick('upgrade', '/my-plan')}
                className={`nav-button ${activeButton === 'upgrade' ? 'active' : ''}`}
              >
                <FontAwesomeIcon icon={faCrown} className="icon" />
                {!isMinimized && <span>Upgrade to Premium</span>}
              </button>
            </li>
          )}

          <li className="nav-item toggle-item">
            <button onClick={toggleSidebar} className="toggle-button">
              <FontAwesomeIcon icon={isMinimized ? faChevronRight : faChevronLeft} />
            </button>
          </li>
        </ul>
      </nav>

      {/* Logout Button */}
      {isLoggedIn && (
      <button title="Log Out" onClick={handleLogout} className={`logout-button ${isMinimized ? 'logout-button-icon-only' : ''}`}>
        <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
        {!isMinimized && <span>Log Out</span>}
      </button>
    )}

    </div>
    </div>
  );
};

export default Sidebar;
