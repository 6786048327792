import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import { FaCheck, FaCopy } from 'react-icons/fa';
import Prism from 'prismjs'; // Import Prism
import 'prismjs/components/prism-javascript'; // JavaScript
import 'prismjs/components/prism-python'; // Python
import 'prismjs/components/prism-bash'; // Bash
import 'prismjs/components/prism-java'; // Java
import 'prismjs/components/prism-css'; // CSS
import 'prismjs/components/prism-json'; // JSON
import 'prismjs/themes/prism-okaidia.css'; // Custom theme

// Codes for formatting outputs
const splitIntoSegments = (text: string): string[] => {
    return text.split(/\n\n/).map(segment => segment.trim()).filter(segment => segment);
  };
  
  const isCodeSnippet = (segment: string): boolean => {
    return segment.startsWith('```') || segment.endsWith('```');
  };
  
  const isImage = (segment: string): boolean => {
    return segment.startsWith('<img src="') && segment.endsWith('">');
  };
  
  const isAudio = (segment: string): boolean => {
    return segment.startsWith('<audio') && segment.endsWith('</audio>');
  };
  
  const extractListNumber = (segment: string | undefined): number | undefined => {
    if (!segment) {
      return undefined;
    }
  
    // Extracts the starting number from a segment that starts with a number followed by a period (e.g., "1. ", "2. ")
    const match = segment.match(/^(\d+)\.\s+/);
    return match ? parseInt(match[1], 10) : undefined;
  };
  
  const isListInSegment = (segment: string | undefined): boolean => {
    if (!segment) {
      return false;
    }
  
    // Match segments that contain multiple numbered list items (e.g., "1. First\n2. Second")
    const matches = segment.match(/(\d+\.\s+)/g);
    return matches !== null && matches.length > 1;
  };

  const formatList = (segments: string[], key: number): JSX.Element => {
    // Custom function to split on "- " only outside of \[ ... \]
    const splitOutsideBrackets = (text: string) => {
      const regex = /- (?![^[]*])/g; // Match "- " only if not within [ ... ]
      const parts = [];
      let lastIndex = 0;
      let match;
    
      while ((match = regex.exec(text)) !== null) {
        parts.push(text.slice(lastIndex, match.index)); // Add text before "- "
        lastIndex = match.index + 2; // Move past "- "
      }
      parts.push(text.slice(lastIndex)); // Add remaining text
    
      return parts;
    };
    
    // Format the collected list segments as a list
    const listItems = segments.map((segment, idx) => {
      // Use custom split function to create sub-list items
      const subListItems = splitOutsideBrackets(segment).map((subItem, subIdx) => {
           //console.log('subItem', subItem);
  
           // Custom function to process text for emphasis and additional formatting
           const renderEmphasizedText = (text: string) => {
            const parts = [];
            let lastIndex = 0;
            const regex = /\*\*(.*?)\*\*:?/g;
            let match;
            
            //console.log('inside text',text)

            while ((match = regex.exec(text)) !== null) {
              const start = match.index;
              const end = regex.lastIndex;
    
              // Add text before the **...**
              if (lastIndex < start) {
                const segment = text.slice(lastIndex, start);
                //console.log('segment',segment)
                const hasMathOrVariable = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment);
                parts.push(
                  <span key={`span-${lastIndex}`}>
                      {hasMathOrVariable ? formatVariablesAndEquations(segment) : segment}
                  </span>
                );
              }
              const segment1 = match[1];
              const includesColon = text[end - 1] === ':'; // Check if the match is followed by a colon
              
              //console.log('segment 1', segment1);
              const hasMathOrVariable1 = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment1);
              
              // Add the emphasized part, appending the colon if it was matched
              parts.push(
                <strong key={`strong-${start}`} style={{ display: 'inline' }}>
                  {hasMathOrVariable1 ? formatVariablesAndEquations(segment1) : segment1}
                  {includesColon && ':'} {/* Append colon if included in match */}
                </strong>
              );
    
              lastIndex = end;
            }
    
            // Add any remaining text after the last **
            if (lastIndex < text.length) {
              const segment2 = text.slice(lastIndex);
              //console.log('segment 2',segment2)

              const hasMathOrVariable2 = /\\\(|\\\[|\\text|_|\^|\\frac|\\sqrt|\\int|\\sum|\\prod|\\theta|\\pi/.test(segment2);
              parts.push(
                <span key={`span-${lastIndex}`}>
                    {hasMathOrVariable2 ? formatVariablesAndEquations(segment2) : segment2}
                </span>
              );
            }
  
            return parts;
          };


        return (
          <li
            key={`sub-list-item-${key}-${idx}-${subIdx}`}
            style={{
              listStyleType: 'none',
              marginLeft: '20px',
              textIndent: '-10px', // Outdent the first line
              paddingLeft: '40px', // Indent the rest of the lines
              fontSize: '18px',
            }}
          >
            {/* Render the emphasized content inline without splitting */}
            {renderEmphasizedText(subItem)}
          </li>
        );
      });
  
      // If there are multiple sub-items, wrap them in a nested <ul>
      return subListItems.length > 1 ? (
        <li
          key={`list-item-${key}-${idx}`}
          style={{
            listStyleType: 'none',
            marginLeft: '10px',
            textIndent: '-10px', // Outdent the first line
            paddingLeft: '40px', // Indent the rest of the lines
          }}
        >
          <ul style={{ paddingLeft: '0' }}>{subListItems}</ul>
        </li>
      ) : (
        subListItems[0] // If only one item, return it as is
      );
    });
  
    return (
      <ul key={`list-${key}`} style={{ paddingLeft: '0' }}>
        {listItems}
      </ul>
    );
  };
  
  
  const extractListItems = (segment: string): string[] => {
    // Extracts list items from a segment with sequential numbers (e.g., "1. First\n2. Second")
    return segment.split(/(?=\d+\.\s+)/);
  };
    
    const formatVariablesAndEquations = (text: string): React.ReactNode => {
      const formattedContent = [];
      const blockRegex = /\\\[([^\]]+?)\\\]/g; // Match \[...\] for blocks
      const inlineRegex = /\\\((.*?)\\\)/g; // Match \( ... \) for inline emphasis
      let lastIndex = 0;

      const formatText = (text: string): string => {
        // Preprocess the text to sanitize invalid LaTeX markers
        const preprocessLaTeX = (input: string): string => {
            return input
                .replace(/\\\(/g, '(') // Replace \( with regular parentheses
                .replace(/\\\)/g, ')') // Replace \) with regular parentheses
                .replace(/\\\[|\\\]/g, '') // Remove block math markers like \[ and \]
                .replace(/\\text\{([^}]+)\}/g, '$1') // Simplify \text{...} to just the text
                .replace(/\\\\/g, '\\'); // Handle double backslashes
        };
    
        const sanitizedText = preprocessLaTeX(text);
    
        // Check if the sanitized text contains LaTeX-specific markers
        const latexRegex = /\\frac|\\sqrt|\\lim|\\sum|\\prod|\\int|\\text|_|\\^/;
        if (!latexRegex.test(sanitizedText)) {
            // If no valid LaTeX markers, use customFormatText for regular formatting
            return customFormatText(sanitizedText);
        }
    
        try {
            // Render LaTeX using KaTeX
            return katex.renderToString(sanitizedText, {
                throwOnError: false, // Skip errors for invalid LaTeX
                displayMode: false,  // Use inline mode
            });
        } catch (err) {
            console.error("KaTeX Error:", err);
            // Fallback to customFormatText if KaTeX fails
            return customFormatText(sanitizedText);
        }
    };
    

      // Helper function to process subscripts, superscripts, and basic replacements
      const customFormatText = (input: string) => {
        return input
          .replace(/\\textit{([^}]+)}/g, '<em>$1</em>') // Italicize text 
          .replace(/\\bar{([^}]+)}/g, '<span class="overline">$1</span>') // Overline
          .replace(/\\leftharpoons/g, '⇄')                   // Reversible reaction
          .replace(/\\%/g, '%')                             // Percent symbol
          .replace(/\\left/g, '')                           // Remove \left
          .replace(/\\right/g, '')                          // Remove \right
          .replace(/\\quad/g, '<span class="quad-space"></span>') // Quad space
          .replace(/\\\(([^)]+)\\\)/g, '<span>$1</span>')  // Inline math
          .replace(/\\text{([^}]+)}/g, '$1')            // Plain text
          .replace(/\\cdot/g, '·')                      // Dot symbol
          .replace(/\\times/g, '×')                     // Multiplication symbol
          .replace(/\\,/g, ' ')                         // Space
          .replace(/\\approx/g, '≈')                    // Approximately equal symbol
          .replace(/_([a-zA-Z0-9]+)/g, '<sub>$1</sub>') // Single-character subscripts
          .replace(/_\{([^}]+)\}/g, '<sub>$1</sub>')    // Multi-character subscripts
          .replace(/([a-zA-Z0-9])\^\{?([+−*/])\}?/g, '$1<sup>$2</sup>') // Superscript for any letter/number followed by ^+ or ^-
          .replace(/\^([a-zA-Z0-9]+)/g, '<sup>$1</sup>') // Single-character superscripts
          .replace(/\^\{([^}]+)\}/g, '<sup>$1</sup>')   // Multi-character superscripts
          .replace(/\^\{\\circ\}/g, '<sup>°</sup>')           // Degree symbol as superscript (\^{\circ})
          .replace(/\^\\circ/g, '<sup>°</sup>')               // Degree symbol as superscript (^circ)
          .replace(
            /\\sqrt{([^}]+)}/g,
            (match, content) =>
              `<span class="sqrt-wrapper">` +
                `<span class="sqrt-symbol">√</span>` +
                `<span class="sqrt-overline"></span>` +
                `<span class="sqrt-content">${content.replace(/_/g, '<sub>').replace(/\^/g, '<sup>')}</span>` +
              `</span>`
          )

          .replace(/\\leq/g, '≤')                       // Less than or equal
          .replace(/\\geq/g, '≥')                       // Greater than or equal
          .replace(/\\neq/g, '≠')                       // Not equal
          .replace(/\\infty/g, '∞')                     // Infinity
          .replace(/\\pi/g, 'π')                        // Pi
          .replace(/\\alpha/g, 'α')                     // Greek letter alpha
          .replace(/\\beta/g, 'β')                      // Greek letter beta
          .replace(/\\gamma/g, 'γ')                     // Greek letter gamma
          .replace(/\\delta/g, 'δ')                     // Greek letter delta
          .replace(/\\epsilon/g, 'ε')                   // Greek letter epsilon
          .replace(/\\lambda/g, 'λ')                    // Greek letter lambda
          .replace(/\\mu/g, 'μ')                        // Greek letter mu
          .replace(/\\tau/g, 'τ')                           // Greek letter tau
          .replace(/\\theta/g, 'θ')                     // Greek letter theta
          .replace(/\\sigma/g, 'σ')                     // Greek letter sigma
          .replace(/\\Delta/g, 'Δ')                     // Uppercase Greek letter Delta
          .replace(/\\int/g, '∫')                       // Integral symbol
          .replace(/\\int_([a-zA-Z0-9]+)\\sup([a-zA-Z0-9]+)/g, '∫<sub>$1</sub><sup>$2</sup>') // Integral with limits
          .replace(/\\int_\{([^}]+)\}\^\{([^}]+)\}/g, '∫<sub>$1</sub><sup>$2</sup>') // Multi-character limits for integrals
          .replace(/\\sum/g, '∑')                       // Summation symbol
          .replace(/\\prod/g, '∏')                      // Product symbol
          .replace(/\\partial/g, '∂')                   // Partial derivative symbol
          .replace(/\\rightarrow/g, '→')                // Right arrow
          .replace(/\\leftarrow/g, '←')                 // Left arrow
          .replace(/\\Rightarrow/g, '⇒')                // Double right arrow
          .replace(/\\Leftarrow/g, '⇐')                 // Double left arrow
          .replace(/\\pm/g, '±')                        // Plus-minus symbol
          .replace(/\\mp/g, '∓')                        // Minus-plus symbol
          .replace(/\\div/g, '÷')                       // Division symbol
          .replace(/\\forall/g, '∀')                    // Universal quantifier
          .replace(/\\exists/g, '∃')                    // Existential quantifier
          .replace(/\\nabla/g, '∇')                     // Nabla (gradient) symbol
          .replace(/\\circ/g, '∘')                      // Circle operator
          .replace(/\\cup/g, '∪')                       // Union
          .replace(/\\cap/g, '∩')                       // Intersection
          .replace(/\\subset/g, '⊂')                    // Subset
          .replace(/\\supset/g, '⊃')                    // Superset
          .replace(/\\subseteq/g, '⊆')                  // Subset or equal
          .replace(/\\supseteq/g, '⊇')                  // Superset or equal
          .replace(/\\log/g, 'log')                     // Logarithm (log)
          .replace(/\\ln/g, 'ln')                       // Natural log (ln)
          .replace(/\\exp/g, 'exp')                     // Exponential (exp)
          .replace(/\\ldots/g, '…')                     // Ellipsis (…)
          .replace(/\\sin/g, 'sin')                     // Sine
          .replace(/\\cos/g, 'cos')                     // Cosine
          .replace(/\\tan/g, 'tan')                     // Tangent
          .replace(/\\csc/g, 'csc')                     // Cosecant
          .replace(/\\sec/g, 'sec')                     // Secant
          .replace(/\\cot/g, 'cot')                     // Cotangent
          .replace(/\\arcsin/g, 'arcsin')               // Inverse sine
          .replace(/\\arccos/g, 'arccos')               // Inverse cosine
          .replace(/\\arctan/g, 'arctan')               // Inverse tangent
          .replace(/\\sinh/g, 'sinh')                   // Hyperbolic sine
          .replace(/\\cosh/g, 'cosh')                   // Hyperbolic cosine
          .replace(/\\tanh/g, 'tanh');                  // Hyperbolic tangent
      };
    
    // Process block equations \[...\]
    text.replace(blockRegex, (match, blockContent, index) => {
      // Add any preceding text as regular text, formatted with formatText
      if (index > lastIndex) {
        // Format the preceding text with formatText
        const precedingText = formatText(text.slice(lastIndex, index));
      
        // Render the formatted preceding text, applying it as HTML
        formattedContent.push(
          <span
            key={`${lastIndex}-text`}
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: precedingText }}
          />
        );
      }
  
      // Process block content and add centered equation
      const formattedEquation = formatText(blockContent);
      formattedContent.push(
        <div key={index} style={{ textAlign: 'center', margin: '1em 0' }}>
          <span dangerouslySetInnerHTML={{ __html: formattedEquation }} />
        </div>
      );
  
      lastIndex = index + match.length;
      return '';
    });
  
    // Process remaining text after block matches, including inline matches
    let remainingText = text.slice(lastIndex);
    lastIndex = 0;
    remainingText.replace(inlineRegex, (match, inlineContent, index) => {
      // Add any preceding text as regular text, formatted with formatText
      if (index > lastIndex) {
        const precedingText = formatText(remainingText.slice(lastIndex, index));

        //console.log('precedingText',precedingText)
        formattedContent.push(
          <span key={`${lastIndex}-inline-text`} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: precedingText }} />
        );
      }
  
      // Process inline content as emphasized text
      const formattedInline = formatText(inlineContent);
      formattedContent.push(
        <em key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: formattedInline }} />
      );
  
      lastIndex = index + match.length;
      return '';
    });
     
    // Add any remaining text after the last inline match, formatted with formatText
    if (lastIndex < remainingText.length) {
      const remainingFormattedText = formatText(remainingText.slice(lastIndex));
      formattedContent.push(
        <span key="remaining-inline-text" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: remainingFormattedText }} />
      );
    }
  
    return <span>{formattedContent}</span>;
  };
  
  export const formatContent = (
    text: string, 
    copiedKey: number | null, 
    setCopiedKey: (key: number | null) => void
  ): React.ReactNode => {

    const handleCopy = (key: number, code: string) => {
        navigator.clipboard
          .writeText(code)
          .then(() => {
            setCopiedKey(key); // Update the copied state
            setTimeout(() => {
              setCopiedKey(null); // Reset the copied state after 2 seconds
            }, 2000);
          })
          .catch((err) => {
            console.error("Failed to copy to clipboard: ", err);
          });
      };


      const formatCodeSnippet = (
        segment: string, 
        key: number, 
        copiedKey: number | null, 
        handleCopy: (key: number, code: string) => void
    ): JSX.Element => {
        // Extract language and code from the segment
        const [, lang = '', code = ''] = segment.match(/^```(.*?)\n([\s\S]*?)```$/) || [];
        
        console.log('Segment:', segment); // Debugging
        console.log('Language:', lang); // Debugging
        console.log('Code:', code); // Debugging
    
        // Determine the language for syntax highlighting
        const language = Prism.languages[lang.trim()] ? lang.trim() : 'plaintext';
        const highlightedCode = Prism.highlight(code.trim(), Prism.languages[language], language);
    
        console.log('Highlighted Code:', highlightedCode); // Debugging
    
        const header = (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                backgroundColor: '#1E1E1E', 
                padding: '5px 10px', 
                borderTopLeftRadius: '5px', 
                borderTopRightRadius: '5px' 
            }}>
                <span style={{ color: '#9CDCFE' }}>{lang.trim() || 'plaintext'}</span>
                <button 
                    onClick={() => handleCopy(key, code.trim())}
                    style={{ 
                        backgroundColor: 'transparent', 
                        color: 'white', 
                        border: 'none', 
                        cursor: 'pointer', 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                >
                    {copiedKey === key ? <FaCheck /> : <FaCopy />}
                    <span style={{ marginLeft: '5px' }}>
                        {copiedKey === key ? 'Copied' : 'Copy'}
                    </span>
                </button>
            </div>
        );
    
        return (
            <div 
                key={`code-container-${key}`} 
                style={{ 
                    backgroundColor: '#1E1E1E', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    width: '90%', 
                    margin: '10px auto', 
                    color: 'white', 
                    wordBreak: 'break-word', 
                    overflowX: 'auto' 
                }}
            >
                {header}
                <pre style={{ margin: 0 }}>
                    <code 
                        dangerouslySetInnerHTML={{ __html: highlightedCode }} 
                        style={{ 
                            whiteSpace: 'pre-wrap', 
                            wordWrap: 'break-word', 
                            fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace' 
                        }}
                    />
                </pre>
            </div>
        );
    };




    const segments = splitIntoSegments(text);
    let inCodeBlock = false;
    let codeBlockContent = '';
    let listSegments: string[] = [];
    let expectedListNumber = 1;
    let result: React.ReactNode[] = [];
  
    const removeExtraNewlines = (str: string) => str.replace(/\n+/g, ' ').trim();
  
    const pushFormattedContent = (formattedSegment: string, index: number) => {
      formattedSegment = removeExtraNewlines(formattedSegment); // Remove all extra newlines

      if (formattedSegment.includes('- ')) {
        const listItems = formattedSegment.split(/\n+/)
          .filter(item => item.trim().startsWith('- ') && !/^- \d/.test(item.trim())) 
          .map((item, i) => {
            const content = removeExtraNewlines(item.replace(/^- /, ''));
            const parts = content.split(/\*\*(.*?)\*\*:?/g);
            return (
              <li key={`list-item-${index}-${i}`}>
                {parts.map((part, j) =>
                  j % 2 === 1 ? (
                    <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                      {formatVariablesAndEquations(part)}
                    </strong>
                  ) : (
                    <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                      {formatVariablesAndEquations(part)}
                    </span>
                  )
                )}
              </li>
            );
          });
      
        result.push(
          <ul key={`list-${index}`} style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {listItems}
          </ul>
        );
      }else {

        const parts = formattedSegment.split(/\*\*(.*?)\*\*/g);
       // console.log('parts',parts) 

        result.push(
          <div key={`segment-${index}`}>
            {parts.map((part, j) =>
              j % 2 === 1 ? (
                <strong key={`strong-${index}-${j}`} style={{ display: 'inline' }}>
                  {formatVariablesAndEquations(part)}
                </strong>
              ) : (
                <span key={`span-${index}-${j}`} style={{ display: 'inline' }}>
                  {formatVariablesAndEquations(removeExtraNewlines(part))}
                </span>
              )
            )}
          </div>
        );
      }
    };
  
    segments.forEach((segment, index) => {
      if (!segment) return;
  
      if (inCodeBlock) {
        codeBlockContent += '\n' + segment;
        if (segment.endsWith('```')) {
          inCodeBlock = false;
          const formattedCode = formatCodeSnippet(codeBlockContent, index, copiedKey, handleCopy);
          codeBlockContent = '';
          result.push(formattedCode);
        }
        return;
      }
  
      if (isCodeSnippet(segment)) {
        if (segment.startsWith('```') && segment.endsWith('```')) {
          result.push(formatCodeSnippet(segment, index, copiedKey, handleCopy));
          return;
        } else if (segment.startsWith('```')) {
          inCodeBlock = true;
          codeBlockContent = segment;
          return;
        }
      }
  
      let formattedSegment = segment.trim();
      
     //console.log('formatted Segment',formattedSegment)
      
      if (formattedSegment.startsWith('###')) {
        const firstNewlineIndex = formattedSegment.indexOf('\n');
        let headingText = formattedSegment;
        let remainingText = '';
  
        if (firstNewlineIndex !== -1) {
          headingText = formattedSegment.substring(0, firstNewlineIndex);
          remainingText = formattedSegment.substring(firstNewlineIndex + 1);
        }
        //console.log('headingtext', headingText);

        let headingContent = headingText
          .trimStart()                   // Remove any leading whitespace
          .replace(/^#{3,4}\s*/, '')     // Remove "### " or "#### " at the beginning
          .replace(/\*\*/g, '');         // Remove all ** markers
        
        result.push(
          headingText.startsWith('####') ? (
            <h4 key={`heading-${index}`}>
              {formatVariablesAndEquations(headingContent)}
            </h4>
          ) : (
            <h3 key={`heading-${index}`}>
              {formatVariablesAndEquations(headingContent)}
            </h3>
          )
        );
        
        formattedSegment = remainingText ? remainingText : '';
      }
      //console.log('formatted Segment',formattedSegment)

      const currentListNumber = extractListNumber(formattedSegment);
      if (currentListNumber === expectedListNumber || isListInSegment(formattedSegment)) {
        const extractedListSegments = extractListItems(formattedSegment);
        listSegments = listSegments.concat(extractedListSegments);
        //console.log('list segments',listSegments)

        const lastListSegment = extractListNumber(listSegments[listSegments.length - 1]);
        expectedListNumber = lastListSegment !== undefined ? lastListSegment + 1 : 1;
  
        const nextSegment = segments[index + 1];
        const nextListNumber = nextSegment ? extractListNumber(nextSegment) : undefined;
  
        if (!nextSegment || nextListNumber !== expectedListNumber) {
          const formattedList = formatList(listSegments, index);
          listSegments = [];
          expectedListNumber = 1;
          result.push(formattedList);
        }
        return;
      }
  
      if (isImage(formattedSegment)) {
        result.push(<div key={`image-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      } else if (isAudio(formattedSegment)) {
        result.push(<div key={`audio-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      }
  
      pushFormattedContent(formattedSegment, index);
    });
  
    return result.length ? result : undefined;

};
