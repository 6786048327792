import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';
import './Base.css';
import { useNavigate } from 'react-router-dom';
import Auth from './Auth';

const CoffeeBreak: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds (300 seconds)
  
  const handleViewHomePage = () => {
    navigate('/home');
  };

  // UseEffect to handle timeout and countdown timer
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // Update every second

    const timeout = setTimeout(() => {
      navigate('/home'); // Redirect after 5 minutes
    }, 300000); // 5 minutes in milliseconds

    // Cleanup both the timer and timeout when the component unmounts
    return () => {
      clearInterval(timerInterval);
      clearTimeout(timeout);
    };
  }, [navigate]);

  // Convert timeLeft from seconds to minutes and seconds format
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  useEffect(() => {
    const fetchFilesCount = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
    };
    fetchFilesCount();
  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setUserImage(loadedImageUrl);
  };

  // Modern and stylish suggestions for the coffee break
  const breakSuggestions = [
    "✨ Stretch those legs and move around!",
    "🌿 Breathe deeply, refresh your mind.",
    "💧 Grab a quick drink or snack to stay energized.",
    "💬 Say hi to a colleague or a friend.",
    "🔄 Organize your desk for a cleaner workspace."
  ];

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
        <div className="content">
          <div className="content-inner">
            <h1 className="break-title">Enjoy your Coffee Break ☕</h1>
            <p className="break-timer">
              You will be redirected back to the home page in {minutes}:{seconds < 10 ? `0${seconds}` : seconds} minutes.
            </p>

            <div className="suggestions-container">
              {breakSuggestions.map((suggestion, index) => (
                <p key={index} className="suggestion-item">{suggestion}</p>
              ))}
            </div>
          </div>

          <p 
            onClick={handleViewHomePage} 
            className="home-page-link"
          >
            Go to Home Page
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoffeeBreak;



