import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Assuming Firestore is initialized in firebaseConfig
import NavBar from './NavBar';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import './Base.css';

// Define the type for a Subscription
interface Subscription {
  id: string;
  status: string;
  current_period_end: number;
  customer: string;
  collection_method: string;
  created: number;
  latest_invoice: string;
  currency: string;
  plan?: {
    id: string;
    nickname: string;
    amount: number;
  };
}

// Define the type for an Invoice
interface Invoice {
  id: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
}

const AccountSubscription: React.FC<{ subscription: Subscription }> = ({ subscription }) => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null); 

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(`/api/invoice?invoiceId=${subscription.latest_invoice}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch invoice: ${response.statusText}`);
        }
        const data: Invoice = await response.json();
        setInvoice(data);
      } catch (error: unknown) {
        console.error('Error fetching invoice:', error);
      }
    };

    if (subscription.latest_invoice) {
      fetchInvoice();
    }
  }, [subscription.latest_invoice]);



// Fetch the subscription status of the user from Firestore
useEffect(() => {
  const fetchSubscriptionStatus = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus || 'Starter'; // Default to "Starter"
      setSubscriptionStatus(subscriptionStatus);
    }
  };

  fetchSubscriptionStatus();
}, []);

  return (
    <section className="subscription-container">
      <hr className="divider" />
      <p>
        Status: <span className="text-highlight">{subscription.status}</span>
      </p>
      <p>
        Plan: <span>{subscriptionStatus}</span> -{' '}
        {subscription.plan?.amount ? `$${subscription.plan.amount / 100}` : 'N/A'} <span>/month</span>
      </p>
      <p>
        Current Period End:{' '}
        <span className="text-highlight">
          {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
        </span>
      </p>
      {invoice && (
        <p>
          <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer" className="invoice-link">
            View Invoice
          </a>
        </p>
      )}
      <Link
        to={'/billing-cancel'}
        state={{ subscription: subscription.id }}
        className="subscription-cancel-link"
      >
        Cancel
      </Link>
    </section>
  );
};

const BillingAccount: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleProfileLoad = (
    loadedFirstName: string,
    loadedLastName: string,
    loadedImageUrl: string,
    loadedBio: string
  ) => {
    setUserImage(loadedImageUrl);
  };

  useEffect(() => {

    const fetchData = async () => {
      const firestore = getFirestore();
      const currentUser = auth.currentUser;
    
      if (!currentUser) {
        setError('User is not authenticated');
        return;
      }
    
      try {
        // Check if the user is an admin
        const adminDocRef = doc(firestore, 'admin', 'auth');
        const adminDoc = await getDoc(adminDocRef);
        let isAdminStaff;
        if (adminDoc.exists()) {
          const adminData = adminDoc.data();
          //console.log('admin data emails',adminData.emails)
          if (adminData.emails.includes(currentUser.email)) {
            isAdminStaff = true;
          }
        }

       const userDocRef = doc(firestore, 'users', currentUser.uid);

       if(!isAdminStaff){
        const userDocSnapshot = await getDoc(userDocRef);
    
        if (!userDocSnapshot.exists()) {
          setError('User profile not found in Firestore');
          return;
        }
    
        const userData = userDocSnapshot.data();
        const customerId = userData?.customerId;
    
        if (!customerId) {
          setError('Customer ID not found in user profile');
          return;
        }
    
        const response = await fetch(`/api/subscriptions?customerId=${customerId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch subscriptions: ${response.statusText}`);
        }
    
        const data: Subscription[] = await response.json();
        setSubscriptions(data);
        let subscriptionStatus = 'Starter';
    
        const hasValidSubscription = data.some((subscription) => {
          if (
            subscription.status === 'active' ||
            subscription.current_period_end * 1000 > Date.now()
          ) {
            if (subscription.plan?.id === 'price_1PwDYyGdz3azwGbGveFCaN7U') {
              subscriptionStatus = 'Premium';
            }
            if (subscription.plan?.id === 'price_1QS6hrGdz3azwGbGAl5GZNdj') {
              subscriptionStatus = 'Chat';
            }
            if (subscription.plan?.id === 'price_1QS6sGGdz3azwGbGVSVWvhIu') {
              subscriptionStatus = 'ProChat';
            }
            return true;
          }
          return false;
        });
    
        if (!hasValidSubscription) {
          subscriptionStatus = 'Starter';
        }
    
        await updateDoc(userDocRef, {
          subscriptionStatus: subscriptionStatus,
        });
       } else{
        await updateDoc(userDocRef, {
          subscriptionStatus: 'ProChat',
        });

       }

      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container" style={{ background: 'black' }}>
      <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />
      <div className="main-content">
        <div className="content-inner">
          <h1 className="billing-title">Your Subscription</h1>
          <div id="subscriptions">
            {error ? (
              <p className="error-message">{error}</p>
            ) : subscriptions.length === 0 ? (
              <p className="no-subscriptions">No subscriptions found.</p>
            ) : (
              subscriptions.map((s) => <AccountSubscription key={s.id} subscription={s} />)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAccount;
