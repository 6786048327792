import React, { useState, useEffect, useRef } from 'react';
import { auth} from '../firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import './Base.css';

const Front: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [typingText, setTypingText] = useState('');
  const indexRef = useRef(0);
  const charIndexRef = useRef(0);
  const phaseRef = useRef('first');
  const [firstLine, setFirstLine] = useState('');
  const typingIntervalRef = useRef<number | null>(null);
  const navigate = useNavigate();

  const animationTexts = [
    { first: 'Improve your capabilities', second: 'to learn faster.' },
    { first: 'Help find an answer', second: 'for a difficult problem.' },
    { first: 'Generate multiple-choice questions', second: 'to better understand the material.' },
    { first: 'Create a summary', second: 'for a PDF, audio, or video.' },
    { first: 'Find a document', second: 'shared by someone just like you.' },
    { first: 'Have your own AI assistant', second: 'to help you with your tasks.' }
  ];
  
  useEffect(() => {
  
    const startTypingSecondLine = () => {
      const currentIndex = indexRef.current;
      const { second } = animationTexts[currentIndex];
  
      charIndexRef.current = 0;
      setTypingText('');
  
      typingIntervalRef.current = window.setInterval(() => {
        if (charIndexRef.current < second.length) {
          const nextChar = second[charIndexRef.current];
          setTypingText((prev) => prev + nextChar);
          charIndexRef.current += 1;
        } else {
          // Finished typing the second line
          if (typingIntervalRef.current !== null) {
            window.clearInterval(typingIntervalRef.current);
            typingIntervalRef.current = null;
          }
  
          // Wait 2 seconds, then remove both lines and move to next
          window.setTimeout(() => {
            setFirstLine('');
            setTypingText('');
  
            indexRef.current = (indexRef.current + 1) % animationTexts.length;
            showNextPair();
          }, 2000);
        }
      }, 100);
    };
  
    const showNextPair = () => {
      setFirstLine(animationTexts[indexRef.current].first);
      startTypingSecondLine(); // Do not change this line
    };
    
    showNextPair();
    return () => {  
      if (typingIntervalRef.current !== null) {
        window.clearInterval(typingIntervalRef.current);
      }
    };
  }, [auth, navigate]);
  
  return (
    <div className="front-container">
      {/* Left Animation Window */}
      <div className="animation-window">
      <div className="window-label">CompreQnA <span className="typing-circle"></span></div>
        <div className="animation-text">
          <h1>{firstLine}</h1>
          <h1 className="typing-text">{typingText}<span className="typing-circle"></span></h1>
          
        </div>
      </div>

      {/* Right Sign-in/Sign-up Container */}
      <div className="auth-box">
        <h1 className="get-started">Get Started</h1>

        {/* Login and Signup buttons */}
        <div className="auth-buttons">
          <button onClick={() => {
            setIsLogin(true); 
            navigate('/auth', { state: { isLogin: true } });
            }}>Login</button>
          <button onClick={() => {
            setIsLogin(false);
            navigate('/auth', { state: { isLogin: false } });
            }}>Signup</button>
        </div>

        <p className="try-it-first">Try it first</p>

        {/* Logo and Footer Links */}
        <div className="footer">
          <img src={Logo} alt="CompreQnA Logo" className="footer-logo" />
          <p className="footer-title">CompreQnA</p>
          <div className="footer-links">
            <Link to="/support">Support</Link>            
            <Link to="/terms">Terms</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Front;