import React, { useState, useCallback,useEffect,useRef} from 'react';
import { Worker, Viewer} from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { printPlugin } from '@react-pdf-viewer/print';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { getDownloadURL, ref,getMetadata, listAll,uploadString, uploadBytes,updateMetadata,getStorage} from 'firebase/storage';
import { auth, storage } from '../firebaseConfig';
import { FaCommentDots, FaPaperPlane,FaHandPaper, FaExpand,FaCrop,FaPrint, FaDownload,FaVolumeUp, FaClipboardCheck, FaFolderOpen,FaClipboard,FaBackward,FaPlay,FaForward,FaPause,FaEyeSlash, FaEye,FaEraser} from 'react-icons/fa';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'; 
import { EventEmitter } from 'events';
import ReactPlayer from 'react-player/youtube';
import { PDFDocument, rgb} from 'pdf-lib';
import * as fontkit from 'fontkit'; 
import CustomAudioMotionAnalyzer from './AudioMotionAnalyzer'; // Adjust import path if necessary
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import { formatContent } from './FormatContent';
import { getAuth } from "firebase/auth";
import Qna from './Qna'; 
import TranscriptText from './TranscriptText'; 
import html2canvas from 'html2canvas';
import OpenAI from "openai";
import { onAuthStateChanged} from 'firebase/auth';
import {getScrollablePdfContainer,uploadFileToOpenAI,retryRequest,filterUnsupportedCharacters,transcribeAudioChunkWithWhisper,
       fetchAudioFromProxy,transcribeAudioWithOpenAI,extractTextWithFormatting,
       } from './UtilityFunctions'
      
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
});

const deepseekai = new OpenAI({ 
  apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
  dangerouslyAllowBrowser: true,
});


interface ChatMessage {
    message: string;
    from: 'system' | 'user' | 'assistant' ;
    type?: 'text' | 'image'; 
}

interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    fileID: string;
    threadID: string; 
    vectorStore:string; 
}


interface FileData {
  name: string;
  userId: string;
  url: string;
  youtubeUrl: string;
  thumbnailUrl:string;
  refPath: string;
  createdAt: Date; 
  viewCount: number;
  documentType: string; 
  shareWithPublic: string; 
  userImageUrl: string; 
  qnaUrl:string;
  summaryUrl:string;   
  transcriptUrl:string;  
  fileTags:string;
  size:number;
  fileNumber:string;
  duration:number;
}

interface PdfViewerModalProps {
  isOpen: boolean;
  isCollecting: boolean;
  isDownloading: boolean;
  pdfUrl: string | null;
  fileName: string; 
  fileDocumentType: string;    
  qnaStatus: Record<string, boolean>; 
  summaryStatus: Record<string, boolean>; 
  setSummaryStatus: React.Dispatch<React.SetStateAction<Record<string, boolean>>>; 
  onClose: () => void;
  onGenerateQuestions: (fileUrl: string) => void; 
  onGenerateSummaries: (fileUrl: string) => void; 
  onCollectFile: (fileUrl: string,fileName:string,documentType:string) => void; 
  onDownloadFile: (fileUrl: string) => void; 
  currentFile: FileData | null;
  setCurrentFile: React.Dispatch<React.SetStateAction<FileData | null>>; 
  transcriptUrl: string;
  setTranscriptUrl: React.Dispatch<React.SetStateAction<string>>; 
  showPublic: boolean;
  isLoggedIn: boolean;
}

interface Event {
    event: string;
    data: any; // Adjust 'any' to a more specific type if you know the structure of 'data'
  }

  interface ToolCall {
    id: string;
    function: {
      name: string;
    };
  }
  
  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }

  interface LibraryItemType {
    question: string;
    options?: { [key: string]: string };
    answer: string;
  }

class EventHandler extends EventEmitter {
    client: OpenAI;
    constructor(client: OpenAI) {
      super();
      this.client = client;
    }
  
    async onEvent(event: Event) {
      try {
        //console.log(event);
        // Retrieve events that are denoted with 'requires_action'
        // since these will have our tool_calls
        if (event.event === "thread.run.requires_action") {
          await this.handleRequiresAction(
            event.data as RequiresActionData,
            event.data.id,
            event.data.thread_id,
          );
        }
      } catch (error) {
        console.error("Error handling event:", error);
      }
    }
  
    async handleRequiresAction(data: RequiresActionData, runId: string, threadId: string) {
      try {
        const toolOutputs = data.required_action.submit_tool_outputs.tool_calls.map((toolCall) => {
          if (toolCall.function.name === "getCurrentTemperature") {
            return {
              tool_call_id: toolCall.id,
              output: "57",
            };
          } else if (toolCall.function.name === "getRainProbability") {
            return {
              tool_call_id: toolCall.id,
              output: "0.06",
            };
          }
          return null; // Add a default return value
        }).filter(Boolean); // Filter out any null values
    
        // Submit all the tool outputs at the same time
        await this.submitToolOutputs(toolOutputs, runId, threadId);
      } catch (error) {
        console.error("Error processing required action:", error);
      }
    }
    
    async submitToolOutputs(toolOutputs: any[], runId: string, threadId: string) {
      try {
        // Use the submitToolOutputsStream helper
        const stream = this.client.beta.threads.runs.submitToolOutputsStream(
          threadId,
          runId,
          { tool_outputs: toolOutputs },
        );
        for await (const event of stream) {
          this.emit("event", event);
        }
      } catch (error) {
        console.error("Error submitting tool outputs:", error);
      }
    }
  }


const db = getFirestore();

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({ 
  isOpen, 
  isCollecting,
  isDownloading,
  pdfUrl,
  fileName,
  fileDocumentType,
  qnaStatus,
  summaryStatus,
  setSummaryStatus,
  onClose,
  onGenerateQuestions,
  onGenerateSummaries,
  onCollectFile,
  onDownloadFile,
  currentFile,
  setCurrentFile,
  transcriptUrl,
  setTranscriptUrl,
  showPublic,
  isLoggedIn,
  }) => {

  const toolbarPluginInstance = toolbarPlugin();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isQnaOpen, setIsQnaOpen] = useState(false);   
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);    
  const [newMessage, setNewMessage] = useState('');
  const [systemMessage, setSystemMessage] = useState<string>('');
  const [assistant, setAssistant] = useState<Assistant | null>(null);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false); // Track assistant loading
  const [deepseekChat, setDeepseekChat] = useState<ChatMessage[]>([]);
  const [isSendDisabled, setIsSendDisabled] = useState(true); // Disable send button until assistant is ready
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [selectionPosition, setSelectionPosition] = useState<{ top: number, left: number } | null>(null);
  const [copiedKey, setCopiedKey] = useState<number | null>(null); // Track which code snippet is copied
  const [showPreloadedMessage, setShowPreloadedMessage] = useState(true);  
  const [selectionWithinPDF, setSelectionWithinPDF] = useState(false); 
  const [plan, setPlan] = useState<string | null>(null); // State to track user plan
  const [isPanning, setIsPanning] = useState(false); // Track panning mode
  const [currentZoom, setCurrentZoom] = useState(1.0); // Track current zoom level
  const mainPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const summaryPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const dummyDivRef = useRef<HTMLDivElement | null>(null); // Reference for the dummy div
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [firstName, setFirstName] = useState<string | null>(null); 
  const [copiedResponseIndex, setCopiedResponseIndex] = useState<number | null>(null); // Track copied response index
  const [isAssistantThinking, setIsAssistantThinking] = useState(false); 
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [audioInstance, setAudioInstance] = useState<SpeechSynthesisUtterance | null>(null); 
  const [pdfViewerWidth, setPdfViewerWidth] = useState(90); // Initial width in percentage
  const resizingRef = useRef(false);
  const [activeViewer, setActiveViewer] = useState<'main' | 'summary'>('main');
  const mainZoomPlugin = zoomPlugin();
  const summaryZoomPlugin = zoomPlugin();
  const { ZoomInButton: MainZoomInButton, ZoomOutButton: MainZoomOutButton} = mainZoomPlugin;
  const { ZoomInButton: SummaryZoomInButton, ZoomOutButton: SummaryZoomOutButton } = summaryZoomPlugin;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1.0);
  const [metadataDuration, setMetadataDuration] = useState<number | null>(null); 
  const [playingAudio, setPlayingAudio] = useState<string | null>(null);
  const [audioProgress, setAudioProgress] = useState<number>(0); // Audio progress state
  const playerRef = useRef<ReactPlayer | null>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItemType[]>([]);
  const [showAnswerIndexes, setShowAnswerIndexes] = useState<number[]>([]);
  const [selectedSubject] = useState<string | null>(null);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [userUID, setUserUID] = useState<string>('');
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showTranscriptNote, setShowTranscriptNote] = useState<boolean>(false);  
  const [showTextArea, setShowTextArea] = useState<boolean>(true);
  const audioMotionAnalyzerRef = useRef<AudioMotionAnalyzer | null>(null);
  const [showSimpleAudioPlayer, setShowSimpleAudioPlayer] = useState<boolean>(true);
  const [showMotionAudioPlayer, setShowMotionAudioPlayer] = useState<boolean>(false);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]); 
  const hasProcessedFileRef = useRef<string | null>(null);
  const isTranscribingRef = useRef(false); 
  const [mainTotalPages, setMainTotalPages] = useState<number>(0);
  const [mainCurrentPage, setMainCurrentPage] = useState<number>(1);
  const [summaryTotalPages, setSummaryTotalPages] = useState<number>(0);
  const [summaryCurrentPage, setSummaryCurrentPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState(
    activeViewer === 'main'
      ? `${mainCurrentPage}`
      : `${summaryCurrentPage}`
  );
  const [clickMain, setClickMain] = useState(false);  
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [endPoint, setEndPoint] = useState<{ x: number; y: number } | null>(null);
  const cropAreaRef = useRef<HTMLDivElement>(null);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [mainSearchKeyword, setMainSearchKeyword] = useState('');
  const [mainSearchFound, setMainSearchFound] = useState(false);
  const [showMainSearchBox, setShowMainSearchBox] = useState(false);
  const [summarySearchKeyword, setSummarySearchKeyword] = useState('');
  const [summarySearchFound, setSummarySearchFound] = useState(false);
  const [showSummarySearchBox, setShowSummarySearchBox] = useState(false);
  const [jumpForward, setJumpForward] = useState<boolean>(false);
  const [jumpBackward, setJumpBackward] = useState<boolean>(false);  
  const mainSearchPluginInstance = searchPlugin();
  const summarySearchPluginInstance = searchPlugin();
  const chatTextareaRef = useRef<HTMLTextAreaElement>(null);
  const previousSizeRef = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;
  const [selectedModel, setSelectedModel] = useState<'ChatGPT' | 'DeepSeek'>('ChatGPT');
  const [progress, setProgress] = useState(0);

// Destructure methods from main search plugin
const {
  highlight: mainHighlight,
  jumpToNextMatch: mainJumpToNextMatch,
  jumpToPreviousMatch: mainJumpToPreviousMatch,
  clearHighlights: mainClearHighlights,
} = mainSearchPluginInstance;

// Destructure methods from summary search plugin
const {
  highlight: summaryHighlight,
  jumpToNextMatch: summaryJumpToNextMatch,
  jumpToPreviousMatch: summaryJumpToPreviousMatch,
  clearHighlights: summaryClearHighlights,
} = summarySearchPluginInstance;


  const mainPageNavigationPluginInstance = pageNavigationPlugin();
  const summaryPageNavigationPluginInstance = pageNavigationPlugin();

// Destructure jumpToPage for each plugin
const { jumpToPage: jumpToMainPage } = mainPageNavigationPluginInstance;
const { jumpToPage: jumpToSummaryPage } = summaryPageNavigationPluginInstance;

const MAX_TOKENS = 10000; // Define the maximum number of tokens 



useEffect(() => {
  // Define an async function inside useEffect
  const fetchTranscript = async () => {
    const transcript = await extractTextWithFormatting(currentFile?.transcriptUrl || transcriptUrl);
    setSystemMessage(transcript);
    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
    }
  };

  fetchTranscript();
}, [transcriptUrl]); // Adjust dependencies as necessary

  useEffect(() => {
    // Scroll the dummy div into view when chat content changes
    if (dummyDivRef.current) {
      dummyDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistant?.chat]); 


  const computeAndSetZoom = useCallback(() => {
    const container = mainPdfViewerRef.current;
    if (!container) return;
  
    // Select the first page layer
    const firstPage = container.querySelector('.rpv-core__page-layer') as HTMLElement;
    if (!firstPage) return;
  
    // Measure widths
    const pageMargin = 30;
    const pageWidth = firstPage.getBoundingClientRect().width + pageMargin;
    const containerWidth = container.getBoundingClientRect().width;

    if (pageWidth === 0 || containerWidth === 0) return;
  
    let zoomLevel = 1.0;
    if (containerWidth < pageWidth) {
      zoomLevel = (containerWidth / pageWidth); // Adjust zoom level based on container width
    } else {
      zoomLevel = 1.0; // Default zoom level
    }
  
    // Clamp the zoom level to prevent extreme scaling
    const clampedZoom = Math.min(Math.max(zoomLevel, 0.01), 2.0); // Between 1% and 200%
  
    // Apply the zoom
    //mainZoomPlugin.zoomTo(clampedZoom);
    container.style.setProperty('--scale-factor', `${clampedZoom}`);
    //setCurrentZoom(clampedZoom); // Update the zoom state
  }, [mainZoomPlugin]);

const handleDocumentLoad = useCallback(async (e: any) => {
  // Set the initial scale based on the active viewer
  if (activeViewer === 'main') {
    if (mainZoomPlugin && typeof mainZoomPlugin.zoomTo === 'function') {
      mainZoomPlugin.zoomTo(1.0); // Set zoom for the main viewer
      mainPdfViewerRef.current?.style.setProperty('--scale-factor', '1.0');
      setCurrentZoom(1.0);
    } else {
      console.warn('Main zoom plugin is not initialized or invalid.');
    }
  } else if (activeViewer === 'summary') {
    if (summaryZoomPlugin && typeof summaryZoomPlugin.zoomTo === 'function') {
      summaryZoomPlugin.zoomTo(1.0); // Set zoom for the summary viewer
      summaryPdfViewerRef.current?.style.setProperty('--scale-factor', '1.0');
      setCurrentZoom(1.0);
    } else {
      console.warn('Summary zoom plugin is not initialized or invalid.');
    }
  } else {
    console.warn('No active viewer detected.');
  }

  const totalPages = e.doc.numPages;
  setInputValue(`1 of ${totalPages}`);

  // Set the active viewer's total pages and current page if applicable
  if (activeViewer === 'main') {
    setMainTotalPages(totalPages);
    setMainCurrentPage(1);
  } else if (activeViewer === 'summary') {
    setSummaryTotalPages(totalPages);
    setSummaryCurrentPage(1);
  }

  // Poll for the first page's existence
  const intervalId = setInterval(() => {
    const firstPage = mainPdfViewerRef.current?.querySelector('.rpv-core__page-layer') as HTMLElement;
    if (firstPage) {
      computeAndSetZoom();
      clearInterval(intervalId);
    }
  }, 100);

  // Cleanup in case the component unmounts before the page renders
  return () => clearInterval(intervalId);
}, [activeViewer, computeAndSetZoom]);



  useEffect(() => {
    const container = mainPdfViewerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;

        // Compare with previous dimensions
        if (
          previousSizeRef.current.width !== width ||
          previousSizeRef.current.height !== height
        ) {
          // Update previous dimensions
          previousSizeRef.current = { width, height };

          if (window.innerWidth > 600) {
          // Invoke the zoom computation
          computeAndSetZoom();
          } 
        }
      }
    });

    // Start observing the container
    resizeObserver.observe(container);

    if (window.innerWidth > 600) {
      // Invoke the zoom computation
      computeAndSetZoom();
      } 

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [computeAndSetZoom]);


  const checkTextSelection = useCallback(() => {
    const selection = window.getSelection();

    if (selection && selection.toString().trim() !== '') {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Get the element where the text was selected
      const parentElement = range.commonAncestorContainer as HTMLElement;
      const pdfViewerElement = document.querySelector('.pdf-viewer-container');

      // Check if the selection is within the PDF viewer container
      if (pdfViewerElement && pdfViewerElement.contains(parentElement)) {
        setSelectedText(selection.toString());
        setSelectionPosition({
          top: rect.top + window.scrollY - 40, // Adjust for display action buttons
          left: rect.left + window.scrollX,
        });
        setSelectionWithinPDF(true); // Selection is within the PDF viewer
      } else {
        // If selection is outside the PDF viewer, reset selection states
        setSelectedText(selection.toString());
        setSelectionPosition({
          top: rect.top + window.scrollY - 40, // Adjust for display action buttons
          left: rect.left + window.scrollX,
        });
        setSelectionWithinPDF(false);
      }
    } else {
      // Reset selection states if no text is selected
      setSelectedText(null);
      setSelectionPosition(null);
      setSelectionWithinPDF(false);
    }
  }, []);

  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus || 'Starter';

        setFirstName(userData?.firstName || null); // Set firstName for personalized responses

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);


 useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user && !user.isAnonymous) {
      fetchUserPlan(user.uid); // Fetch user plan when authenticated
      
    } else {
      console.warn("No user authenticated, data fetching skipped.");
    }
  });

  return () => unsubscribe(); // Clean up the listener on component unmount
}, [fetchUserPlan]);

  
  useEffect(() => {
    const interval = setInterval(checkTextSelection, 1000); // Check selection every second
    return () => clearInterval(interval);
  }, [checkTextSelection]);

  const handlePanClick = () => {
    setIsPanning((prev) => !prev);
  };


const handleMouseDown = (e: React.MouseEvent) => {
  if (isPanning && e.button === 0) {
      const pdfViewerContainer = activeViewer === 'main' ? mainPdfViewerRef.current : summaryPdfViewerRef.current;

      if (!pdfViewerContainer) {
          console.error('PDF viewer container not found.');
          return;
      }

      // Using helper function to find scrollable content inside the viewer container
      const scrollableContent = getScrollablePdfContainer(pdfViewerContainer);
      
      if (!scrollableContent) {
          console.error("Scrollable PDF content not found.");
          return;
      }

      // Add the no-text-select class to disable text selection while panning
      pdfViewerContainer.classList.add('no-text-select');

      const startX = e.clientX;
      const startY = e.clientY;
      const initialScrollLeft = scrollableContent.scrollLeft;
      const initialScrollTop = scrollableContent.scrollTop;

      const handleMouseMove = (moveEvent: MouseEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const deltaY = moveEvent.clientY - startY;
          scrollableContent.scrollLeft = initialScrollLeft - deltaX;
          scrollableContent.scrollTop = initialScrollTop - deltaY;
      };

      const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);

          // Remove the no-text-select class after panning ends
          pdfViewerContainer.classList.remove('no-text-select');
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  }
};


const handleFullPageClick = () => {
  if (activeViewer === 'main') {
    mainZoomPlugin.zoomTo(1.0); // Set zoom for main viewer
    setCurrentZoom(1.0); 
  } else if (activeViewer === 'summary') {
    summaryZoomPlugin.zoomTo(1.0); // Set zoom for summary viewer
    setCurrentZoom(1.0); 
  }
};

const handleAction = (action: 'question' | 'explain' | 'summary') => {
    if (!selectedText) return;

    let message: string;
    switch (action) {
      case 'question':
        // Set the text in the prompt input container for user to modify
        setNewMessage(`"${selectedText}" For the text above, `);
        setShowPreloadedMessage(false); 
        break;
      case 'explain':
        message = `Can you explain the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      case 'summary':
        message = `Can you summarize the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      default:
        return;
    }
  };

  const sendMessage = async (message: string) => {
    if (!assistant || !assistant.threadID) {
      console.error('Cannot send message: Assistant or threadID is not available.');
      return;
    }
  
    const updatedChat: ChatMessage[] = assistant ? [...assistant.chat, { message, from: 'user', type: 'text' }] : [];
  
    setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
    setLoading(true);
    setShowPreloadedMessage(false); 
  
    try {
      const content = [{ type: 'text' as const, text: message }];
      
      // Use the selected model
      if (selectedModel === 'ChatGPT') {
        await openai.beta.threads.messages.create(assistant!.threadID, { role: 'user', content });
        await processAssistantResponse(updatedChat);
      } else if (selectedModel === 'DeepSeek') {
        const completion = await deepseekai.chat.completions.create({
          messages: [{ role: "system", content: "You are a helpful assistant." }],
          model: "gpt-4o-mini"
        });
        console.log(completion.choices[0].message.content);
      }
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
  
    setNewMessage(''); // Clear the input field after sending
  };

  const handleQnaButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }

    //console.log('currentFile qna url',currentFile?.qnaUrl)
    //console.log('currentFile url',pdfUrl)

    setShowPreloadedMessage(true); 
    setIsQnaOpen((prev) => !prev);
  
  };
  
  const handleSummaryButtonClick = async () => {

    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    setShowPreloadedMessage(true); 
    setActiveViewer('summary');
    setIsSummaryOpen((prev) => !prev);
  };
  
  const handleChatButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    if(selectedModel === 'ChatGPT'){
      if(!isAssistantLoading && assistant === null){
        if (currentFile?.documentType === 'Note' && pdfUrl) {
          // pdfUrl is guaranteed to be a string here
          await createTemporaryAssistant(pdfUrl);  
        } else if (currentFile?.transcriptUrl || transcriptUrl) {
          // transcriptUrl is guaranteed to be a string here
        } else {
          console.error("No valid URL available to create an assistant.");
        }
        setShowPreloadedMessage(true); 
      }
    }

    if(selectedModel === 'DeepSeek'){
        setShowPreloadedMessage(true); 
    }
    setIsChatOpen((prev) => !prev);
    //console.log('IsAssistantLoading',isAssistantLoading)
  };
  
// Function to create a temporary assistant
const createTemporaryAssistant = useCallback(async (pdfUrl: string) => {
    const assistantInstructions = 'You are an assistant to help people unserstands uploaded documents. You should respond to questions based on the document you have. When you provide responses, provide answers without including source citations.';
    setIsAssistantLoading(true);  // Start assistant loading
    setIsSendDisabled(true);  // Disable send button while loading assistant
    try {
      const assistantResponse = await openai.beta.assistants.create({
        name: 'PDF Assistant',
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });
  
      const assistantID = assistantResponse.id;  
      //console.log('assistant ID', assistantID);
  
      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      //console.log('thread ID', threadID);
  
      // Upload PDF using pdfUrl
      //console.log('pdfURL',pdfUrl)
      const fileResponse = await fetch(pdfUrl);
      const fileBlob = await fileResponse.blob();

      const fileUploadResponse = await openai.files.create({
        file: new File([fileBlob], "document.pdf", { type: fileBlob.type }),  // Create file from the URL
        purpose: "assistants",
      });

      //console.log('fileUploadResponse', fileUploadResponse);
  
      const fileID = fileUploadResponse.id;
  
      const vectorStoreResponse = await openai.beta.vectorStores.create({
        name: "Temporary Vector Store",
      });
  
      const vectorStoreID = vectorStoreResponse.id;
  
     // console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      //console.log('my vector store file', myVectorStoreFile);

      await openai.beta.assistants.update(assistantID, {
        tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
  
      setAssistant({
        name: 'Temporary Assistant',
        id: assistantID,  // Store the real assistant ID
        chat: [],
        fileID: fileID,
        threadID: threadID,
        vectorStore: vectorStoreID,
      });
      console.log('New assistnat has been created.')
      setIsSendDisabled(false);  // Enable send button when assistant is ready
    } catch (error) {
      console.error('Error creating assistant:', error);
    } finally {
      setIsAssistantLoading(false);  // Stop assistant loading
    }
  }, []);

  const removeTemporaryAssistant = async () => {
    if (assistant) {
      try {
        await openai.files.del(assistant.fileID);
        //console.log('file ID removed:', assistant.fileID);
  
        await openai.beta.threads.del(assistant.threadID);
        //console.log('thread ID removed:', assistant.threadID);
  
        await openai.beta.vectorStores.del(assistant.vectorStore);
        //console.log('vector store ID removed:', assistant.vectorStore);
  
        await openai.beta.assistants.del(assistant.id);
        //console.log('assistant ID removed:', assistant.id);
  
        // Clear the assistant state by setting it to null
        setAssistant(null);
  
        setIsSendDisabled(true); // Disable send button after removing assistant
  
        // Log the assistant state after it's cleared
        setTimeout(() => {
          //console.log('assistant removed:', assistant); 
          console.log('assistant has been removed'); 
        }, 0);
      } catch (error) {
        console.error('Error removing assistant:', error);
      }
    }
  };
  
  const addAssistantImageMessage = async (message: string, fileIds: string[]) => {
    if (!assistant) return;
      let preMessage = ''; 
      if(fileDocumentType === 'Note'){
          preMessage = 'The following message is for the pdf document in the context.'
      }
      if(fileDocumentType === 'YouTube Video'){
        preMessage = 'The following message is for the transcript of a video in the context.' 
      }

      if(fileDocumentType === 'Recording'){
        preMessage = 'The following message is for the transcript of a recording or audio file in the context.'
      }

    const content: OpenAI.Beta.Threads.Messages.MessageContentPartParam[] = [
      { type: "text", text: preMessage + message },
      ...fileIds.map(fileId => ({
        type: "image_file" as const,
        image_file: { file_id: fileId },
      })),
    ];

    await retryRequest(async () => {
      await openai.beta.threads.messages.create(
        assistant.threadID,
        {
          role: "user",
          content: content,
        }
      );
    });
  };
  
  const processAssistantResponse = async (updatedChat: ChatMessage[]) => {
    if (!assistant) return;
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
  
      const client = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
        dangerouslyAllowBrowser: true,
      });
      const threadId = assistant.threadID;
      const eventHandler = new EventHandler(client);
      eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));

        try {
          // Start the stream
          const stream = await openai.beta.threads.runs.stream(threadId, {
            assistant_id: assistant.id,
          });
  
          let partialMessage = ""; // To build the message incrementally
          const newUpdatedChat = updatedChat;

          let lastMessageIndex = -1; // Keep track of the last message index
          
          // Listen for events from the stream
          for await (const event of stream) {
            eventHandler.emit("event", event); // Emit each event to your EventHandler instance
  
            // Type assertion to access the 'delta' property
            if ((event as any).data.delta?.content?.length > 0) {
              const chunk = (event as any).data.delta.content[0].text.value;
              partialMessage += chunk; // Add the chunk to the partial message
  
              // Find the last assistant message if not already located
              if (lastMessageIndex === -1) {
                lastMessageIndex = newUpdatedChat.findIndex(
                  (msg) => msg.from === 'assistant' && !msg.message.trim()
                );
              }
  
              // Update the assistant's response incrementally
              //console.log('lastMessageIndex',lastMessageIndex)

              if (lastMessageIndex !== -1) {
                newUpdatedChat[lastMessageIndex].message = partialMessage;
                //console.log('updated chat1',newUpdatedChat)
              } else {
                newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
                lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
              }
  
              setAssistant((prev) => prev ? { ...prev, chat: newUpdatedChat } : null);
              setIsAssistantThinking(false); 
            }
          }
  
          setLoading(false); // Set loading to false after completion
        } catch (error) {
          console.error("Error handling stream:", error);
        }
      
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };


  // A simple estimate: 1 token ~= 4 characters (this is an approximation)
  const estimateTokens = (text: string): number => {
    return Math.ceil(text.length / 4);
  };
  
  const processDeepseekResponse = async (updatedChat: ChatMessage[], systemMessage: string, preMessage: string, newMessage: string) => {
    try {
      if (selectedModel !== 'DeepSeek') {
        console.error("Selected model is not DeepSeek. Skipping.");
        return;
      }
  
      const eventHandler = new EventHandler(openai);
      eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));
  
      // Start by adding the previous chat history to the message context
      let chatHistory: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = updatedChat.map(msg => ({
        role: msg.from === 'user' ? 'user' : 'assistant',
        content: msg.message,
      }));
  
      // Add the new message to the chat history
      chatHistory.push({
        role: 'user',
        content: preMessage + newMessage,
      });
  
      // Start with the system message
      const formattedMessages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
        {
          role: 'system',
          content: systemMessage,
        },
      ];
  
      // Calculate token usage for the system message
      let totalTokens = estimateTokens(systemMessage);
  
      // Truncate chat history to stay within token limits
      let truncatedChatHistory: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [];
      for (let i = chatHistory.length - 1; i >= 0; i--) {
        const message = chatHistory[i];
        if (typeof message.content === 'string') {
          const messageTokens = estimateTokens(message.content);
          if (totalTokens + messageTokens > MAX_TOKENS) {
            // Stop adding messages if the token limit is reached
            break;
          }
          totalTokens += messageTokens;
          truncatedChatHistory.unshift(message); // Add the message to the front (preserving order)
        } else {
          console.warn("Skipping invalid message content:", message);
        }
      }
  
      // Add the truncated chat history to the formatted messages
      formattedMessages.push(...truncatedChatHistory);
  
      // Make a request to the OpenAI chat completions API
      const stream = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: formattedMessages,
        max_tokens: 3000,
        stream: true, // Enable streaming mode
      });
  
      let partialMessage = ""; // To build the message incrementally
      const newUpdatedChat = [...updatedChat]; // Start with the previous chat history
      newUpdatedChat.push({ message: newMessage, from: "user", type: "text" });
  
      let lastMessageIndex = -1; // Keep track of the last assistant message index
  
      // Listen for events from the stream
      for await (const event of stream) {
        eventHandler.emit("event", event);
        if ((event as any).choices[0]?.delta?.content) {
          const chunk = (event as any).choices[0]?.delta?.content;
          partialMessage += chunk;
  
          if (lastMessageIndex === -1) {
            lastMessageIndex = newUpdatedChat.findIndex(
              (msg) => msg.from === 'assistant' && !msg.message.trim()
            );
          }
  
          if (lastMessageIndex !== -1) {
            newUpdatedChat[lastMessageIndex].message = partialMessage;
          } else {
            newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
            lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
          }
          setDeepseekChat([...newUpdatedChat]); // Append new messages to the previous chat
        }
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false);
    }
  };
  
  const processDeepseekResponseWithFiles = async (updatedChat: ChatMessage[], files: File[], systemMessage: string, preMessage: string, newMessage: string) => {
    try {
      if (selectedModel !== 'DeepSeek') {
        console.error("Selected model is not DeepSeek. Skipping.");
        return;
      }
  
      if (!files || files.length === 0) {
        console.error("No files uploaded");
        return;
      }
  
      const eventHandler = new EventHandler(openai);
      eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));
  
      // Loop through each file in the `files` array
      const filePromises = files.map(async (file) => {
        const fileUrl = URL.createObjectURL(file);  // Create a URL for the file
  
        const base64Image = await convertToBase64(file);  // Convert the file to base64
        const imageType = file.type;  // Get the MIME type of the file
        if (!imageType.startsWith("image/")) {
          console.error("Invalid image MIME type: " + imageType);
          return null;  // Stop if it's not a valid image
        }
  
        const fileQuestion = `<img src="${fileUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + preMessage + newMessage;
        // Prepare the messages for the OpenAI API
        const formattedMessages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
          {
            role: 'system',
            content: systemMessage,
          },
          {
            role: "user",
            content: [
              { type: "text", text: fileQuestion },
              { type: "image_url", image_url: { url: `data:${imageType};base64,${base64Image}` } }
            ],
          }
        ];
  
        return formattedMessages;
      });
  
      // Wait for all files to be processed
      const allFormattedMessages = await Promise.all(filePromises);
  
      // Filter out any null entries (in case of invalid files)
      const validMessages = allFormattedMessages.filter((message): message is OpenAI.Chat.Completions.ChatCompletionMessageParam[] => message !== null);
  
      // Make a request to the OpenAI API for GPT-4 Vision model with all valid files
      const stream = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: validMessages.flat(),  // Flatten the array of messages
        max_tokens: 3000,
        stream: true,
      });

      let partialMessage = "";
      const newUpdatedChat = [...updatedChat];
      let lastMessageIndex = -1;
  
      for await (const event of stream) {
        eventHandler.emit("event", event);
        if ((event as any).choices[0]?.delta?.content) {
          const chunk = (event as any).choices[0]?.delta?.content;
          partialMessage += chunk;
  
          if (lastMessageIndex === -1) {
            lastMessageIndex = newUpdatedChat.findIndex(
              (msg) => msg.from === 'assistant' && !msg.message.trim()
            );
          }
  
          if (lastMessageIndex !== -1) {
            newUpdatedChat[lastMessageIndex].message = partialMessage;
          } else {
            newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
            lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
          }
          setDeepseekChat([...newUpdatedChat]); // Append new messages to the previous chat
        }
      }
  
      setIsAssistantThinking(false);
      setLoading(false);
    } catch (error) {
      console.error("Error processing DeepSeek response with files:", error);
      setLoading(false);
    }
  };
  
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = function (event: ProgressEvent<FileReader>) {
        // Ensuring event.target.result is a string as expected
        if (typeof event.target?.result === 'string') {
          const base64String = event.target.result.split(',')[1]; // Extracts the base64 part
          resolve(base64String); // Resolve the promise with the base64 string
        } else {
          reject(new Error("Failed to load file as base64 string"));
        }
      };
      
      reader.onerror = function (error: ProgressEvent<FileReader>) {
        reject(error); // Reject the promise if there's an error
      };
      
      reader.readAsDataURL(file); // Read the file as a Data URL
    });
  };
  

  const handleSendMessage = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("User is not authenticated.");
      return;
    }
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "users", currentUser.uid);
  
    setIsAssistantThinking(true);
  
    try {
      // Fetch the user's data from Firestore
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        alert("User profile not found in Firestore.");
        setIsAssistantThinking(false);
        return;
      }
  
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus || "Starter";
      const lastChatTime = userData?.lastChatTime || 0;

      // Check if the date of lastChatTime is earlier than today
      const today = new Date().setHours(0, 0, 0, 0);  // Set to the start of today (midnight)
      const lastChatDate = new Date(lastChatTime.seconds * 1000).setHours(0, 0, 0, 0);  // Convert Firestore timestamp to Date object and set to midnight

      // If lastChatDate is earlier than today, reset the daily prompt count
      if (lastChatDate < today) {
        await updateDoc(userDocRef, {
          dailyPromptCount: 0,  // Reset prompt count
          dailyFileUploadCount: 0,
        });
      }

      const promptCount = userData?.dailyPromptCount || 0;
      const fileUploadCount = userData?.dailyFileUploadCount || 0;
  
      // Define daily limits for prompts and file uploads based on the plan
      const dailyPromptLimits: Record<string, number> = {
        Starter: 20,
        Premium: 100,
        Chat: 300,
      };
  
      const dailyFileUploadLimits: Record<string, number> = {
        Starter: 3,
        Premium: 10,
        Chat: 30,
      };
  
      const dailyPromptLimit = dailyPromptLimits[subscriptionStatus];
      const dailyFileUploadLimit = dailyFileUploadLimits[subscriptionStatus];
  
      // Check if the user has exceeded the daily prompt limit
      if (promptCount >= dailyPromptLimit) {
        alert(`You have reached your daily prompt limit of ${dailyPromptLimit} for the ${subscriptionStatus} plan.`);
        setIsAssistantThinking(false);
        return;
      }
  
      // Check if the user has exceeded the daily file upload limit
      if (uploadedFiles.length > 0 && fileUploadCount + uploadedFiles.length > dailyFileUploadLimit) {
        alert(`You can upload up to ${dailyFileUploadLimit} files per day for the ${subscriptionStatus} plan.`);
        setIsAssistantThinking(false);
        return;
      }
  
      if (isAssistantLoading) {
        //console.log("Assistant is still loading. Please wait.");
        setIsAssistantThinking(false);
        return;
      }

      if (newMessage.trim() === "" && uploadedFiles.length === 0) {
        setIsAssistantThinking(false);
        return;
      }
  
      if (selectedModel === 'ChatGPT'){
        if (!assistant || !assistant.threadID) {
          console.error("Cannot send message: Assistant or threadID is not available.");
          setIsAssistantThinking(false);
          return;
        }
      }      

      let updatedChat: ChatMessage[] = assistant ? [...assistant.chat] : [];
      let fileQuestion = newMessage;
  
      const filePromises = uploadedFiles.map(async (file) => {
        const fileUrl = URL.createObjectURL(file);
  
        let matchingPreviewUrl;
        if (file.name === "Screenshot.png") {
          matchingPreviewUrl = imagePreviewUrls[0];
        } else {
          matchingPreviewUrl = imagePreviewUrls.find((url) => url.includes(file.name));
        }
  
        if (!matchingPreviewUrl) {
          console.error(`No preview URL found for file: ${file.name}`);
        }
  
        fileQuestion = `<img src="${matchingPreviewUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;
  
        const uploadedFileId = await uploadFileToOpenAI(file);
        return { fileUrl, fileId: uploadedFileId };
      });
  
      let preMessage = ''; 
      if(fileDocumentType === 'Note'){
          preMessage = 'The following message is for the pdf document in the context.'
      }
      if(fileDocumentType === 'YouTube Video'){
        preMessage = 'The following message is for the transcript of a video included in the system message.'
      }

      if(fileDocumentType === 'Recording'){
        preMessage = 'The following message is for the transcript of a recording or audio file included in the system message.'
      }

      if (uploadedFiles.length > 0) {
        try {
      // Handle chat with DeepSeek
        if (selectedModel === 'ChatGPT') {
          const fileData = await Promise.all(filePromises);
          const fileIds = fileData.map((file) => file.fileId);
  
          if (fileQuestion.trim() !== "") {
            updatedChat.push({ message: fileQuestion, from: "user", type: "text" });
          }
  
          setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
          setLoading(true);


          await addAssistantImageMessage(fileQuestion, fileIds);
          await processAssistantResponse(updatedChat);
        } else if (selectedModel === 'DeepSeek') {
          await processDeepseekResponseWithFiles(deepseekChat, uploadedFiles, systemMessage,preMessage,newMessage);
        }
          // Update daily prompt and file upload counts in Firestore
          await updateDoc(userDocRef, {
            dailyPromptCount: promptCount + 1,
            dailyFileUploadCount: fileUploadCount + uploadedFiles.length,
          });
  
          setIsAssistantThinking(false);
        } catch (error) {
          console.error("Error uploading files:", error);
          setIsAssistantThinking(false);
          return;
        }
      } else {

        if (newMessage.trim() !== "") {
          updatedChat.push({ message: newMessage, from: "user", type: "text" });
        }

        setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
        setLoading(true);
        
        try {

          if(selectedModel === 'ChatGPT'){
            const content = [{ type: "text" as const, text: preMessage + newMessage }];
            await retryRequest(async () => {
              await openai.beta.threads.messages.create(assistant!.threadID, { role: "user", content });
            });

            await processAssistantResponse(updatedChat);
          } else{
            await processDeepseekResponse(deepseekChat, systemMessage, preMessage, newMessage);
          }

          // Update daily prompt count in Firestore
          await updateDoc(userDocRef, { 
              dailyPromptCount: promptCount + 1,
              lastChatTime: new Date()
            });
          setIsAssistantThinking(false);
        } catch (error) {
          console.error("Error sending message:", error);
          setLoading(false);
          setIsAssistantThinking(false);
        }
      }
  
      setNewMessage("");
      setShowPreloadedMessage(false);
      setUploadedFiles([]);
      setImagePreviewUrls([]);
    } catch (error) {
      console.error("Error handling message:", error);
      setIsAssistantThinking(false);
    }
  };
  
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const auth = getAuth(); // Get Firebase Auth instance
    const user = auth.currentUser; // Get the currently authenticated user
  
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }
  
    if (e.target.files && e.target.files.length > 0) {
      const storage = getStorage(); // Initialize Firebase Storage
      const filesArray = Array.from(e.target.files); // Convert FileList to an array
  
      const uploadPromises = filesArray.map(async (file) => {
        // Include user's UID in the file name
        const fileRef = ref(storage, `chatImages/${user.uid}_${file.name}`); // UID appended to file name
  
        try {
          // Upload the file to Firebase Storage
          const uploadSnapshot = await uploadBytes(fileRef, file);
         // console.log(`Uploaded file: ${uploadSnapshot.metadata.name}`);
  
          // Get the download URL for the uploaded file
          const downloadUrl = await getDownloadURL(fileRef);
         // console.log(`File available at: ${downloadUrl}`);
  
          return downloadUrl; // Return the download URL
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          return null; // Return null for failed uploads
        }
      });
  
      try {
        // Wait for all files to upload
        const downloadUrls = await Promise.all(uploadPromises);
  
        // Filter out any null entries (failed uploads)
        const validUrls = downloadUrls.filter((url): url is string => url !== null); // Use a type guard to ensure only strings
  
        // Update state with the valid download URLs
        setImagePreviewUrls((prevUrls) => [...prevUrls, ...validUrls]); // Store Firebase URLs for preview
        setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]); // Store the file objects
      } catch (error) {
        console.error("Error handling file uploads:", error);
      }
    }
  };
  

  const handleClose = async (isCancel = false) => {
    setTextareaContent('');
    setLeaving(true);
    setIsPanning(false);
    setShowMainSearchBox(false)
    setShowSummarySearchBox(false)
    setDeepseekChat([])
    setLeaving(false);
  
    if (isCancel) {
      setIsChatOpen(false);
    } else {
      setIsChatOpen(false);        
      onClose();
    }

    if (assistant) {
      await removeTemporaryAssistant();
    }
  
  };

  const handleQnaClose = () => {
    setIsQnaOpen(false);
  };

  const handleSummaryClose = () => {
    setIsSummaryOpen(false);
    if (activeViewer === 'summary') setActiveViewer('main');
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

// Function to read aloud the assistant's response
const readAloud = (text: string) => {
  if (isPlaying && audioInstance) {
    // Stop playing if already playing
    speechSynthesis.cancel(); // Stop any ongoing speech
    setIsPlaying(false); // Reset playing state
    setAudioInstance(null); // Clear audio instance
    return; // Exit if we are stopping
  }

  const speech = new SpeechSynthesisUtterance(text);
  
  // Set language and natural voice
  speech.lang = 'en-US'; // Set the language
  const voices = speechSynthesis.getVoices(); // Get available voices
  const naturalVoice = voices.find(
    (voice) => voice.lang === 'en-US' && voice.name.includes('Google') // Example: Google voices are often more natural
  );
  if (naturalVoice) {
    speech.voice = naturalVoice; // Set the natural voice if available
  }

  // Configure voice properties
  speech.pitch = 1; // Adjust pitch (default is 1; higher values = higher pitch)
  speech.rate = 1; // Adjust rate (default is 1; higher values = faster speech)
  speech.volume = 1; // Adjust volume (range: 0 to 1)

  speech.onstart = () => {
    setIsPlaying(true); // Set playing state
    setAudioInstance(speech); // Store the audio instance
  }; 
  speech.onend = () => {
    setIsPlaying(false); // Reset playing state when done
    setAudioInstance(null); // Clear audio instance
  };

  speechSynthesis.speak(speech);
};

  const handleCopyResponse = (index: number, response: string) => {
    navigator.clipboard.writeText(response).then(() => {
      setCopiedResponseIndex(index);
      setTimeout(() => setCopiedResponseIndex(null), 2000); // Reset after 2 seconds
    }).catch(err => console.error('Failed to copy:', err));
  };

  useEffect(() => {
    const updatePdfViewerWidth = () => {
      if (window.innerWidth > 1000) {
        setPdfViewerWidth(60); // Set width to 60% for screens larger than 1000px
      } else {
        setPdfViewerWidth(90); // Set width to 90% for screens smaller than 1000px
      }
    };
  
    // Initial setup
    updatePdfViewerWidth();
  
    // Add resize listener
    window.addEventListener('resize', updatePdfViewerWidth);
  
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updatePdfViewerWidth);
    };
  }, []);
  

const createOverlay = () => {
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100vw';
  overlay.style.height = '100vh';
  overlay.style.zIndex = '9999';
  overlay.style.cursor = 'col-resize';
  overlay.style.background = 'transparent';
  overlay.style.pointerEvents = 'all';

  document.body.appendChild(overlay);
  overlayRef.current = overlay;
  return overlay;
};

const removeOverlay = () => {
  if (overlayRef.current) {
    document.body.removeChild(overlayRef.current);
    overlayRef.current = null;
  }
};

const handleMouseDownOnResizeBar = (e: React.MouseEvent) => {
  e.preventDefault();
  resizingRef.current = true;

  const overlay = createOverlay();

  const handleMouseMove = (event: MouseEvent) => {
    if (resizingRef.current && mainPdfViewerRef.current) {
      const newWidth = (event.clientX / window.innerWidth) * 100;
      setPdfViewerWidth(Math.min(95, Math.max(5, newWidth)));
    }
  };

  const handleMouseUp = () => {
    resizingRef.current = false;
    overlay.removeEventListener('mousemove', handleMouseMove);
    overlay.removeEventListener('mouseup', handleMouseUp);
    removeOverlay();
  };

  overlay.addEventListener('mousemove', handleMouseMove);
  overlay.addEventListener('mouseup', handleMouseUp);
};


  // Functions for simple audio player
  const handlePlayAudio = async (url: string,fileRefPath: string) => {
    if (audioRef.current) {
      try {
        if (playingAudio === url && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          if (playingAudio === url) {
            audioRef.current.play();
            setIsPlaying(true);
          } else {
            audioRef.current.src = url;
            audioRef.current.currentTime = 0;
            audioRef.current.play();
            setPlayingAudio(url);
            setIsPlaying(true);
          }
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        alert('Failed to play the audio. Please try again.');
      }
    }
  };

  // Function to update progress of audio playback
  const handleAudioProgress = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
  
      // Use metadataDuration if available, otherwise fallback to audioRef.current.duration
      const duration = metadataDuration || audioRef.current.duration;
  
      if (isFinite(currentTime) && isFinite(duration) && duration > 0) {
        const progress = (currentTime / duration) * 100;
        setAudioProgress(progress);
      }
    }
  };

  const handleFastForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10; // Fast forward 10 seconds
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const handleSpeedChange = () => {
    if (audioRef.current) {
      const newSpeed = playbackSpeed === 1.0 ? 1.5 : playbackSpeed === 1.5 ? 2.0 : 1.0;
      audioRef.current.playbackRate = newSpeed; // Set playback speed
      setPlaybackSpeed(newSpeed);
    }
  };

  // Function to handle seeking when dragging the progress bar
const handleAudioSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (audioRef.current && isFinite(audioRef.current.duration)) {
    const seekTime = (parseFloat(event.target.value) / 100) * audioRef.current.duration;
    if (isFinite(seekTime)) {
      audioRef.current.currentTime = seekTime;
    }
  }
};

  // Function to handle metadata load (duration availability)
  const handleMetadataLoaded = () => {
    if (audioRef.current && isFinite(audioRef.current.duration)) {
      setAudioProgress(0); // Reset progress when metadata is loaded
    }
  };

const handleToggleAnswer = (index: number) => {
  if (showAnswerIndexes.includes(index)) {
    setShowAnswerIndexes(showAnswerIndexes.filter(i => i !== index));
  } else {
    setShowAnswerIndexes([...showAnswerIndexes, index]);
  }
};


const handleDeleteItem = async (index: number) => {
  const currentUser = auth.currentUser;
  if (!currentUser || !selectedSubject) return;

  const confirmDeletion = window.confirm('Are you sure you want to delete this question?');
  if (!confirmDeletion) return;

  const updatedLibraryItems = libraryItems.filter((_, i) => i !== index);
  setLibraryItems(updatedLibraryItems);

  const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
  const subjectRef = ref(storage, subjectFileName);
  await uploadString(subjectRef, JSON.stringify(updatedLibraryItems));
};



const generateUniqueFileName = useCallback(
async (originalName: string): Promise<string> => {

  let folderName = '';

  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }
  
  const storageRef = ref(storage, folderName);
  const fileList = await listAll(storageRef);

  let newFileName = originalName;
  let counter = 1;
  
  const existingFiles = fileList.items.map((file) => file.name);

  while (existingFiles.includes(newFileName + '.pdf')) {
    const fileNameWithoutExt = originalName;
    newFileName = `${fileNameWithoutExt}(${counter})`;
    counter++;
  }
  
  return `${newFileName}.pdf`;
},
[currentFile]
);


const getOriginalFileName = useCallback((url: string): string => {
  // Decode the URL to get the file path with normal slashes
  const decodedUrl = decodeURIComponent(url);
  
  // Extract the file name after removing the "recordings/" prefix
  const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('recordings/') + 'recordings/'.length);
  
  // Remove the file extension
  return fileName.split('.').slice(0, -1).join('.');
}, []);


const uploadTextAsPDF = useCallback(async (text: string) => {

  const  currentQuestion = `Reorganize the texts to show as paragraphs. 
  Ignore "Recording stopped." if exists. 
  Ignore "WEBVTT Kind: captions Language: en" if exists.   
  Ignore time related texts such as "00:00:00.240 --> 00:00:04.040" if exists,
  Do not change the text contents unless noted as above:`+ text;

  //console.log('currentQuestion',currentQuestion)
  const  response = await openai.chat.completions.create({
    model:  "gpt-4o-mini",
    messages: [
      {
        role: "user",
        content: [
          { type: "text", text: currentQuestion },
        ],
      }
    ],
    max_tokens: 10000,
    stream: false,
    });

    let updatedText: string = '';
    if (response) {
      updatedText = response.choices[0].message.content ?? 'Default Content';
     // console.log('updated text',updatedText)
    }

  const pdfDoc = await PDFDocument.create();

  // Register fontkit
  pdfDoc.registerFontkit(fontkit as any);

  // Load a custom Unicode font from a TTF file
  const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font

  // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
  const pageWidth = 8.5 * 72;
  const pageHeight = 11 * 72;

  let page = pdfDoc.addPage([pageWidth, pageHeight]);
  const { width, height } = page.getSize();
  const fontSize = 12;
  const lineHeight = fontSize + 4;
  const margin = 50;
  const maxLineWidth = width - margin * 2;

  const sanitizedText = filterUnsupportedCharacters(updatedText);
  const paragraphs = sanitizedText.split('\n');

  let y = height - margin;

  for (const paragraph of paragraphs) {
    const words = paragraph.split(' ');
    let currentLine = '';

    for (const word of words) {
      const testLine = currentLine + word + ' ';
      const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);

      if (testLineWidth > maxLineWidth) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine, {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        currentLine = word + ' ';
        y -= lineHeight;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine.trim()) {
      if (y - lineHeight < margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        y = height - margin;
      }
      page.drawText(currentLine.trim(), {
        x: margin,
        y: y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      y -= lineHeight;
    }

    // After processing each paragraph, add a line break
    y -= lineHeight;
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  const extractVideoId = (url: string): string => {
    // Extract the part of the URL after the last '/'
    const fileName = url.split('/').pop()?.split('?')[0]; 
    if (!fileName) return '';
  

  // Extract the video ID from the file name based on the assumed naming convention
  const parts = fileName.split('_');
  const videoId = parts.length > 1 ? parts.slice(1).join('_') : '';

    // Remove the ".url" suffix if it exists
    return videoId.replace('.url', '');
  };
  
 // console.log('seleced audio url',pdfUrl)

  let originalName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
         // console.log('selectedVideoUrl',pdfUrl)
          const originalName0 = pdfUrl ? extractVideoId(pdfUrl || '') : '';
          //console.log('originalName0',originalName0)
          originalName = userUID +'_' + originalName0 + '_transcript';

      } else if (currentFile.documentType === "Recording") {
          const originalName0 = getOriginalFileName(pdfUrl || '');
          originalName = originalName0 + '_transcript';
      }
  }

  //console.log('originalName',originalName)
  
  const uniqueFileName = await generateUniqueFileName(originalName);
  let folderName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }

  //console.log('uniqueFileName',uniqueFileName )
  const storageRef = ref(storage, `${folderName}${uniqueFileName}`);

  try {
    const userFilesRef = ref(storage, folderName);
    const fileList = await listAll(userFilesRef);

    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
    
    if (isAnonymous) {
     // console.log('isAnonymouse',isAnonymous)
      // Allow anonymous users to upload a maximum of 2 files
      const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
      if (existingFiles.length >= 2) {
        alert('Anonymous users can only upload up to two files.');
        return;
      }
    } else {
      const currentUser = auth.currentUser;
      if (currentUser) {
            const uid = currentUser.uid;
            setUserUID(uid);

            if (plan === 'Starter') {
              const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
              if (existingFiles.length >= 10) {
                  alert('Starter users can only upload up to 10 files. Registering as a premium user will allow 50 file uploads.');
                  return;
              }
          }
  
            if (plan === 'Premium') {
              const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
              if (existingFiles.length >= 50) {
                  alert('Premium users can only upload up to 50 files. Registering as a chat user will allow unlimited file uploads.');
                  return;
              }
            }

            }
          }

          // Set custom metadata based on the user's selection
          const customMetadata = {
            customMetadata: {
              ShareWithPublic: 'No',
              UserID:userUID,
              DocumentType:'Trancript',
            },
          };

          await uploadBytes(storageRef, blob,customMetadata);
          const transcript_Url = await getDownloadURL(storageRef);
          // Update currentFile's custom metadata with the transcript_Url
          if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);

            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    TranscriptUrl: transcript_Url, // Add the transcriptUrl
                },
            };

            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              transcriptUrl: transcript_Url, 
            });

            if (currentFile.fileNumber) {
              const firestore = getFirestore();
              const fileDocRef = doc(firestore, "files", currentFile.fileNumber.toString());
          
              await setDoc(
                  fileDocRef,
                  { transcriptUrl: transcriptUrl }, 
                  { merge: true } 
              );
          
              //console.log(`Added transcript URL to Firestore for document number: ${currentFile.fileNumber}`);
            }


           // console.log("Updated currentFile metadata with TranscriptUrl:", transcript_Url);
        }

          setIsConverting(false);
          console.log('PDF has been succesfully generated')

  }


  } catch (error) {
    console.error('Upload error:', error);
  }
}, [
  currentFile,
  setCurrentFile,
  filterUnsupportedCharacters,
  generateUniqueFileName,
  getOriginalFileName,
  userUID,
  isAnonymous,
  setIsConverting,
  pdfUrl,
  transcriptUrl,
]);


const handleSubmitScript = useCallback(async (content?: string) => {
  const textToSubmit = content ?? textareaContent.trim(); // Use argument or state value
  //console.log('starting to transcribe');

  if (textToSubmit) {
    await uploadTextAsPDF(textToSubmit);
  } else {
    alert('Please paste some information before submitting.');
  }
}, [textareaContent, uploadTextAsPDF]); // Add dependencies


const handleTranscribeAudio = useCallback(async (url: string) => {
 // console.log('Transcribing audio for', url);
  try {
    setTextareaContent('Processing the audio, please wait...');
    setIsConverting(true);

    // Step 1: Fetch the audio file from the provided URL
    const audioBlob = await fetch(url).then((res) => res.blob());

    // Step 2: Convert the audio Blob to base64 format (if necessary, Whisper API can accept Blob directly)
    const audioFile = new File([audioBlob], "audio.mp4", { type: audioBlob.type });
   // console.log("audio file type:", audioBlob.type)
    // Step 3: Call OpenAI's Whisper API for transcription
    const transcriptionText = await transcribeAudioWithOpenAI(audioFile);   
    setTextareaContent(transcriptionText); // Update the textarea with the transcription
    await handleSubmitScript(transcriptionText); // Save the full transcript

  } catch (error) {
    setTextareaContent('An error occurred during audio transcription. Please try again.');
    console.error('Error during transcription:', error);
  } finally {
    setIsConverting(false);
  }
}, [handleSubmitScript]);

const handleTranscribeVideo = useCallback(async (youtubeUrl: string, url: string) => {
  setTextareaContent('Processing the video, please wait...');
  setIsConverting(true);
  setProgress(0);  // Initialize progress state

  try {
    const response = await fetch(`/api/download-captions?url=${encodeURIComponent(youtubeUrl)}`);

    if (response.headers.get('Content-Type') === 'text/plain; charset=utf-8') {
      // Process captions as plain text
      const captionContent = await response.text();
      setTextareaContent(captionContent);
      await handleSubmitScript(captionContent); // Save the transcript dynamically
    } else if (response.headers.get('Content-Type') === 'audio/mpeg') {
      const reader = response.body?.getReader();

      if (reader) {
        let dynamicTranscript = ''; 
        let totalDuration = 0; // Initialize total duration
        let timeProcessed = 0; // Time in seconds for the audio processed

        // Assuming the total duration was fetched correctly from the backend
        totalDuration = parseFloat(response.headers.get('X-Video-Duration') || '0'); // Ensure totalDuration is a number

        console.log('Total duration',totalDuration)

        if (isNaN(totalDuration) || totalDuration <= 0) {
          console.error('Invalid or missing total video duration.');
          totalDuration = 1; // Avoid division by zero
        }

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          // Calculate how much time this chunk corresponds to
          const chunkDuration = value.length / (1024 *11); // Heuristic: 11 KB of audio data is ~1 second (adjust as needed)
          timeProcessed += chunkDuration;

          const audioBlob = new Blob([value], { type: 'audio/mpeg' });
          const audioFile = new File([audioBlob], 'audio_chunk.mp3', { type: 'audio/mpeg' });

          const chunkTranscript = await transcribeAudioChunkWithWhisper(audioFile, openai);

          dynamicTranscript += chunkTranscript;
          setTextareaContent((prev) => prev + chunkTranscript);

          // Track progress based on time processed and total duration
          console.log('totalDuration',totalDuration)

          const percentage = (timeProcessed / totalDuration) * 100;

          setProgress(Math.min(percentage, 100)); // Ensure progress doesn't exceed 100%
        }

        await handleSubmitScript(dynamicTranscript); // Save the full transcript
      }
    }
  } catch (error) {
    console.error('Error fetching captions or audio:', error);
  } finally {
    setIsConverting(false);
  }
}, [handleSubmitScript]);


useEffect(() => {
  const processVideoAndSubmitScript = async () => {

    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (!currentFile.youtubeUrl) {
      console.warn("Current file does not have a valid youtubeURL. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'YouTube Video') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (showPublic) {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "videosTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
 
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );
    
          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            await getDownloadURL(highestFile);

          setShowTextArea(false);
          setShowTranscript(true);
          setShowTranscriptNote(false)
          return; 
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Show note...");
        }
      }

      setShowTranscript(false);
      setShowTextArea(false);
      setShowTranscriptNote(true)
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "videosTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {

          const fileList = await listAll(folderRef);

          // Decode cleanFileName1 and the filenames from Firebase
          const decodedCleanFileName1 = decodeURIComponent(cleanFileName1);
      
          const matchingFiles = fileList.items.filter(item => {
            // Decode the file name from Firebase
            const decodedFileName = decodeURIComponent(item.name);
            
            // Check if the decoded file name starts with the decoded cleanFileName1
            return decodedFileName.startsWith(decodedCleanFileName1);
          });

          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            const validUrl = await getDownloadURL(highestFile);
            //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;
      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      try {
        await handleTranscribeVideo(currentFile.youtubeUrl, currentFile.url);
      } catch (transcriptionError) {
        console.error("Error during transcription or script submission:", transcriptionError);
      }

      const fileList = await listAll(folderRef);
    
      // Filter files to include only those with `cleanFileName1` as part of the name
      const matchingFiles = fileList.items.filter(item => 
        item.name.startsWith(cleanFileName1)
      );

      if (matchingFiles.length === 0) {
        console.warn("No matching files found for:", cleanFileName1);
      } else {
        // Sort files by number in filename, defaulting to 0 if no number exists
        const sortedFiles = matchingFiles.sort((a, b) => {
          const extractNumber = (name: string) => {
            const match = name.match(/\((\d+)\)\.pdf$/);
            return match ? parseInt(match[1], 10) : 0;
          };
          return extractNumber(a.name) - extractNumber(b.name);
        });

        // Get the last file (highest number or cleanFileName1 + .pdf)
        const highestFile = sortedFiles[sortedFiles.length - 1];

      try {
        const validUrl = await getDownloadURL(highestFile);
        //console.log("New transcript validated at:", validUrl);
        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        setShowTranscriptNote(false)

        currentFile.transcriptUrl = validUrl; 
        hasProcessedFileRef.current = currentFile.url; 

      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }
    }
    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processVideoAndSubmitScript();
  }
}, [currentFile]); // eslint-disable-line react-hooks/exhaustive-deps


useEffect(() => {
  const processRecordingAndSubmitScript = async () => {
    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'Recording') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (showPublic) {


      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "recordingTranscript/";
      const folderRef = ref(storage, folderPath);


      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {

        try {
 
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );

          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            await getDownloadURL(highestFile);

          setShowTextArea(false);
          setShowTranscript(true);
          setShowTranscriptNote(false)
          return; 
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Show note...");
        }
      }

      setShowTranscript(false);
      setShowTextArea(false);
      setShowTranscriptNote(true)
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");

      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "recordingTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
          
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );
    
          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            const validUrl = await getDownloadURL(highestFile);
            //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          setShowTranscriptNote(false)
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;

      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      // Perform transcription
      await handleTranscribeAudio(currentFile.url);

      const fileList = await listAll(folderRef);
    
      // Filter files to include only those with `cleanFileName1` as part of the name
      const matchingFiles = fileList.items.filter(item => 
        item.name.startsWith(cleanFileName1)
      );

      if (matchingFiles.length === 0) {
        console.warn("No matching files found for:", cleanFileName1);
      } else {
        // Sort files by number in filename, defaulting to 0 if no number exists
        const sortedFiles = matchingFiles.sort((a, b) => {
          const extractNumber = (name: string) => {
            const match = name.match(/\((\d+)\)\.pdf$/);
            return match ? parseInt(match[1], 10) : 0;
          };
          return extractNumber(a.name) - extractNumber(b.name);
        });

        // Get the last file (highest number or cleanFileName1 + .pdf)
        const highestFile = sortedFiles[sortedFiles.length - 1];

        try {
        const validUrl = await getDownloadURL(highestFile);
        //console.log("New transcript validated at:", validUrl);


        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        currentFile.transcriptUrl = validUrl; // Update file metadata
        hasProcessedFileRef.current = currentFile.url; // Mark as processed

      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }

      }
    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processRecordingAndSubmitScript();
  }
}, [currentFile]); // eslint-disable-line react-hooks/exhaustive-deps


// Functions for motion audio player
const getAudioUrl = async (url: string,fileRefPath:string) => {
    // Fetch metadata for duration
    const storageRef = ref(storage, fileRefPath);
    const metadata = await getMetadata(storageRef);
    const duration = metadata.customMetadata?.Duration;
    //console.log('fileRefPath',fileRefPath)
    //console.log('duration',duration)
    if (duration) {
      setMetadataDuration(parseFloat(duration));
    } else {
      setMetadataDuration(null);
    }
  return fetchAudioFromProxy(url);
};


  useEffect(() => {
    if (!audioMotionAnalyzerRef.current) {
      const container = document.getElementById('audio-motion-container');
      if (container) {
        audioMotionAnalyzerRef.current = new AudioMotionAnalyzer(container, {
          mode: 1, // Replace with the correct numeric value for 'waveform'
          gradient: 'rainbow',
        });
      }
    }

    return () => {
      // Cleanup AudioMotionAnalyzer
      audioMotionAnalyzerRef.current?.disconnectInput();
      audioMotionAnalyzerRef.current?.destroy();
      audioMotionAnalyzerRef.current = null;
    };
  }, []);

  const onPageChange = (page: number, viewer: 'main' | 'summary') => {
    const totalPages = viewer === 'main' ? mainTotalPages : summaryTotalPages;

    //console.log('viewer:', viewer);
    // Ensure the page is within valid bounds
    if (page >= 1 && page <= totalPages) {
     // console.log('Attempting to jump to page:', page);
  
      if (viewer === 'main') {
       // console.log('Jumping in main viewer');
        jumpToMainPage(page - 1); // Use main viewer's jumpToPage
        setMainCurrentPage(page);
        setInputValue(`${page || 1} of ${mainTotalPages}`);
      } else if (viewer === 'summary') {
        //console.log('Jumping in summary viewer');
        jumpToSummaryPage(page - 1); // Use summary viewer's jumpToPage
        setSummaryCurrentPage(page);
        setInputValue(`${page || 1} of ${summaryTotalPages}`);
      } else {
        console.warn('Unknown viewer');
      }
    } else {
      console.warn('Invalid page number:', page);
    }
  };

  const onMainPageChange = (page: number) => {
    onPageChange(page, 'main');
  };
  
  const onSummaryPageChange = (page: number) => {
    onPageChange(page, 'summary');
  };


  // Handle page load initialization
  useEffect(() => {
    const initializePageInput = () => {
      const totalPages =
        activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
       
        //console.log('mainCurrent Page',mainCurrentPage)

      // Ensure totalPages is a valid number
      if (totalPages && totalPages > 0) {
        // Set the initial page to 1
        setInputValue(`1 of ${totalPages}`);
        if (activeViewer === 'main') {
          setMainCurrentPage(1);
        } else {
          setSummaryCurrentPage(1);
        }
      } else {
        // Handle edge cases where totalPages is undefined or invalid
        setInputValue(`1 of ...`);
      }
    };
  
    initializePageInput();
  }, [activeViewer, mainTotalPages, summaryTotalPages]);
  

  useEffect(() => {
    //console.log('INput value2',inputValue)
    const pdfContainer =
      activeViewer === 'main' ? mainPdfViewerRef.current : summaryPdfViewerRef.current;
  
    if (!pdfContainer) {
      console.error('pdfContainer is not defined.');
      return;
    }
  
    const scrollableContainer = pdfContainer.querySelector(
      '.rpv-core__inner-pages'
    ) as HTMLElement;
  
    if (!scrollableContainer) {
      console.error('Scrollable container (.rpv-core__inner-pages) not found.');
      return;
    }
  
    let currentVisiblePage = 1;
  
    // Function to calculate the visible page using scroll position
    const calculateVisiblePage = () => {
      const totalPages = activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
  
      // Approximate height of a single page
      const pdfPages = scrollableContainer.querySelectorAll('.rpv-core__page-layer');
      if (pdfPages.length === 0) {
        console.error('No pages detected in the DOM.');
        return;
      }
  
      const singlePageHeight = pdfPages[0].getBoundingClientRect().height;
  
      // Current scroll position and approximate visible page
      const scrollPosition = scrollableContainer.scrollTop;
      const visiblePage = Math.floor(scrollPosition / singlePageHeight) + 1;
  
      // Ensure visiblePage stays within the total pages
      currentVisiblePage = Math.min(Math.max(visiblePage, 1), totalPages);
  
      //console.log(`Visible page: ${currentVisiblePage} of ${totalPages}`);
  
      if (activeViewer === 'main') {
        setMainCurrentPage(currentVisiblePage);
      } else {
        setSummaryCurrentPage(currentVisiblePage);
      }
  
      setInputValue(`${currentVisiblePage || 1} of ${totalPages}`);
    };
  
    const handleScroll = () => {
      calculateVisiblePage();
    };
  
    // Attach scroll listener
    scrollableContainer.addEventListener('scroll', handleScroll);
  
    // Trigger initial calculation
    calculateVisiblePage();
  
    // Observe DOM changes to detect new pages being added
    const domObserver = new MutationObserver(() => {
      //console.log('DOM updated, recalculating visible page.');
      calculateVisiblePage(); // Recalculate pages on new page load
    });
  
    domObserver.observe(scrollableContainer, { childList: true, subtree: true });
  
    return () => {
      scrollableContainer.removeEventListener('scroll', handleScroll);
      domObserver.disconnect();
    };
  }, [activeViewer, mainTotalPages, summaryTotalPages, mainPdfViewerRef, summaryPdfViewerRef,clickMain]);
  
  // Function to allow user to crop a rectangle area to chat

  useEffect(() => {
    const pdfContainer = mainPdfViewerRef.current;
    if (pdfContainer) {
      if (isCropping) {
        pdfContainer.classList.add('cropping-mode'); // Add the class when cropping is active
      } else {
        pdfContainer.classList.remove('cropping-mode'); // Remove the class when cropping is inactive
      }
    }
  }, [isCropping]); // Trigger this effect when isCropping changes


  const handleCropClick = () => {
    setIsCropping(true);
    setStartPoint(null);
    setEndPoint(null);
  };
  
  const handleMouseDown1 = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Calculate the start point relative to the crop area
      setStartPoint({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      });
    }
  };
  
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && startPoint && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Calculate the end point relative to the crop area
      setEndPoint({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      });
    }
  };
  
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && startPoint && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Finalize the end point relative to the crop area
      const newEndPoint = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      };
  
      setEndPoint(newEndPoint);
      setIsCropping(false);
      // Perform the screenshot operation
      captureCropArea(startPoint, newEndPoint);
      
    }
  };

  const captureCropArea = async (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => {
    if (!cropAreaRef.current) {
      console.error("Crop area reference is null");
      return;
    }
  
    const rect = {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
      width: Math.abs(end.x - start.x),
      height: Math.abs(end.y - start.y),
    };
  
    try {
      const canvas = await html2canvas(cropAreaRef.current);
      const scaleX = canvas.width / cropAreaRef.current.offsetWidth;
      const scaleY = canvas.height / cropAreaRef.current.offsetHeight;
  
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = rect.width * scaleX;
      croppedCanvas.height = rect.height * scaleY;
  
      const context = croppedCanvas.getContext("2d");
      if (!context) {
        console.error("Failed to get canvas context");
        return;
      }
  
      context.drawImage(
        canvas,
        rect.x * scaleX,
        rect.y * scaleY,
        rect.width * scaleX,
        rect.height * scaleY,
        0,
        0,
        rect.width * scaleX,
        rect.height * scaleY
      );
  
      croppedCanvas.toBlob(async (blob) => {
        if (blob) {
          try {
            // Save to clipboard
            await navigator.clipboard.write([
              new ClipboardItem({
                "image/png": blob,
              }),
            ]);

            //alert("Screenshot saved to clipboard and sent to chat if open!");
  
            // Additional functionality: Save to uploadedFiles and imagePreviewUrls
            if (isChatOpen) {
              const file = new File([blob], "Screenshot.png", { type: "image/png" });
              const url = URL.createObjectURL(file);
  
              // Update uploadedFiles
              setUploadedFiles((prevFiles) => [...prevFiles, file]);
  
              // Update imagePreviewUrls
              setImagePreviewUrls((prevUrls) => [...prevUrls, url]);
  
             // console.log("Screenshot saved to uploadedFiles and imagePreviewUrls");
            }
          } catch (err) {
            console.error("Failed to write to clipboard or save:", err);
          }

        }
      });
    } catch (error) {
      console.error("Error capturing crop area:", error);
    }
  };
  
  const removeImage = (index: number) => {
    // Remove the image from imagePreviewUrls
    const updatedUrls = [...imagePreviewUrls];
    updatedUrls.splice(index, 1);
    setImagePreviewUrls(updatedUrls);
    // Empty uploaded file
    setUploadedFiles([]);
  };
  
  const handleImageClick = (url: string) => {
    setCurrentImage(url);
    setShowPopup(true);
  };
  

  const closePopup = () => {
    setShowPopup(false);
    setCurrentImage(null);
  }

  // Memoize the handler function for jumpForward
  const handleJumpForward = useCallback(() => {
    if (jumpForward) {
       if(activeViewer === 'main') {mainJumpToNextMatch();}
       if(activeViewer === 'summary') {summaryJumpToNextMatch();}     
       setJumpForward(false); 
    }
  }, [jumpForward]);
  
  // Memoize the handler function for jumpBackward
  const handleJumpBackward = useCallback(() => {
    if (jumpBackward) {
       mainJumpToPreviousMatch();
       if(activeViewer === 'main') {mainJumpToPreviousMatch();}
       if(activeViewer === 'summary') {summaryJumpToPreviousMatch();}  
       setJumpBackward(false); 
    }
  }, [jumpBackward]);
  
  // useEffect to handle jumpForward
  useEffect(() => {
    handleJumpForward();
    handleJumpBackward();

  }, [handleJumpForward,handleJumpBackward]);

  // Ensure that handleMainNextMatch and handleSummaryNextMatch are memoized
  const handleMainNextMatch = useCallback(() => {
    setJumpForward(true);
  }, [setJumpForward]);

  const handleSummaryNextMatch = useCallback(() => {
    setJumpForward(true);
  }, [setJumpForward]);

  useEffect(() => {
    const performMainSearch = async () => {
      if (mainSearchKeyword.trim() !== '') {
        try {
          const regex = new RegExp(mainSearchKeyword, 'gi');
          const foundMatches = await mainHighlight([regex]); 
          setMainSearchFound(foundMatches.length > 0);         
        } catch (err) {
          console.error('Error during main PDF search highlighting:', err);
        }
      } else {
        mainClearHighlights();
        setMainSearchFound(false);
      }
    };
  
    const delayDebounceFn = setTimeout(() => {
      performMainSearch();
    }, 300); // 300ms debounce delay
  
    return () => clearTimeout(delayDebounceFn);
  }, [mainSearchKeyword]);
  
    // Summary Viewer Search Effect
    useEffect(() => {
      const performSummarySearch = async () => {
        if (summarySearchKeyword.trim() !== '') {
          try {
            const matches = await summaryHighlight([new RegExp(summarySearchKeyword, 'gi')]);
            setSummarySearchFound(matches.length > 0);
          } catch (err) {
            console.error('Error during summary PDF search highlighting:', err);
          }
        } else {
          summaryClearHighlights();
          setSummarySearchFound(false);
        }
      };

      const delayDebounceFn = setTimeout(() => {
        performSummarySearch();
      }, 300); // 300ms debounce delay

      return () => clearTimeout(delayDebounceFn);
    }, [summarySearchKeyword]);

      useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
          if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'f') {
            e.preventDefault();
            if (activeViewer === 'main') {
              setShowMainSearchBox((prev) => !prev);
              if (!showMainSearchBox) {
                setTimeout(() => {
                  const searchInput = document.getElementById('main-pdf-search-input') as HTMLInputElement;
                  if (searchInput) {
                    searchInput.focus();
                    searchInput.select();
                  }
                }, 0);
              }
            } else if (activeViewer === 'summary') {
              setShowSummarySearchBox((prev) => !prev);
              if (!showSummarySearchBox) {
                setTimeout(() => {
                  const searchInput = document.getElementById('summary-pdf-search-input') as HTMLInputElement;
                  if (searchInput) {
                    searchInput.focus();
                    searchInput.select();
                  }
                }, 0);
              }
            }
          }
        };
      
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [activeViewer, showMainSearchBox, showSummarySearchBox]);
      
          // Main Viewer Search Handlers
      const handleMainSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMainSearchKeyword(e.target.value);
      };

      const handleMainCloseSearch = () => {
        setMainSearchKeyword('');
        mainClearHighlights();
        setMainSearchFound(false);
        setShowMainSearchBox(false);
      };

      const handleMainPrevMatch = () => {
        setJumpBackward(true);
      };

    // Summary Viewer Search Handlers
    const handleSummarySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSummarySearchKeyword(e.target.value);
    };

    const handleSummaryCloseSearch = () => {
      setSummarySearchKeyword('');
      summaryClearHighlights();
      setSummarySearchFound(false);
      setShowSummarySearchBox(false);
    };

    const handleSummaryPrevMatch = () => {
      setJumpBackward(true);
    };

  // Handler Functions for Enter Key
  const handleMainKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleMainNextMatch();
      }
    },
    [handleMainNextMatch]
  );

  const handleSummaryKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSummaryNextMatch();
      }
    },
    [handleSummaryNextMatch]
  );

  // Function to adjust the height of the textarea
  const adjustTextareaHeight = () => {
    const textarea = chatTextareaRef.current;
    if (textarea) {
      if (newMessage.trim() === '') {
        textarea.style.height = '24px'; // Minimal height when empty
      } else {
        textarea.style.height = 'auto'; // Reset height to calculate scrollHeight correctly
        const newHeight = Math.min(textarea.scrollHeight, 300); // Cap the height at 300px
        textarea.style.height = `${newHeight}px`;
      }
    }
  };

  const handleZoomIn = () => {
    setCurrentZoom((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.1, 2.0); // Increase zoom by 10%, capped at 200%  
      if (activeViewer === 'main') {
        mainZoomPlugin.zoomTo(newZoom);
        mainPdfViewerRef.current?.style.setProperty('--scale-factor', `${newZoom}`);
      } else if (activeViewer === 'summary') {
        summaryZoomPlugin.zoomTo(newZoom);
        summaryPdfViewerRef.current?.style.setProperty('--scale-factor', `${newZoom}`);
      }
  
      return newZoom; // Return the new zoom level to update the state
    });
  };

  const handleZoomOut = () => {
    setCurrentZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.1, 0.5); // Decrease zoom by 10%, capped at 50%  
      if (activeViewer === 'main') {
        mainZoomPlugin.zoomTo(newZoom);
        mainPdfViewerRef.current?.style.setProperty('--scale-factor', `${newZoom}`);
      } else if (activeViewer === 'summary') {
        summaryZoomPlugin.zoomTo(newZoom);
        summaryPdfViewerRef.current?.style.setProperty('--scale-factor', `${newZoom}`);
      }
  
      return newZoom; // Return the new zoom level to update the state
    });
  };

  useEffect(() => {
    if (fileDocumentType) {
      if (fileDocumentType === 'Recording' || fileDocumentType === 'YouTube Video') {
        setSelectedModel('DeepSeek')
      } else {
        setSelectedModel('ChatGPT')
      }
    }
  }, [fileDocumentType]); // Trigger this effect when isCropping changes

  if (!isOpen || !pdfUrl) return null;

  return (

    <div className="modal-overlay"  
        ref={cropAreaRef}
        onMouseDown={handleMouseDown1}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
      <div className={`pdf-viewer-model ${isChatOpen||isQnaOpen||isSummaryOpen ? 'expanded' : 'shrink'}`} style={{ cursor: isCropping ? 'crosshair' : (isPanning ? 'grab' : 'default'),  }}>
        <div className="pdf-toolbar">
          <div className="zoom-buttons-container" style={{ display: 'flex', gap: '10px',userSelect: 'none', }} >

          {activeViewer === 'main' && (
              <>
              <button onClick={handleZoomIn} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '-10px' }}>
              <MainZoomInButton />
              </button>
              <input
                type="text"
                value={`${Math.round(currentZoom * 100)}%`} // Display zoom percentage
                readOnly // Make the input read-only
                style={{
                  width: '50px', // Adjust width as needed
                  textAlign: 'center',
                  color: 'white',
                  border: 'none',
                  marginTop: '15px',
                  marginLeft: '10px',
                  marginRight: '-30px',
                  background: 'transparent',
                  padding: '2px',
                }}
              />
            
              <button onClick={handleZoomOut} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '-10px', marginRight: '10px' }}>
              <MainZoomOutButton /> 
              </button>
            </>
          )}
          {activeViewer === 'summary' && (
            <>
              <button onClick={handleZoomIn} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '-10px' }}>
                <SummaryZoomInButton />
              </button>
              <input
                type="text"
                value={`${Math.round(currentZoom * 100)}%`} // Display zoom percentage
                readOnly // Make the input read-only
                style={{
                  width: '50px', // Adjust width as needed
                  textAlign: 'center',
                  color: 'white',
                  border: 'none',
                  marginTop: '15px',
                  marginLeft: '10px',
                  marginRight: '-30px',
                  background: 'transparent',
                  padding: '2px',
                }}
              />
              <button onClick={handleZoomOut} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '-10px', marginRight: '10px' }}>
                <SummaryZoomOutButton />
              </button>
            </>
            )}
            <button 
              onClick={handlePanClick} 
              style={{ background: 'none', border: 'none', cursor: 'pointer', color: isPanning ? 'blue' : 'white' }}
            >
              <FaHandPaper size={18} title="Pan" />
            </button>

            <button onClick={handleFullPageClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <FaExpand size={18} title="One Full Page" />
            </button>

            <button
                onClick={handleCropClick}
                style={{ background: 'none', border: 'none', cursor: 'pointer',color: isCropping ? 'blue' : 'white' }}
              >
                <FaCrop size={18} title="Crop Screen" />
            </button>


            <input
              className="pdf-page-input"
              style={{userSelect:  'none',  }}
              type="text"
              value={inputValue}
              placeholder={`1 of ${
                activeViewer === 'main' ? mainTotalPages : summaryTotalPages
              }`}
              onChange={(e) => {
                // Allow free input, including blank
                setInputValue(e.target.value);
              }}
              onBlur={() => {
                const totalPages =
                  activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
                const newPage = parseInt(inputValue, 10);

                if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                  // Navigate to the page if valid
                  if (activeViewer === 'main') {
                    onMainPageChange(newPage);
                  } else {
                    onSummaryPageChange(newPage);
                  }
                  // Set the formatted value
                  setInputValue(`${newPage || 1} of ${totalPages}`);
                } else {
                  // Reset to current page if invalid
                  setInputValue(
                    activeViewer === 'main'
                      ? `${mainCurrentPage || 1} of ${mainTotalPages}`
                      : `${summaryCurrentPage || 1} of ${summaryTotalPages}`
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const totalPages =
                    activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
                  const newPage = parseInt(inputValue, 10);

                  if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                    // Navigate to the page if valid
                    if (activeViewer === 'main') {
                      onMainPageChange(newPage);
                    } else {
                      onSummaryPageChange(newPage);
                    }
                    // Set the formatted value
                    setInputValue(`${newPage || 1} of ${totalPages}`);
                  } else {
                    // Reset to current page if invalid
                    setInputValue(
                      activeViewer === 'main'
                        ? `${mainCurrentPage || 1} of ${mainTotalPages}`
                        : `${summaryCurrentPage || 1} of ${summaryTotalPages}`
                    );
                  }
                  e.currentTarget.blur();
                }
              }}

            />

          </div>

          <div className="action-buttons-container" style={{ display: 'flex', gap: '10px' }}>
          {currentFile && currentFile.documentType === 'Note' && (    
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '5px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(pdfUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: qnaStatus[pdfUrl!] ? 'green' : 'red',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              ></button>

              <button
                className="chat-button"
                onClick={() => handleQnaButtonClick()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                title="Q & A"
              >
                Q & A
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(pdfUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: summaryStatus[pdfUrl!] ? 'green' : 'red',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              ></button>

              <button
                className="chat-button"
                onClick={() => { handleSummaryButtonClick(); }}
                style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                title="Summary"
              >
                <FaClipboard size={18} />
              </button>
            </div>
          )}

          {currentFile && (currentFile.documentType === 'YouTube Video' || currentFile.documentType === 'Recording') && (
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '5px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(transcriptUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: qnaStatus[pdfUrl!] ? 'green' : 'red',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              ></button>

              <button
                className="chat-button"
                onClick={() => handleQnaButtonClick()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                title="Q & A"
              >
                Q & A
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(transcriptUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: summaryStatus[pdfUrl!] ? 'green' : 'red',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                }}
              ></button>

              <button
                className="chat-button"
                onClick={() => { handleSummaryButtonClick(); }}
                style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                title="Summary"
              >
                <FaClipboard size={18} />
              </button>
            </div>
          )}

          <button
            className="chat-button"
            onClick={() => {
              if (isLoggedIn) {
                handleChatButtonClick();
              } else {
                alert('Please Login to Chat on File.');
              }
            }}
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Chat"
            disabled={isAssistantLoading} // Disable the button while loading
          >
            {isAssistantLoading ? "Loading..." : <FaCommentDots size={18} />}
          </button>


          {leaving && <div className="leaving-overlay">Leaving...</div>}
        </div>

        <div className='collect-close-buttons'>

          <div className="action-buttons-container" style={{ display: 'flex', gap: '10px' }}>
              {currentFile && showPublic && (
                <button
                  onClick={() => {
                    if (isLoggedIn) {
                      onCollectFile(pdfUrl, currentFile.name, currentFile.documentType);
                    } else {
                      alert('Please Login to Collect File.');
                    }
                  }}

                  style={{ background: 'none', marginRight: '20px',border: 'none'}}
                  title="Collect File"
                >
                       
                {isCollecting ? 'Collecting' : <FaFolderOpen size={18} /> }

                </button>

              )}
              <button
              onClick={() => {
                if (isLoggedIn) {
                  onDownloadFile(pdfUrl);
                } else {
                  alert('Please Login to download File.');
                }
              }}
              style={{ background: 'none', border: 'none' }}
              title="Download File"
              >
              {isDownloading ? 'Downloading' : <FaDownload size={18} /> }   
              </button>

              <Print>
              {(props) => (
                <button
                  onClick={props.onClick}
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                  title="Print File"
                >
                  <FaPrint size={18} />
                </button>
              )}
            </Print>

          </div>

          <button
            onClick={() => handleClose(false)}
            className="close-viewer-button"
            style={{ marginLeft: '20px' }}
            >
            Close
        </button>
        </div>

        </div>

        <div className={`modal-container ${isChatOpen ? 'expanded' : 'shrink'}`}
           >

          {isCropping && startPoint && endPoint && (
            <div
              style={{
                position: 'absolute',
                left: Math.min(startPoint.x, endPoint.x),
                top: Math.min(startPoint.y, endPoint.y),
                width: Math.abs(endPoint.x - startPoint.x),
                height: Math.abs(endPoint.y - startPoint.y),
                border: '2px solid black',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                pointerEvents: 'none',
                zIndex: 99999,
              }}
            />
          )}


          <div
            className={`pdf-viewer-container ${isCropping ? 'cropping-mode' : ''}`}
            ref={mainPdfViewerRef}
            onMouseDown={handleMouseDown}
            onClick={(e) => {
              e.stopPropagation(); // Prevent propagation if necessary
              //console.log('Container clicked');
              setActiveViewer('main'); // Ensure this function updates the state properly
              //console.log('active viewer',activeViewer);
              setClickMain(true);
            }}

            style={{ width: `${pdfViewerWidth}%`, userSelect: activeViewer === 'main' ?  isCropping ? 'none':'text': 'none', }}
          >
          {currentFile && (
            <>
              {currentFile.documentType === 'Note' && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  
                  <Viewer 
                    fileUrl={pdfUrl} 
                    plugins={[mainZoomPlugin, toolbarPluginInstance,mainPageNavigationPluginInstance,mainSearchPluginInstance,printPluginInstance]} 
                    onDocumentLoad={(e) => handleDocumentLoad(e)}
                  />
                </Worker>
              )}

          {currentFile.documentType === 'Recording' && (
            <div>
              {/* Pull-down menu for selecting audio player */}
              <div className="audio-player-selector"
              style={{  userSelect: 'none'}}
              >
                <select
                  value={showSimpleAudioPlayer ? 'simpleAudioPlayer' : 'motionAudioPlayer'}
                  onChange={(e) => {
                    const selectedPlayer = e.target.value;
                    if (selectedPlayer === 'simpleAudioPlayer') {
                      setShowSimpleAudioPlayer(true);
                      setShowMotionAudioPlayer(false);
                    } else {
                      setShowSimpleAudioPlayer(false);
                      setShowMotionAudioPlayer(true);
                    }
                  }}
                >
                  <option value="simpleAudioPlayer">Simple Audio Player</option>
                 {/*  <option value="motionAudioPlayer">Motion Audio Player</option>*/}
                </select>
              </div>

              {/* Render Simple Audio Player */}
              {showSimpleAudioPlayer && (
                <div className="audio-play-bar">
                  <div className="audio-controls">
                    <button onClick={handleRewind}><FaBackward size={18} /></button>
                    <button
                      onClick={() => {
                        handlePlayAudio(currentFile.url, currentFile.refPath);
                      }}
                    >
                      {isPlaying ? <FaPause size={18} /> : <FaPlay size={18} />}
                    </button>
                    <button onClick={handleFastForward}><FaForward size={18} /></button>
                    <button onClick={handleSpeedChange}>
                      {`${playbackSpeed}x`}
                    </button>
                  </div>
                  <div className="audio-progress-container">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={isNaN(audioProgress) ? 0 : audioProgress}
                      onChange={handleAudioSeek}
                      className="audio-progress-bar"
                      style={{  userSelect: 'none'}}
                    />
                  </div>

                  <div style={{ textAlign: 'center', fontSize: '14px', color: '#555', marginTop: '10px',  userSelect: 'none' }}>
                    <span style={{ fontWeight: 'bold' }}>Elapsed:</span>{' '}
                    {new Date((audioRef.current?.currentTime ?? 0) * 1000).toISOString().substr(11, 8)}{' '}
                    <span style={{ fontWeight: 'bold', margin: '0 5px' }}>/</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>Total:</span>{' '}
                    {new Date((currentFile.duration ?? 0) * 1000).toISOString().substr(11, 8)}
                  </div>
                </div>
              )}

              {/* Render Motion Audio Player */}
              {showMotionAudioPlayer && (
                <div>
                  <CustomAudioMotionAnalyzer
                    fetchAudioBlob={() => getAudioUrl(currentFile.url, currentFile.refPath)}
                    onAudioContextReady={(analyzerInstance: AudioMotionAnalyzer) => {
                      audioMotionAnalyzerRef.current = analyzerInstance;
                    }}
                    metadataDuration={metadataDuration}
                  />
                </div>
              )}

              <h3 style={{ color: 'white', userSelect: 'none' }}>Transcript</h3>

              {showTranscriptNote && (
                 <p style={{ color: 'white', textAlign: 'left', fontSize: '18px' , userSelect: 'none'}}>
                  No transcript available. Transcript can only be generated within My Space. 
                 </p>
               )}

              {showTextArea && (
              <div className="textarea-container" style={{ position: 'relative' }}>
                <textarea
                  value={textareaContent}
                  onChange={(e) => setTextareaContent(e.target.value)}
                  placeholder="Paste Information Here"
                  className="textarea-input"
                  readOnly={isConverting}
                  ref={textareaRef} // Attach ref to textarea
                />
              </div>
              )}
            {showTranscript && (
              <div className="audio-transcript" 
                style={{ userSelect: activeViewer === 'main' ? 'text' : 'none', height: `${pdfViewerWidth}%` }}
              >
                {currentFile?.transcriptUrl || transcriptUrl ? (
                  <TranscriptText pdfUrl={currentFile?.transcriptUrl || transcriptUrl} />
                ) : (
                  <p>No transcript available.</p>
                )}
              </div>
            )}

            </div>
          )}

            {currentFile.documentType === 'YouTube Video' && (
              <div>
                <div className="video-container">
                  <ReactPlayer
                    ref={playerRef}
                    url={currentFile.youtubeUrl || undefined} // Use undefined if no URL
                    playing={Boolean(currentFile.youtubeUrl)}
                    controls
                    className="react-player"
                    width="100%" // Make the player take the full width of the parent
                    height="100%" // Optional: Adjust height as needed
                  />
                </div>
                <div className="transcript-container" style={{ position: 'relative', color: 'white',}}>
                  <h3>Transcript</h3>
                  {isConverting && (
                    <div>
                      <p>Transcribing: {Math.round(progress)}% complete</p>
                      <progress value={progress} max={100}></progress>
                    </div>
                  )}
                  {showTranscript && (
                    <div className="video-transcript"
                      style={{ userSelect: activeViewer === 'main' ? 'text' : 'none' }}
                    >
                      {currentFile?.transcriptUrl || transcriptUrl ? (
                        <TranscriptText pdfUrl={currentFile?.transcriptUrl || transcriptUrl} />
                      ) : (
                        <p>No transcript available.</p>
                      )}
                    </div>
                  )}

                </div>

                {showTranscriptNote && (
                 <p style={{ color: 'white', textAlign: 'left', fontSize: '18px' ,  userSelect: 'none',}}>
                  No transcript available. Transcript can only be generated within My Space. 
                 </p>
               )}

               {showTextArea && (
                <div className="textarea-container" style={{ position: 'relative' }}>
                  <textarea
                    value={textareaContent}
                    onChange={(e) => setTextareaContent(e.target.value)}
                    placeholder="Paste Information Here"
                    className="textarea-input"
                    readOnly={isConverting}
                    ref={textareaRef} // Attach ref to textarea
                  />
                </div>
               )}

              </div>
            )}


            {currentFile.documentType === 'Subject' && (
              <div className="library-items" style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
                {libraryItems.map((item, index) => (
                  <div key={index} className="library-item" style={{ marginBottom: '10px' }}>
                    <div
                      className="library-card"
                      style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px'}}
                    >
                      <div className="front">
                        <p style={{ color: 'white' }}><strong>Question:</strong> {item.question}</p>
                        {item.options && (
                          <ul style={{ paddingLeft: '20px' }}>
                            {Object.entries(item.options).map(([key, value]) => (
                              <li key={key} style={{ color: 'white' }}>
                                <strong>{key}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        )}
                        {showAnswerIndexes.includes(index) && (
                          <p style={{ marginTop: '10px', color: 'white' }}>
                            <strong>Answer:</strong> {item.answer}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Button container */}
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px', marginLeft: '100px' }}>
                      <button 
                        title={showAnswerIndexes.includes(index) ? "Hide Answer" : "See Answer"} 
                        onClick={() => handleToggleAnswer(index)} 
                        className="icon-button"
                      >
                        {showAnswerIndexes.includes(index) ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                      </button>
                      <button title="Delete" onClick={() => handleDeleteItem(index)} className="icon-button">
                        <FaEraser size={20} /> 
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        </div>
          <div 
            className="resize-bar" 
            onMouseDown={handleMouseDownOnResizeBar} 
          />

          {isQnaOpen && (
              <div className="chat-window">
                  <div className="chat-header">
                      <h3 style={{ color: 'white' }}>Q&A</h3>                
                      <button
                          className="cancel-chat-button"
                          onClick={() => handleQnaClose()}
                          style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
                      >
                          Cancel
                      </button>
                  </div>
                  
                  {/* Integrate Qna component here */}
                  <Qna 
                   currentFile={currentFile}
                   />
              </div>
          )}

          {isSummaryOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Summary</h3>

            <button
                className="cancel-chat-button"
                onClick={() => handleSummaryClose()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
            </button>

            </div>

            <div 
              className="pdf-viewer-container" 
              onClick={(e) => {
                e.stopPropagation(); // Prevent propagation if necessary
                //console.log('Container clicked');
                setActiveViewer('summary'); // Ensure this function updates the state properly
                //console.log('active viewer',activeViewer);

              }}
              ref={summaryPdfViewerRef}
              onMouseDown={(e) => {
               // console.log('Mouse down event');
                handleMouseDown(e);
              }}
              style={{ width: '98%', zIndex: 1, userSelect: activeViewer === 'summary' ? 'text' : 'none',  }} // Added zIndex for potential overlap issues
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {currentFile?.summaryUrl ? (
                  <Viewer 
                    fileUrl={currentFile.summaryUrl} 
                    plugins={[summaryZoomPlugin, toolbarPluginInstance, summaryPageNavigationPluginInstance,summarySearchPluginInstance,printPluginInstance]}  
                    onDocumentLoad={(e) => handleDocumentLoad(e)}
                  />
                ) : (
                  <p style={{ color: 'white', textAlign: 'left', fontSize: '18px' }}>
                    No summary available. Generate a summary with the circle button on the left side first. Green circle indicates a summary is available; Red circle indicates not available.
                  </p>
                )}
              </Worker>
            </div>

            </div>
          )}

          {isChatOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Chat</h3>
            <button
                className="cancel-chat-button"
                onClick={() => handleChatClose()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
              </button>
            </div>

            <div className="chat-content">
            {/* Preloaded message */}
            {showPreloadedMessage && (
                <p style={{ color: 'white', textAlign: 'left', fontSize: '18px' }}>
                     {currentFile?.transcriptUrl === '' && fileDocumentType !== 'Note'  
                          ?  'I cannot directly view or summarize audios or videos. However, if you have a transcript or description of the video, feel free to share it, and I would be happy to chat on that!'
                          :  'You can ask any questions related to the document. You can also select specific texts from the PDF to ask a question, explain the text, or provide a summary.'
                          }
                </p>
            )}

            {(selectedModel === 'ChatGPT' ? assistant?.chat : deepseekChat)?.map((chatMsg, index) => (
              <div 
                key={index} 
                onMouseEnter={() => setHoveredIndex(index)} 
                onMouseLeave={() => setHoveredIndex(null)} 
                style={{ position: 'relative' }} // Position relative for absolute button positioning
              >
                <p
                  style={{
                    color: chatMsg.from === 'user' ? 'white' : 'white',
                    textAlign: chatMsg.from === 'user' ? 'right' : 'left',
                    fontSize: '18px',
                  }}
                >
                  <strong>
                    {chatMsg.from === 'user' 
                      ? firstName || 'You' 
                      : 'Assistant'}:
                  </strong>

                  <div className="assistant-response-container">
                    {formatContent(chatMsg.message, copiedKey, setCopiedKey)}

                    {chatMsg.from === 'assistant' && !loading && (
                      <div 
                        className="pdf-response-buttons" 
                        style={{
                          display: (index === (selectedModel === 'ChatGPT' && assistant ? assistant.chat.length : deepseekChat.length) - 1 || hoveredIndex === index) ? 'block' : 'none',
                          position: 'absolute',
                          right: '0',
                          bottom: '-30px',
                        }}
                      >
                        <button 
                          onClick={() => readAloud(chatMsg.message)} 
                          title="Read Aloud" 
                          style={{ cursor: 'pointer', marginRight: '5px', color: isPlaying ? 'green' : 'inherit' }}
                        >
                          {isPlaying ? <FaVolumeUp style={{ color: 'green' }} size={16} /> : <FaVolumeUp size={16} />}
                        </button>
                        <button 
                          onClick={() => handleCopyResponse(index, chatMsg.message)} 
                          title="Copy Response"
                          style={{ cursor: 'pointer' }}
                        >
                          {copiedResponseIndex === index ? <FaClipboardCheck size={16}/> : <FaClipboard size={16} />}
                        </button>
                      </div>
                    )}
                  </div>
                </p>
              </div>
            ))}

            {/* Show thinking indicator after the last user message */}
              {isAssistantThinking && (
                <div className="thinking-indicator">
                  <div className={`chat-message assistant`}>
                  <strong>
                      Assistant:
                  </strong>
                  </div>
                  <div className="thinking-dot"></div>
                </div>
              )}
            {/* Dummy div to ensure scrolling to the bottom */}
            <div ref={dummyDivRef} />
            </div>
              <div className="chat-input-container">
              {imagePreviewUrls.length > 0 && (

                <div className="image-preview">
                  {imagePreviewUrls.map((url, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        margin: "10px",
                      }}
                    >
                      <img
                        src={url}
                        alt={`Preview ${index + 1}`}
                        style={{ width: "50px", height: "50px" }}
                        onClick={() => handleImageClick(url)}
                      />
                      <button
                        onClick={() => removeImage(index)} // Pass the current index
                        style={{
                          position: "absolute",
                          top: "-24px",
                          right: "-5px",
                          backgroundColor: "transparent",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
                
                )}

              <div className="input-row">
                <label 
                  title="Upload" 
                  htmlFor="file-upload" 
                  style={{ cursor: 'pointer', alignSelf: 'flex-end', color: 'white' }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                </label>
                <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />
                <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);

                        // Reset height to 30px if the input is empty
                        if (e.target.value.trim() === '' && newMessage === '') {
                            e.target.style.height = '24px';
                        } else {
                            // Dynamically adjust the height based on content
                            e.target.style.height = 'auto'; // Reset the height to calculate the scrollHeight properly
                            e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Cap the height at 300px
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                            setNewMessage(''); 
                        }
                    }}
                    placeholder="Type a message"
                    className="chat-input"
                    rows={2}
                    ref={chatTextareaRef}
                />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? setLoading(false) : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                  disabled={isSendDisabled} // Disable sending until assistant is ready
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>
                </div>

          {/* 
                  <div className="model-option">
                    <select
                      value={selectedModel}
                      onChange={(e) => setSelectedModel(e.target.value as 'ChatGPT' | 'DeepSeek')}
                      >
                      <option value="ChatGPT">ChatGPT</option>
                      <option value="DeepSeek">DeepSeek</option>
                    </select>
                  </div>

           */}
              </div>
            </div>
          )}
          {/* Show action buttons near the selected text */}
          {selectedText && selectionPosition && isChatOpen && (
            <div
              style={{
                position: 'absolute',
                top: `${selectionPosition.top}px`,
                left: `${selectionPosition.left}px`,
                zIndex: 1000,
                backgroundColor: 'transparent',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <button
                className="pdf-text-button"
                onClick={() => handleAction('question')}
              >
                Question
              </button>

              <button
                className="pdf-text-button"
                onClick={() => handleAction('explain')}
              >
                Explain
              </button>
                {selectionWithinPDF && (
              <button
                className="pdf-text-button"
                onClick={() => handleAction('summary')}
              >
                Summary
              </button>
              )}
            </div>
          )}
            <audio
            ref={audioRef} // Reference to control the audio element
            onTimeUpdate={handleAudioProgress} // Update the progress bar as the audio plays
            onLoadedMetadata={handleMetadataLoaded} // Reset progress bar when metadata (like duration) is loaded
            onEnded={() => setPlayingAudio(null)} // Reset when audio finishes
            />

            {/* Pop-up for larger image */}
            {showPopup && currentImage && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={currentImage}
                    alt="Larger Preview"
                    style={{ maxWidth: "90vw", maxHeight: "90vh" }}
                  />
                  <button
                    onClick={closePopup}
                    style={{
                      position: "absolute",
                      top: "-15px", // Move above the image
                      right: "-15px", // Move to the right outside the image
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: add some styling for a better look
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}


            {/* Main Viewer Search Box */}
            {showMainSearchBox && (
              <div
                style={{
                  position: 'absolute',
                  top: '150px',
                  left: '50px',
                  backgroundColor: '#333',
                  padding: '10px',
                  borderRadius: '5px',
                  zIndex: 99999,
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center'
                }}
              >
                <input
                  id="main-pdf-search-input"
                  type="text"
                  value={mainSearchKeyword}
                  onChange={handleMainSearchChange}
                  onKeyDown={handleMainKeyDown}
                  placeholder="Search in Main PDF..."
                  style={{ padding: '5px', borderRadius: '3px' }}
                />
                <button
                  onClick={handleMainPrevMatch}
                  disabled={!mainSearchFound}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: mainSearchFound ? 'pointer' : 'not-allowed' }}
                  title="Previous match"
                >
                  Prev
                </button>
                <button
                  onClick={handleMainNextMatch}
                  disabled={!mainSearchFound}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: mainSearchFound ? 'pointer' : 'not-allowed' }}
                  title="Next match"
                >
                  Next
                </button>
                <button
                  onClick={handleMainCloseSearch}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: 'pointer' }}
                  title="Close search"
                >
                  X
                </button>
              </div>
            )}

            {/* Summary Viewer Search Box */}
            {showSummarySearchBox && (
              <div
                style={{
                  position: 'absolute',
                  top: '150px',
                  right: '50px',
                  backgroundColor: '#333',
                  padding: '10px',
                  borderRadius: '5px',
                  zIndex: 99999,
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center'
                }}
              >
                <input
                  id="summary-pdf-search-input"
                  type="text"
                  value={summarySearchKeyword}
                  onChange={handleSummarySearchChange}
                  onKeyDown={handleSummaryKeyDown}
                  placeholder="Search in Summary PDF..."
                  style={{ padding: '5px', borderRadius: '3px' }}
                />
                <button
                  onClick={handleSummaryPrevMatch}
                  disabled={!summarySearchFound}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: summarySearchFound ? 'pointer' : 'not-allowed' }}
                  title="Previous match"
                >
                  Prev
                </button>
                <button
                  onClick={handleSummaryNextMatch}
                  disabled={!summarySearchFound}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: summarySearchFound ? 'pointer' : 'not-allowed' }}
                  title="Next match"
                >
                  Next
                </button>
                <button
                  onClick={handleSummaryCloseSearch}
                  style={{ background: '#555', color: 'white', border: 'none', padding: '5px', cursor: 'pointer' }}
                  title="Close search"
                >
                  X
                </button>
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default PdfViewerModal;