import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { ref, getDownloadURL, listAll, uploadString,updateMetadata,getMetadata} from 'firebase/storage';
import { getFirestore,collection, addDoc, query,where,getDocs} from 'firebase/firestore';
import { FaFolderOpen, FaShareAlt, FaFire, FaRandom, FaExpand, FaCompress, FaCheck, FaArrowLeft, FaArrowRight, FaStar } from 'react-icons/fa';
import './Base.css';

// Define the type for the file object
interface FileType {
  name: string;
  url: string;
  refPath: string;
}

// Define the type for the state passed via location
interface LocationState {
  selectedFile: string | null;
  files: FileType[];
}

// Define the type for the question object
interface QuestionType {
  question: string;
  options?: { [key: string]: string };
  answer: string;
  starred?: boolean; // Add a starred property to the question type
}

interface FileData {
  name: string;
  userId: string;
  url: string;
  youtubeUrl: string;
  thumbnailUrl:string;
  refPath: string;
  createdAt: Date; 
  viewCount: number;
  documentType: string; 
  shareWithPublic: string; 
  userImageUrl: string; 
  qnaUrl:string;
  summaryUrl:string;   
}


interface QnaProps {
  currentFile: FileData | null;
}

const Qna: React.FC<QnaProps> = ({ currentFile }) => {
  const location = useLocation();

  const { selectedFile: navigatedSelectedFile, files: navigatedFiles, selectedSubject: navigatedSubject} = 
    (location.state as LocationState & { selectedSubject?: string; subjects?: string[] }) || { 
      selectedFile: null, 
      files: [], 
      selectedSubject: null, 
      subjects: [] 
    };

  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [subjectFromNav] = useState<string | null>(navigatedSubject || null);
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // New state for the share modal
  const [shareEmail, setShareEmail] = useState(''); // State for recipient's email
  const [shareMessage, setShareMessage] = useState(''); // State for sharing message
  const [shuffledQuestions, setShuffledQuestions] = useState<QuestionType[]>([]); // New state for shuffled questions
  const [starredQuestions, setStarredQuestions] = useState<QuestionType[]>([]); // New state for starred questions
  const [isShuffled, setIsShuffled] = useState(false); 
  const [isStarred, setIsStarred] = useState(false); 
  const [isStarredQuestion, setIsStarredQuestion] = useState(false); 
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const [isTouching, setIsTouching] = useState(false); // New flag to track touch events
  const [questionContainerColor, setQuestionContainerColor] = useState<string>('#2b2f33');
  const [streaks, setStreaks] = useState<number>(0); 
  const [scrollingTimeout, setScrollingTimeout] = useState<NodeJS.Timeout | null>(null);

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (currentFile?.qnaUrl) {
        try {
          const response = await fetch(currentFile.qnaUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch questions: ${response.statusText}`);
          }
          const data = await response.json();
          setQuestions(data || []);
        } catch (error) {
          console.error('Error fetching questions:', error);
          setQuestions([]); // Fallback to an empty array in case of error
        }
      }
    };
  
    // Fetch questions when currentFile changes or is available
    if (currentFile?.qnaUrl) {
      fetchQuestions();
    }
  }, [currentFile]); // Trigger when currentFile changes
  

  useEffect(() => {
    const questionContainer = questionContainerRef.current;
    if (!questionContainer) return;
  
    let startY = 0;
    let endY = 0;
    let touchStartTime = 0;
    const minSwipeDistance = 20;
    const maxTapDuration = 80;
    let isButtonInteraction = false;
    let lastScrollTime = 0; // To debounce wheel events
  
    const handleTouchStart = (e: TouchEvent) => {
      setIsTouching(true);
      touchStartTime = Date.now();
      if (e.touches.length > 2) return;
      startY = e.touches[0].clientY;
      isButtonInteraction =
        (e.target instanceof HTMLButtonElement) ||
        (e.target instanceof HTMLInputElement) ||
        (e.target instanceof HTMLTextAreaElement) ||
        (e.target instanceof HTMLDivElement && e.target.className.includes('show-answer-button'));
    };
  
    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches.length > 2) return;
      endY = e.touches[0].clientY;
  
      // Prevent the default scroll behavior of the parent container
      e.preventDefault();
    };
  
    const handleTouchEnd = () => {
      const touchDuration = Date.now() - touchStartTime;
      const distance = startY - endY;
  
      if (!isButtonInteraction && touchDuration > maxTapDuration && Math.abs(distance) > minSwipeDistance) {
        if (distance > 0) {
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
        } else {
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
        }
      }
  
      setIsTouching(false);
    };


    const handleWheel = (e: WheelEvent) => {
      // If we are currently in a cooldown, ignore subsequent events
      if (scrollingTimeout) {
        e.preventDefault();
        return;
      }
  
      if (e.ctrlKey || e.metaKey || e.altKey) return;
  
      const now = Date.now();
      const delta = e.deltaY;
  
      const MIN_DELTA = 20;
      const MIN_INTERVAL = 200; 
  
      if (now - lastScrollTime > MIN_INTERVAL && Math.abs(delta) > MIN_DELTA) {
        if (delta > 0) {
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
        } else {
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
        }
        lastScrollTime = now;
  
        // Start a cooldown to ignore subsequent wheel events for a short period
        const timeoutId = setTimeout(() => {
          setScrollingTimeout(null);
        }, 300);
        setScrollingTimeout(timeoutId);
      }
  
      e.preventDefault();
    };


    // Add event listeners with { passive: false } to allow preventDefault()
    questionContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
    questionContainer.addEventListener('touchmove', handleTouchMove, { passive: false });
    questionContainer.addEventListener('touchend', handleTouchEnd, { passive: false });
    questionContainer.addEventListener('wheel', handleWheel, { passive: false });
  
    return () => {
      questionContainer.removeEventListener('touchstart', handleTouchStart);
      questionContainer.removeEventListener('touchmove', handleTouchMove);
      questionContainer.removeEventListener('touchend', handleTouchEnd);
      questionContainer.removeEventListener('wheel', handleWheel);
    };
  }, [questions.length]);
  
  // Handle sharing the question
  const handleSendShare = async () => {
    const firestore = getFirestore();
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      const receiverUser = await getUserByEmail(shareEmail);
      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }

      const receiverUID = receiverUser.uid;
      const currentQuestion = questions[currentIndex];

      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: currentUser.uid,
        fileType: 'question',
        message: `${currentUser.email} has shared a question with you: ${currentQuestion.question}`,
        question: currentQuestion.question,
        answer: currentQuestion.answer,
        options: currentQuestion.options || null,
        status: 'pending',
        createdAt: new Date(),
        isRead: false,
      });

      alert('Question shared successfully!');
      setIsShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing question:', error);
      alert('Failed to share the question. Please try again.');
    }
  };

    // Get user by email
    const getUserByEmail = async (email: string) => {
      const firestore = getFirestore();
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', email));
  
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { uid: userDoc.id, ...userDoc.data() };
      }
      return null;
    };

  const handleAddToLibraryClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      const listRef = ref(storage, `library/`);
      const res = await listAll(listRef);

      const userSubjects = res.items
        .filter((item) => item.name.includes(currentUser.uid))
        .map((item) => item.name.split('_').slice(1, -1).join('_'));

      setSubjects(userSubjects);
    } catch (error) {
      console.error("Error fetching subjects from Firebase Storage:", error);
    }

    setIsLibraryModalOpen(true);
  };

  const addToLibrary = async () => {
    if (!selectedSubject) return;
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
  
    try {
      // Fetch the file's existing metadata before updating
      const metadata = await getMetadata(subjectRef);
      
      const url = await getDownloadURL(subjectRef);
      const response = await fetch(url);
      const existingData = await response.json();
  
      const newQuestion: QuestionType = {
        question: questions[currentIndex].question,
        answer: questions[currentIndex].answer,
      };
  
      if (questions[currentIndex].options) {
        newQuestion.options = questions[currentIndex].options;
      }
  
      const updatedData = [...existingData, newQuestion];
  
      // Update the file with new data
      await uploadString(subjectRef, JSON.stringify(updatedData), 'raw', {
        contentType: 'application/json',
      });
  
      // After the file upload, update its metadata with the same metadata as before
      const customMetadata = {
        customMetadata: {
          ...metadata.customMetadata, // Retain existing metadata
          UserID: currentUser.uid,
          ShareWithPublic: metadata.customMetadata?.ShareWithPublic || 'No',
          DocumentType: 'Subject',
        },
      };
  
      // Update the file's metadata with the preserved and new custom metadata
      await updateMetadata(subjectRef, customMetadata);
  
      alert("Question added to the library successfully!");
    } catch (error) {
      console.error("Error adding question to library:", error);
      alert("Failed to add question to the library.");
    }
  
    setIsLibraryModalOpen(false);
  };
  
  const handleOptionClick = (optionKey: string) => {
    if (isTouching) return; // Prevent option click during touch interaction
    setSelectedOption(optionKey);
    setShowSubmitButton(true);
    setResultMessage(null);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      const isCorrect = selectedOption === questions[currentIndex].answer;
      setResultMessage(isCorrect ? 'Correct!' : 'Incorrect');

      // Update streaks if correct, or reset if incorrect
      if (isCorrect) {
        setStreaks((prev) => prev + 1);
      } else {
        setStreaks(0);
      }

      setShowSubmitButton(false);
    }
  };

  const handleShowAnswerClick = () => {
    if (isTouching) return; // Prevent hide/show answer during touch interaction
    setShowAnswer(!showAnswer);
  };

  useEffect(() => {
    setResultMessage(null);
    setSelectedOption(null);
    setShowSubmitButton(false);
  }, [currentIndex]);

  // Function to shuffle the questions in a temporary manner
  const handleShuffleClick = () => {
    if (!isShuffled) {
      const shuffled = [...questions].sort(() => 0.5 - Math.random());
      setShuffledQuestions(shuffled);
      setIsShuffled(true);
    } else {
      setIsShuffled(false); // Reset to original order when clicked again
    }
  };

  const handleStarredClick = () => {
    if (!isStarred) {
      const starred = questions.filter((question) => question.starred)
      setStarredQuestions(starred);
      setIsStarred(true);
    } else {
      setIsStarred(false); // Reset to original order when clicked again
    }
  };

  const handleStarClick = () => {
    const updatedQuestions = questions.map((question, index) =>
      index === currentIndex ? { ...question, starred: !question.starred } : question
    );
    setQuestions(updatedQuestions);
    setIsStarredQuestion((prev) => !prev); // Toggle the starred state for the current question
  };

   // Filter questions based on whether they are starred
   const filteredQuestions = isStarred ? starredQuestions: questions;
   // Set the displayed questions based on whether shuffle mode is active
   const displayedQuestions = isShuffled ? shuffledQuestions : filteredQuestions;

  // Reset currentIndex when filteredQuestions changes
  useEffect(() => {
    if (displayedQuestions.length === 0) {
      setCurrentIndex(0);
    } else if (currentIndex >= displayedQuestions.length) {
      setCurrentIndex(0);
    }
  }, [displayedQuestions, currentIndex]);
  
  useEffect(() => {
    // Update isStarredQuestion when currentIndex changes
    if (displayedQuestions.length > 0 && currentIndex < displayedQuestions.length) {
      const isStarred = displayedQuestions[currentIndex].starred || false;
      setIsStarredQuestion(isStarred);
    }
  }, [currentIndex, displayedQuestions]);

  return ( 
      <div  style={{ width: '100%', height:'calc(100vh - 100px)'}} >

        {questions.length === 0 && (
          <p style={{ color: 'white' , textAlign: 'left', fontSize: '14px'  }}>
             No question available. Generate Questions with the circle button on the left side first. 
             Green circle indicates questions available; Red circle indicates not avaialble.
          </p>
        )}

        {isStarred && starredQuestions.length === 0 && (
          <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
            You have no starred questions.
          </p>
        )}

      <div
        className="question-container"
            ref={questionContainerRef}
            onMouseDown={(e) => {
              e.preventDefault();
              const startY = e.clientY;

              const onMouseMove = (moveEvent: MouseEvent) => {
                moveEvent.preventDefault();
                const moveY = moveEvent.clientY;

                if (startY - moveY > 50) {
                  setShowAnswer(false);
                  setCurrentIndex((prevIndex) => (prevIndex + 1) % displayedQuestions.length);
                  document.onmousemove = null;
                } else if (moveY - startY > 50) {
                  setShowAnswer(false);
                  setCurrentIndex((prevIndex) => (prevIndex - 1 + displayedQuestions.length) % displayedQuestions.length);
                  document.onmousemove = null;
                }
              };

              const onMouseUp = () => {
                document.onmousemove = null;
              };

              document.onmousemove = onMouseMove;
              document.onmouseup = onMouseUp;
            }}
          >
            {displayedQuestions.length > 0 && displayedQuestions[currentIndex] && (
              <>
                <div className="qna-content">
                  <p style={{ marginBottom: '40px' }}>{displayedQuestions[currentIndex].question}</p>

                  {displayedQuestions[currentIndex].options && (
                    <ul>
                      {Object.entries(displayedQuestions[currentIndex].options!).map(([key, value]) => (
                        <li
                          key={key}
                          className={`option-item ${selectedOption === key ? 'glowing' : ''}`}
                          onClick={() => handleOptionClick(key)}
                        >
                          <strong>{key}:</strong> {value}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="additional-content">
                  {resultMessage && (
                    <p className={`${resultMessage === 'Correct!' ? 'correct' : 'incorrect'}`}>
                      {resultMessage}
                    </p>
                  )}

                  {showAnswer && (
                    <p className="correct-answer">
                      <strong>Correct Answer:</strong>
                      <span style={{ marginLeft: '10px' }}>{displayedQuestions[currentIndex].answer}</span>
                    </p>
                  )}
                </div>

                <div className="navigation-buttons">
                  <button
                    title="Previous Question"
                    className="nav-qna-button"
                    onClick={() => {
                      setShowAnswer(false);
                      setCurrentIndex((prevIndex) => (prevIndex - 1 + displayedQuestions.length) % displayedQuestions.length);
                    }}
                  >
                    <FaArrowLeft size={24} />
                  </button>

                  <button className="show-answer-button" onClick={handleShowAnswerClick}>
                    {showAnswer ? 'Hide Answer' : 'Show Answer'}
                  </button>

                  <button
                    title="Next Question"
                    className="nav-qna-button"
                    onClick={() => {
                      setShowAnswer(false);
                      setCurrentIndex((prevIndex) => (prevIndex + 1) % displayedQuestions.length);
                    }}
                  >
                    <FaArrowRight size={24} />
                  </button>

                  {!isStarred && (
                    <button
                    title="Star Question"
                    className="nav-qna-button"
                    onClick={handleStarClick}
                    >
                    <FaStar size={24} color={isStarredQuestion ? 'gold' : 'white'} />
                    </button>
                  )}

                </div>
              </>
            )}

            {questions.length > 0 && (
              <div className="question-container-icons">
                <button
                  title="Starred Questions"
                  className={`star-icon ${isStarred ? 'active' : ''}`}
                  onClick={handleStarredClick}
                >
                  <FaStar size={28} />
                </button>

                <button title="Streaks" className="streak-icon">
                  <FaFire size={28} />
                  {streaks > 0 && <span className="streak-badge">{streaks}</span>}
                </button>

                <button
                  title="Shuffle Questions"
                  className={`shuffle-icon ${isShuffled ? 'shuffled' : ''}`}
                  onClick={handleShuffleClick}
                >
                  <FaRandom size={28} />
                </button>

                {!resultMessage && showSubmitButton && (
                  <button className="submit-icon" title="Submit" onClick={handleSubmit}>
                    <FaCheck size={28} />
                  </button>
                )}
              </div>
            )}
          </div>

      {isShareModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content notes-share-modal">
                <h3 className="modal-title">Share Question</h3>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">To:</label>
                  <input
                    type="email"
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                    placeholder="Enter recipient's email"
                    className="notes-share-input"
                  />
                </div>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">Message:</label>
                  <textarea
                    value={shareMessage}
                    onChange={(e) => setShareMessage(e.target.value)}
                    placeholder="Enter your message"
                    className="notes-share-textarea"
                  />
                </div>
                <div className="modal-actions notes-share-actions">
                  <button className="cancel-button" onClick={() => setIsShareModalOpen(false)}>
                    Cancel
                  </button>
                  <button className="notes-share-send-button" onClick={handleSendShare}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}

        {isLibraryModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content stylish-modal">
              <h3 className="modal-title">Select Subject to Add Question</h3>
              
              <select
                value={selectedSubject || ''}
                onChange={(e) => setSelectedSubject(e.target.value)}
                className="stylish-select"
              >
                <option value="" disabled>Select a subject</option>
                {subjects.sort().map((subject, index) => (
                  <option key={index} value={subject}>{subject}</option>
                ))}
              </select>

              <div className="modal-actions">
                <button className="cancel-button stylish-button" onClick={() => setIsLibraryModalOpen(false)}>
                  Cancel
                </button>
                <button className="add-button stylish-button" onClick={addToLibrary}>
                  Add to Library
                </button>
              </div>
            </div>
          </div>
        )}

      </div>

  );
};

// Function to style the streak badge based on streak count
const streakBadgeStyle = (streaks: number) => ({
  display: 'inline-block',
  backgroundColor: streaks > 0 ? 'red' : 'transparent',
  color: 'white',
  padding: '2px 8px',
  borderRadius: '50%',
  marginLeft: '5px',
});

export default Qna;
