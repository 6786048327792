import React, { useState, useEffect,useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FaUserPlus, FaUserMinus, FaUser, FaPaperPlane, FaPaintBrush,FaVolumeUp, FaClipboardCheck, FaClipboard} from 'react-icons/fa'; // Import icons
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import OpenAI from "openai";
import { EventEmitter } from 'events';
import { getDoc,doc, updateDoc, arrayUnion, getFirestore} from 'firebase/firestore';
import ProfessionalCreationWindow from './ProfessionalCreationWindow';
import { handleRequiresAction } from './ProfessionalCreationWindow';
//import CaddMain from './caddDesigner/caddMain'; 
//import EngineerMain from './bridgeEngineer/engineerMain'; 
import { formatContent } from './FormatContent';
import { getAuth } from "firebase/auth";

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })

  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  

interface Assistant {
    name: string;
    professional:string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

type AssistantTool =
  | { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
  | { type: 'file_search' }
  | { type: 'function'; function: FunctionDefinition };

  type FunctionParameters = {
    type: "object";
    properties: Record<string, { type: string; description: string; enum?: string[] }>;
    required: string[];
  };

  interface FunctionDefinition {
    name: string;
    description: string;
    parameters: FunctionParameters;
  }

  interface Event {
    event: string;
    data: any; // Adjust 'any' to a more specific type if you know the structure of 'data'
  }
  
  interface ToolCall {
    id: string;
    function: {
      name: string;
      parameters?: {
        type: string; // Adjust the type as needed
        properties: {
          location: {
            type: string;
            description: string;
          };
          // Add other parameters as needed
        };
        required?: string[];
      };
    };
  }
  
  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }
  
  class EventHandler extends EventEmitter {
    client: OpenAI;
    setAssistants: React.Dispatch<React.SetStateAction<Assistant[]>>;
    currentAssistantIndex: number;

    constructor(client: OpenAI, setAssistants: React.Dispatch<React.SetStateAction<Assistant[]>>, currentAssistantIndex: number) {
        super();
        this.client = client;
        this.setAssistants = setAssistants;
        this.currentAssistantIndex = currentAssistantIndex;
    }

    async onEvent(event: Event) {
        try {
            // Handle events that require action
            if (event.event === "thread.run.requires_action") {
                await handleRequiresAction(
                    event.data as RequiresActionData,
                    event.data.id,
                    event.data.thread_id,
                    this.submitToolOutputs.bind(this),
                    this.setAssistants,
                    this.currentAssistantIndex
                );
            }
        } catch (error) {
            console.error("Error handling event:", error);
        }
    }

    async submitToolOutputs(toolOutputs: any[], runId: string, threadId: string) {
        try {
            const stream = this.client.beta.threads.runs.submitToolOutputsStream(
                threadId,
                runId,
                { tool_outputs: toolOutputs },
            );
            for await (const event of stream) {
                this.emit("event", event);
            }
        } catch (error) {
            console.error("Error submitting tool outputs:", error);
        }
    }
}
const db = getFirestore();

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('');
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [currentAssistantIndex, setCurrentAssistantIndex] = useState<number | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [showAssistantWindow, setShowAssistantWindow] = useState(false); // To toggle assistant creation window
  const [assistantName, setAssistantName] = useState(''); // Assistant name
  const [assistantInstructions, setAssistantInstructions] = useState(''); // Assistant instructions
  const [assistantTool] = useState('file_search');
  const [showUpdateAssistantWindow, setShowUpdateAssistantWindow] = useState(false);
  const [isRemoveAssistantOpen, setIsRemoveAssistantOpen] = useState(false); // New state for remove assistant window
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]); // Store selected assistants for removal
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen mode
  const [copiedKey, setCopiedKey] = useState<number | null>(null); 
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]); 
  const [loading, setLoading] = useState(false);
  const [showAddAssistantOptionWindow, setShowAddAssistantOptionWindow] = useState(false); 
  const [selectedProfessional, setSelectedProfessional] = useState(''); 
  const [showProfessionalCreationWindow, setShowProfessionalCreationWindow] = useState(false); 
  const [showProfessionalSelectionWindow, setShowProfessionalSelectionWindow] = useState(false); 
  const [plan, setPlan] = useState<string | null>(null); 
  const [isCanvasVisible, setIsCanvasVisible] = useState(false); 
  const selectedAssistant = currentAssistantIndex !== null ? assistants[currentAssistantIndex] : null;
  const isProfessionalAssistant = selectedAssistant && 
      (selectedAssistant.name === 'CADD Designer' || selectedAssistant.name === 'Bridge Engineer');
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [firstName, setFirstName] = useState<string | null>(null); 
  const [copiedResponseIndex, setCopiedResponseIndex] = useState<number | null>(null); // Track copied response index
  const [isAssistantThinking, setIsAssistantThinking] = useState(false); 
  const [audioInstance, setAudioInstance] = useState<SpeechSynthesisUtterance | null>(null); 
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [userHasTyped, setUserHasTyped] = useState<boolean>(false);
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
  const resizingRef = useRef(false);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const [canvasWidth, setCanvasWidth] = useState(80);

  // Reference for the chat messages container
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    // Automatically scroll to the bottom when chat updates
    scrollToBottom();
  }, [assistants, currentAssistantIndex]);



  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;

        setFirstName(userData?.firstName || null); // Set firstName for personalized responses

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);

  const fetchAssistantsFromFirestore = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.assistants) {
          const fetchedAssistants = userData.assistants.map((assistant: any) => ({
            name: assistant.name,
            id: assistant.id,
            chat: assistant.lastChatMessages || [], // Load last chat messages
            virtualId: assistant.name.charAt(0).toUpperCase(),
            instructions: assistant.instructions,
            tool: assistant.tool,
            model: assistant.model,
            vectorStore: assistant.vectorStoreId,
            threadID: assistant.threadID,
          }));

          setAssistants(fetchedAssistants);
          console.log('Fetched assistants:', fetchedAssistants);
        }

        if (userData.currentAssistantIndex !== undefined) {
          setCurrentAssistantIndex(userData.currentAssistantIndex);
          console.log('Fetched currentAssistantIndex:', userData.currentAssistantIndex);
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching assistants:", error);
    }
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
        fetchAssistantsFromFirestore(user.uid); // Fetch assistants when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan,fetchAssistantsFromFirestore]);


  useEffect(() => {
    document.body.style.overflow = 'hidden';
  
    // Fetch assistants and currentAssistantIndex from Firestore when the component loads
    const fetchAssistantsFromFirestore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
  
          // Fetch assistants
          if (userData.assistants) {
            const fetchedAssistants = userData.assistants.map((assistant: any) => ({
              name: assistant.name,
              id: assistant.id,
              chat: assistant.lastChatMessages || [], // Fetch the last set of chat messages
              virtualId: assistant.name.charAt(0).toUpperCase(),
              instructions: assistant.instructions,
              tool: assistant.tool,
              model: assistant.model,
              vectorStore: assistant.vectorStoreId,
              threadID: assistant.threadID,
            }));
  
            setAssistants(fetchedAssistants);
            console.log('Fetched assistants:', fetchedAssistants);
          }
  
          // Fetch currentAssistantIndex
          if (userData.currentAssistantIndex !== undefined) {
            setCurrentAssistantIndex(userData.currentAssistantIndex);
            console.log('Fetched currentAssistantIndex:', userData.currentAssistantIndex);
          } else {
            console.log('No currentAssistantIndex found.');
          }
        } else {
          console.error('No such document!');
        }
      }
    };
  
    fetchAssistantsFromFirestore();
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [db]);
  
  

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const handleAddAssistant = () => {
    setShowAddAssistantOptionWindow(true); // Open add assistant options window
  };

  const handleSelectProfessional = (professional: string) => {
    setSelectedProfessional(professional); // Set selected professional
  };

  const handleProfessionalAssistantAdd = () => {
    setAssistantName(selectedProfessional); // Set the professional assistant's name
    setShowProfessionalCreationWindow(true); // Open professional creation window
  };

  const closeAddAssistantOptionWindow = () => {
    setShowAddAssistantOptionWindow(false); // Close the add assistant options window
  };

  const closeProfessionalSelectionWindow = () => {
    setShowProfessionalSelectionWindow(false); // Close professional creation window
  };

  const newChat = async () => {
    setUserHasInteracted(false);
    if (currentAssistantIndex === null) {
      alert("No assistant selected to start a new chat.");
      return;
    }
  
    const assistant = assistants[currentAssistantIndex];
  
    try {
      // Reset the chat in the state
      const updatedAssistants = assistants.map((asst, index) => {
        if (index === currentAssistantIndex) {
          return { ...asst, chat: [] };
        }
        return asst;
      });
  
      setAssistants(updatedAssistants);
  
      // Clear lastChatMessages in Firestore for the selected assistant
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const assistantsData = userData.assistants;
  
          const updatedAssistantsData = assistantsData.map((asst: any) => {
            if (asst.id === assistant.id) {
              return { ...asst, lastChatMessages: [] }; // Clear chat messages in Firestore
            }
            return asst;
          });
  
          await updateDoc(userDocRef, { assistants: updatedAssistantsData });
          console.log("Chat messages cleared in Firestore for assistant ID:", assistant.id);
        }
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
      alert("Failed to clear the chat. Please try again.");
    }
  };
  
  const handleCreateAssistant = async () => {
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants.length + 1}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Creating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      // Call OpenAI API to create the assistant
      const response = await openai.beta.assistants.create({
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });


      console.log('Assistant created:', response);
  
      let vector_store = '';  
      if (tools[0].type === 'file_search') {
        const vectorStore = await openai.beta.vectorStores.create({
          name: safeAssistantName + '_vectorStore',
        });
  
        console.log('Vector store created:', vectorStore);
  
        await openai.beta.assistants.update(response.id, {
          tool_resources: { file_search: { vector_store_ids: [vectorStore.id] } },
        });
  
        vector_store = vectorStore.id;
      }
  
      // No longer saving virtualId to Firestore, we calculate it from the first letter of the name
      const virtualId = safeAssistantName.charAt(0).toUpperCase();

        // Create a new thread
        const threadResponse = await openai.beta.threads.create();
        // threadResponse will contain the ID of the created thread
        const threadID = threadResponse.id;

      // Add the new assistant to the state
      const newAssistant: Assistant = {
        name: safeAssistantName,
        id: response.id, // Use numeric value for id
        chat: [],
        virtualId, // Assign virtualId based on the first letter of the name
        tool:'file_search',
        model:'gpt-4o-mini',
        professional:selectedProfessional,
        vectorStore:vector_store,
        threadID: threadID, 
      };
  
      // Save the assistant information to Firestore under the current user profile
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
  
        // Create the assistant information JSON object
        const assistantData = {
          name: safeAssistantName,
          id: newAssistant.id,
          instructions: assistantInstructions,
          model: 'gpt-4o-mini',
          vectorStoreId: vector_store,
          tool: tools[0].type,
          threadID: threadID, 
        };
  
        // Add the new assistant data to the "assistants" array in Firestore
        await updateDoc(userDocRef, {
          assistants: arrayUnion(assistantData),
        });
  
        console.log('Assistant information saved to Firestore.');
      } else {
        console.error('No authenticated user found.');
      }
  
      setAssistants([...assistants, newAssistant]);
      setShowAssistantWindow(false); // Close the assistant creation window
      setAssistantName(''); // Reset input fields
      setAssistantInstructions('');
  
      alert('Assistant created and saved successfully.');
    } catch (error) {
      console.error('Failed to create assistant:', error);
      alert('Failed to create the assistant. Please try again.');
    }
  };
  
  const closeAssistantWindow = () => {
    setShowAssistantWindow(false); // Close the window without creating an assistant
  };

  const handleUpdateAssistant = async () => {
    if (currentAssistantIndex === null) {
      alert("No assistant selected for updating.");
      return;
    }
  
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants[currentAssistantIndex]?.name}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Updating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      const currentAssistantId = assistants[currentAssistantIndex]?.id.toString(); // Convert number to string
  
      // Call OpenAI API to update the assistant
      const updatedAssistant = await openai.beta.assistants.update(currentAssistantId, {
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: 'gpt-4o-mini', // Specify the model (adjust as per requirements)
        tools,
      });
  
      console.log('Assistant updated:', updatedAssistant);
  
      // Calculate the new virtualId based on the updated name
      const newVirtualId = safeAssistantName.charAt(0).toUpperCase();
  
      // Save the updated assistant information to Firestore
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        let updatedAssistants = [];
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const existingAssistants = userData.assistants || [];
  
          // Check if an assistant with the same id already exists
          const assistantExists = existingAssistants.some((assistant: any) => assistant.id === currentAssistantId);
  
          if (assistantExists) {
            // Replace the existing assistant's information
            updatedAssistants = existingAssistants.map((assistant: any) =>
              assistant.id === currentAssistantId
                ? {
                    ...assistant,
                    name: safeAssistantName,
                    instructions: assistantInstructions,
                    model: 'gpt-4o',
                    tool: tools[0].type,
                  }
                : assistant
            );
          } else {
            // Add the new assistant data to the list
            updatedAssistants = [...existingAssistants, {
              name: safeAssistantName,
              id: currentAssistantId,
              instructions: assistantInstructions,
              model: 'gpt-4o',
              tool: tools[0].type,
            }];
          }
        }
  
        // Update the assistants array in Firestore
        await updateDoc(userDocRef, {
          assistants: updatedAssistants,
        });
  
        console.log('Assistant information updated in Firestore.');
  
        // Update the assistant in the state
        setAssistants(prevAssistants =>
          prevAssistants.map((assistant, index) =>
            index === currentAssistantIndex
              ? { ...assistant, name: safeAssistantName, instructions: assistantInstructions, virtualId: newVirtualId }
              : assistant
          )
        );
  
        setShowUpdateAssistantWindow(false); // Close the update window
        setAssistantName(''); // Reset input fields
        setAssistantInstructions('');
  
        alert('Assistant updated successfully.');
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Failed to update assistant:', error);
      alert('Failed to update the assistant. Please try again.');
    }
  };

// Handle the logic to remove selected assistants
const handleRemoveSelectedAssistants = async () => {
    if (selectedAssistants.length === 0) return;
  
    // Show a warning message before proceeding with removal
    const confirmation = window.confirm(
      `Are you sure you want to remove the selected assistants? This action cannot be undone.`
    );
  
    if (!confirmation) {
      // If the user cancels, exit the function
      console.log('User canceled the removal.');
      return;
    }
  
    try {
      for (const assistantId of selectedAssistants) {
        const assistant = assistants.find(asst => asst.id === assistantId);
        if (!assistant) continue;
  
        // Delete assistant from OpenAI
        await openai.beta.assistants.del(assistantId);
        console.log(`Deleted assistant ${assistant.name} from OpenAI`);
  
        // If the assistant has a file search tool, delete associated vector store files
        if (assistant.tool === 'file_search') {
          const deletedVectorStore = await openai.beta.vectorStores.del(assistant.vectorStore);
          console.log(`Deleted vector store for assistant ${assistant.name}`, deletedVectorStore);
        }
  
        // Remove assistant from Firestore
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const updatedAssistants = userData.assistants.filter((asst: any) => asst.id !== assistantId);
            await updateDoc(userDocRef, { assistants: updatedAssistants });
            console.log(`Removed assistant ${assistant.name} from Firestore`);
          }
        }
      }
  
      // Update state to remove assistants from UI
      setAssistants(prevAssistants => prevAssistants.filter(asst => !selectedAssistants.includes(asst.id)));
      setSelectedAssistants([]); // Clear selection
      setIsRemoveAssistantOpen(false); // Close the modal
  
      alert('Selected assistants removed successfully.');
    } catch (error) {
      console.error('Failed to remove assistants:', error);
      alert('Failed to remove assistants. Please try again.');
    }
  };
  
  // Handle toggling assistant selection
  const handleToggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants(prevSelected =>
      prevSelected.includes(assistantId)
        ? prevSelected.filter(id => id !== assistantId) // Unselect assistant
        : [...prevSelected, assistantId] // Select assistant
    );
  };

  const selectAssistant = async (index: number) => {
    setCurrentAssistantIndex(index); // Set the selected assistant index in state
  
    try {
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          // Overwrite the currentAssistantIndex in Firestore
          await updateDoc(userDocRef, { currentAssistantIndex: index });
          console.log('currentAssistantIndex updated in Firestore:', index);
        } else {
          console.error('User document does not exist.');
        }
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error updating currentAssistantIndex in Firestore:', error);
    }
  };
  
  const handleAssistantClick = (index: number) => {
    let clickTimeout: NodeJS.Timeout;
  
    const singleClick = () => {
      selectAssistant(index); // Handle single click by selecting the assistant
    };
  
    const doubleClick = () => {
      selectAssistant(index);
      const selectedAssistant = assistants[index];
      if (selectedAssistant) {
        setAssistantName(selectedAssistant.name);
        setAssistantInstructions(selectedAssistant.instructions || ''); // Correctly set instructions as default text
        setShowUpdateAssistantWindow(true); // Show the update window
      }
    };
  
    return (e: React.MouseEvent) => {
      if (e.detail === 1) {
        clickTimeout = setTimeout(singleClick, 200); // Single click handler after a delay
      } else if (e.detail === 2) {
        clearTimeout(clickTimeout); // Cancel single-click action on double-click
        doubleClick(); // Handle double-click
      }
    };
  };
  
  const stopStreaming = () => {
    setLoading(false); // Stop streaming
  };

  const uploadFileToOpenAI = async (file: File) => {
    const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
    return response.id;
  };

// Function to add a message and multiple images to a thread
  const  addAssistantImageMessage = async (threadId: string, message: string, fileIds: string[]): Promise<any> => {
        console.log('Adding a new message and images to thread: ' + threadId);
  
        // Construct the content array with the message text and image files
        const content = [
          { type: "text", text: message } as const,
          ...fileIds.map(fileId => ({
            type: "image_file",
            image_file: { file_id: fileId }
          } as const))
        ];
  
        // Create the message in the thread
        const response = await openai.beta.threads.messages.create(
          threadId,
          {
            role: "user",
            content: content
          }
        );
  
        return response;
      }
  
  const processAssistantResponse = async (assistant: Assistant, updatedChat: ChatMessage[]) => {
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
  
      const client = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
        dangerouslyAllowBrowser: true,
      });
      const threadId = assistant.threadID;
        // Pass the necessary parameters to the EventHandler
        const eventHandler = new EventHandler(client, setAssistants, currentAssistantIndex!); // Make sure to use the non-null assertion operator if currentAssistantIndex could be null

        eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));

        try {
          // Start the stream
          const stream = await openai.beta.threads.runs.stream(threadId, {
            assistant_id: assistant.id,
          });
  
          let partialMessage = ""; // To build the message incrementally
          const newUpdatedChat = updatedChat;

          let lastMessageIndex = -1; // Keep track of the last message index
          
          // Listen for events from the stream
          for await (const event of stream) {
            eventHandler.emit("event", event); // Emit each event to your EventHandler instance
            //console.log('event')
            // Type assertion to access the 'delta' property
            if ((event as any).data.delta?.content?.length > 0) {
              const chunk = (event as any).data.delta.content[0].text.value;
              partialMessage += chunk; // Add the chunk to the partial message
  
              // Find the last assistant message if not already located
              if (lastMessageIndex === -1) {
                lastMessageIndex = newUpdatedChat.findIndex(
                  (msg) => msg.from === 'assistant' && !msg.message.trim()
                );
              }

              if (lastMessageIndex !== -1) {
                newUpdatedChat[lastMessageIndex].message = partialMessage;

              } else {
                newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
                lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
              }
  
              // Update the chat state with the new partial message
              setAssistants((prevAssistants) => {
                const newAssistants = [...prevAssistants];
                newAssistants[currentAssistantIndex!] = {
                  ...newAssistants[currentAssistantIndex!],
                  chat: newUpdatedChat,
                };
                return newAssistants;
              });
            }
          }
  
          // Once the streaming is complete, save the final message to Firestore
          await saveChatToFirestore(assistant.id, updatedChat);
          setLoading(false); // Set loading to false after completion
        } catch (error) {
          console.error("Error handling stream:", error);
        }
      
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };
  
  
// Helper function to save chat to Firestore (limit to 10 messages)
const saveChatToFirestore = async (assistantId: string, chat: ChatMessage[]) => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const assistantsData = userData.assistants;
  
        // Limit the chat to the last 10 messages
        const limitedChat = chat.slice(-10);
  
        // Update the specific assistant's lastChatMessages with a max of 10 messages
        const updatedAssistants = assistantsData.map((asst: any) => {
          if (asst.id === assistantId) {
            return { ...asst, lastChatMessages: limitedChat };
          }
          return asst;
        });
  
        await updateDoc(userDocRef, { assistants: updatedAssistants });
        console.log('Last 10 chat messages updated in Firestore for assistant ID:', assistantId);
      }
    }
  };
  
 /*
  async function cancelRun(threadId: string, runId: string) {
    try {
      // Call the OpenAI API to cancel the run
      const response = await openai.beta.threads.runs.cancel(threadId, runId);
  
      // If the cancel operation is successful, log the response
      console.log('Run cancelled successfully:', response);
    } catch (error) {
      // Handle errors during the cancel operation
      console.error('Error cancelling run:', error);
      throw new Error('Failed to cancel the run');
    }
  }
  */

  const handleSendMessage = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('User is not authenticated');
      return;
    }
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
  
    try {
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.warn("User document does not exist.");
        return;
      }
  
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus || 'Starter';
      const promptCount = userData?.dailyPromptCount || 0;
      const fileUploadCount = userData?.dailyFileUploadCount || 0;
  
      // Define daily limits for prompts and file uploads based on the plan
      const dailyPromptLimits: Record<string, number> = {
        Starter: 20,
        Premium: 100,
        Chat: 300,
      };
  
      const dailyFileUploadLimits: Record<string, number> = {
        Starter: 3,
        Premium: 10,
        Chat: 30,
      };
  
      const dailyPromptLimit = dailyPromptLimits[subscriptionStatus];
      const dailyFileUploadLimit = dailyFileUploadLimits[subscriptionStatus];
  
      // Check if the user has exceeded the daily prompt limit
      if (promptCount >= dailyPromptLimit) {
        alert(`You have reached your daily prompt limit of ${dailyPromptLimit} for the ${subscriptionStatus} plan.`);
        return;
      }
  
      // Check if the user has exceeded the daily file upload limit
      if (uploadedFiles.length > 0 && fileUploadCount + uploadedFiles.length > dailyFileUploadLimit) {
        alert(`You can upload up to ${dailyFileUploadLimit} files per day for the ${subscriptionStatus} plan.`);
        return;
      }
  
      setIsAssistantThinking(true);
  
      if (newMessage.trim() === '' && uploadedFiles.length === 0) return;
  
      const assistant = assistants[currentAssistantIndex!];
      let updatedChat: ChatMessage[] = [...assistant.chat];
  
      let fileQuestion = newMessage;
  
      const filePromises = uploadedFiles.map(async (file) => {
        const fileUrl = URL.createObjectURL(file);
        console.log("Uploaded file for GPT-4 Vision:", file);
  
        const matchingPreviewUrl = imagePreviewUrls.find((url) => url.includes(file.name));
  
        if (!matchingPreviewUrl) {
          console.error(`No preview URL found for file: ${file.name}`);
        }
  
        fileQuestion = `<img src="${matchingPreviewUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;
  
        const uploadedFileId = await uploadFileToOpenAI(file);
        return { fileUrl, fileId: uploadedFileId };
      });
  
      if (uploadedFiles.length > 0) {
        const fileData = await Promise.all(filePromises);
        const fileIds = fileData.map((file) => file.fileId);
  
        if (fileQuestion.trim() !== '') {
          updatedChat.push({ message: fileQuestion, from: 'user', type: 'text' });
        }
  
        await saveChatToFirestore(assistant.id, updatedChat);
  
        setAssistants((prevAssistants) => {
          const updatedAssistants = [...prevAssistants];
          updatedAssistants[currentAssistantIndex!] = {
            ...updatedAssistants[currentAssistantIndex!],
            chat: updatedChat,
          };
          return updatedAssistants;
        });
  
        setLoading(true);
  
        const messageResponse = await addAssistantImageMessage(assistant.threadID, fileQuestion, fileIds);
        console.log("Added a message to the thread", messageResponse);
  
        await processAssistantResponse(assistant, updatedChat);
  
        // Increment and update the daily file upload count in Firestore
        const newFileUploadCount = fileUploadCount + uploadedFiles.length;
        await updateDoc(userDocRef, { dailyFileUploadCount: newFileUploadCount });
      } else {
        if (newMessage.trim() !== '') {
          updatedChat.push({ message: newMessage, from: 'user', type: 'text' });
        }
  
        await saveChatToFirestore(assistant.id, updatedChat);
  
        setAssistants((prevAssistants) => {
          const updatedAssistants = [...prevAssistants];
          updatedAssistants[currentAssistantIndex!] = {
            ...updatedAssistants[currentAssistantIndex!],
            chat: updatedChat,
          };
          return updatedAssistants;
        });
  
        setLoading(true);
  
        const content: any[] = [];
  
        if (newMessage.trim() !== '') {
          content.push({
            type: 'text',
            text: newMessage,
          });
        }
  
        if (!assistant.threadID) {
          console.error('No thread ID for this assistant.');
          return;
        }
  
        const threadId = assistant.threadID;
  
        await openai.beta.threads.messages.create(threadId, {
          role: 'user',
          content: content,
        });
  
        await processAssistantResponse(assistant, updatedChat);
      }
  
      // Increment and update the daily prompt count in Firestore
      const newPromptCount = promptCount + 1;
      await updateDoc(userDocRef, { dailyPromptCount: newPromptCount });
  
      setIsAssistantThinking(false);
      setNewMessage('');
      setUserHasTyped(false);
      setUploadedFiles([]);
      setImagePreviewUrls([]);
    } catch (error) {
      console.error('Error handling message:', error);
      alert('An error occurred while sending the message. Please try again.');
      setLoading(false);
      setUserHasTyped(false);
      setIsAssistantThinking(false);
    }
  };


  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const auth = getAuth(); // Get Firebase Auth instance
    const user = auth.currentUser; // Get the currently authenticated user
  
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }
  
    if (e.target.files && e.target.files.length > 0) {
      const storage = getStorage(); // Initialize Firebase Storage
      const filesArray = Array.from(e.target.files); // Convert FileList to an array
  
      const uploadPromises = filesArray.map(async (file) => {
        // Include user's UID in the file name
        const fileRef = ref(storage, `chatImages/${user.uid}_${file.name}`); // UID appended to file name
  
        try {
          // Upload the file to Firebase Storage
          const uploadSnapshot = await uploadBytes(fileRef, file);
          console.log(`Uploaded file: ${uploadSnapshot.metadata.name}`);
  
          // Get the download URL for the uploaded file
          const downloadUrl = await getDownloadURL(fileRef);
          console.log(`File available at: ${downloadUrl}`);
  
          return downloadUrl; // Return the download URL
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          return null; // Return null for failed uploads
        }
      });
  
      try {
        // Wait for all files to upload
        const downloadUrls = await Promise.all(uploadPromises);
  
        // Filter out any null entries (failed uploads)
        const validUrls = downloadUrls.filter((url): url is string => url !== null); // Use a type guard to ensure only strings
  
        // Update state with the valid download URLs
        setImagePreviewUrls((prevUrls) => [...prevUrls, ...validUrls]); // Store Firebase URLs for preview
        setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]); // Store the file objects
      } catch (error) {
        console.error("Error handling file uploads:", error);
      }
    }
  };
  

  // Function to read aloud the assistant's response
  const readAloud = (text: string) => {
    if (isPlaying && audioInstance) {
      // Stop playing if already playing
      speechSynthesis.cancel(); // Stop any ongoing speech
      setIsPlaying(false); // Reset playing state
      setAudioInstance(null); // Clear audio instance
      return; // Exit if we are stopping
    }

    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US'; // Set the language as needed
    speech.onstart = () => {
      setIsPlaying(true); // Set playing state
      setAudioInstance(speech); // Store the audio instance
    }; 
    speech.onend = () => {
      setIsPlaying(false); // Reset playing state when done
      setAudioInstance(null); // Clear audio instance
    };

    speechSynthesis.speak(speech);
  };

  const handleCopyResponse = (index: number, response: string) => {
    navigator.clipboard.writeText(response).then(() => {
      setCopiedResponseIndex(index);
      setTimeout(() => setCopiedResponseIndex(null), 2000); // Reset after 2 seconds
    }).catch(err => console.error('Failed to copy:', err));
  };

// Function to toggle full-screen mode
  const toggleFullScreen = () => {
    setIsFullScreen(prevState => !prevState);
  };
  const handleCanvasToggle = () => {
    setIsCanvasVisible(prev => !prev);
  };


  const removeImage = (index: number) => {
    // Remove the image from imagePreviewUrls
    const updatedUrls = [...imagePreviewUrls];
    updatedUrls.splice(index, 1);
    setImagePreviewUrls(updatedUrls);
  
    // Remove the corresponding file from uploadedFiles
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };
  
  useEffect(() => {
    const updateCanvasWidth = () => {
      if (window.innerWidth > 600) {
        setCanvasWidth(80); // Set width to 80% for screens larger than 600px
      } else {
        setCanvasWidth(90); // Set width to 90% for screens smaller than 600px
      }
    };
  
    // Initial setup
    updateCanvasWidth();
  
    // Add resize listener
    window.addEventListener('resize', updateCanvasWidth);
  
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateCanvasWidth);
    };
  }, []);
  
 
  const handleMouseDownOnResizeBar = (e: React.MouseEvent) => {
    e.preventDefault();
    resizingRef.current = true;

    const handleMouseMove = (event: MouseEvent) => {
      if (resizingRef.current && canvasRef.current) {
        const newWidth = (event.clientX / window.innerWidth) * 100;
        setCanvasWidth(Math.min(95, Math.max(5, newWidth))); // Restrict width between 5% and 95%
      }
    };

    const handleMouseUp = () => {
      resizingRef.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleImageClick = (url: string) => {
    setCurrentImage(url);
    setShowPopup(true);
  };
  
  const closePopup = () => {
    setShowPopup(false);
    setCurrentImage(null);
  }

  return (
<div className={`container ${isFullScreen ? 'fullscreen-mode' : ''}`}>
  {!isFullScreen && <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />}
  <div className={`main-content ${isFullScreen ? 'fullscreen-content' : ''}`}>
       {!isFullScreen && 
        <Sidebar
        className="chat-sidebar" 
        onLogout={handleLogout}
        onHomeClick={async () => {
            navigate('/home');
        }}
        />
       }

    <div className={`chat-content-inner ${isFullScreen ? 'fullscreen-chat-container' : ''}`}>
      
      {isCanvasVisible && (
        <>
          <div 
            className="canvas-container"  
            ref={canvasRef}
            style={{ width: `${canvasWidth}%`}}
            >
              {/* Prompt Container 
              {selectedAssistant?.name === "CADD Designer" ? (
                <CaddMain id="my-canvas" />
              ) : selectedAssistant?.name === "Bridge Engineer" ? (
                <EngineerMain id="my-canvas" />
              ) : null}
             */}
          </div>
          <div
            className="chat-resize-bar"
            onMouseDown={handleMouseDownOnResizeBar}
          />
        </>
      )}

      <div
          className={`chat-container ${isFullScreen ? 'fullscreen-chat-container' : ''}`}
          style={{
            width: isCanvasVisible ? '35%' : '100%',
            display: 'flex',
            transition: 'width 0.3s',
            margin: isCanvasVisible ? '0' : '0 auto',
          }}
          onDoubleClick={toggleFullScreen}
        >
       {!isFullScreen && 
            <div className="chat-container-icons">
              <ul className="chat-container-ul">
                 <li className="chat-container-li">
                  <button title='New Chat' onClick={newChat}>
                  <svg width="30" height="30" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" ><path d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z" fill="currentColor" />
                  </svg>
                  </button>
                </li>

                <li className="chat-container-li">
                  <button title='Add Assistant' onClick={handleAddAssistant}>
                    <FaUserPlus size={28} />
                  </button>
                </li>

                <li className="chat-container-li">
                  <button title='Remove Assistant' onClick={() => setIsRemoveAssistantOpen(true)}>
                    <FaUserMinus size={28} />
                  </button>
                </li>

                {assistants.map((assistant, index) => (
                <li className="chat-container-li" key={assistant.id} onClick={handleAssistantClick(index)} onDoubleClick={handleAssistantClick(index)} >
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                    <button
                        style={{
                        position: 'relative',
                        background: 'transparent',
                        border: 'none',
                        color: currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue if selected
                        }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                        onMouseLeave={() => setHoveredIndex(null)}  // Remove hover state on mouse leave
                        title={assistant.name} // Show the assistant's name in a tooltip
                    >
                        <FaUser size={24} style={{ color: currentAssistantIndex === index ? '#007bff' : 'inherit' }} /> {/* Retain original color or blue if selected */}
                        <span
                        style={{
                            position: 'absolute',
                            top: '30%', // Fine-tune the position to merge better
                            left: '90%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '10px', // Make the number smaller to integrate better
                            fontWeight: 'bold',
                            color: hoveredIndex === index || currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue on hover or if selected, original color otherwise
                            textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)', // Add shadow for depth
                            pointerEvents: 'none', // Prevent interaction with the virtualId text
                        }}
                        >
                        {assistant.virtualId}
                        </span>
                    </button>
                    </div>
                </li>
                ))}

                {isProfessionalAssistant && (
                    <li className="chat-container-li">
                        <button
                            title='Canvas'
                            onClick={handleCanvasToggle}
                            style={{ color: isCanvasVisible ? '#007bff' : '#8055f5' }} // Change color based on visibility
                        >
                            <FaPaintBrush size={28} />
                        </button>
                    </li>
                )}

              </ul>
            </div>
          }

            <div className="chat-area">
            {!userHasTyped && !userHasInteracted && (
              <div className="welcome">
                  {currentAssistantIndex !== null && assistants[currentAssistantIndex]?.chat.length === 0 && (
                    <>
                      <h2>This is {assistants[currentAssistantIndex]?.name}.</h2>
                      <h2>How can I help you today?</h2>
                    </>
                  )}
                </div>
              )} 

              {currentAssistantIndex !== null ? (
                <>

                <div className="chat-messages">
                {assistants[currentAssistantIndex]?.chat.map((chat, index) => (

                  <div key={index} className={`chat-message ${chat.from}`}>

                    <strong>
                      {chat.from === 'user' 
                        ? firstName || 'You' 
                        : assistants[currentAssistantIndex]?.name}:
                    </strong>

                    <div 
                        className="assistant-response-container"
                        onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                        onMouseLeave={() => setHoveredIndex(null)}  // Remove hover state on mouse leave
                      >
                      
                      {formatContent(chat.message, copiedKey, setCopiedKey)}

                      {chat.from === 'assistant' && !loading && (
                        <div
                          className="response-buttons"
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                          style={{ display: index === assistants[currentAssistantIndex]?.chat.length - 1 || hoveredIndex === index ? 'block' : 'none' }}
                        >
                          <button
                            onClick={() => readAloud(chat.message)}
                            title="Read Aloud"
                            style={{
                              cursor: 'pointer',
                              marginRight: '5px',
                              color: isPlaying && index === assistants[currentAssistantIndex]?.chat.length - 1 ? 'green' : 'inherit',
                            }}
                          >
                            {isPlaying && index === assistants[currentAssistantIndex]?.chat.length - 1 ? (
                              <FaVolumeUp style={{ color: 'green' }} size={16} />
                            ) : (
                              <FaVolumeUp size={16} />
                            )}
                          </button>
                          <button
                            onClick={() => handleCopyResponse(index, chat.message)}
                            title="Copy Response"
                            style={{ cursor: 'pointer' }}
                          >
                            {copiedResponseIndex === index ? <FaClipboardCheck size={16} /> : <FaClipboard size={16} />}
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                ))}
                {/* Show thinking indicator after the last user message */}
                  {isAssistantThinking && (
                    <div className="thinking-indicator">
                      <div className={`chat-message assistant`}>
                      <strong>
                        {assistants[currentAssistantIndex]?.name}:
                      </strong>
                      </div>
                      <div className="thinking-dot"></div>
                    </div>
                  )}
                  {/* Dummy div to scroll to the bottom */}
                  <div ref={chatEndRef} />
                </div>

                  {/* Prompt Container */}

                  <div className="prompt-container">

                  {imagePreviewUrls.length > 0 && (
                  <div className="image-preview">
                    {imagePreviewUrls.map((url, index) => (
                      <div key={index} style={{ position: "relative", display: "inline-block", marginBottom: "10px" }}>
                        <img 
                          src={url} 
                          alt={`Preview ${index + 1}`} 
                          style={{ width: '50px', marginBottom: '10px' }} 
                          onClick={() => handleImageClick(url)} 
                        />
                        <button
                          onClick={() => removeImage(index)} // Pass the current index
                          style={{
                            position: "absolute",
                            top: "-24px",
                            right: "-5px",
                            backgroundColor: "transparent",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <div className="input-row">
                    <label 
                    title="Upload" 
                    htmlFor="file-upload" 
                    style={{ cursor: 'pointer', alignSelf: 'flex-end' }} 
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    </label>
                    <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />

                    <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                        setUserHasTyped(true);
                        setUserHasInteracted(true);
                        // Always reset the height if the input is empty
                        if (e.target.value === '') {
                        e.target.style.height = '24px';
                        } else {
                        e.target.style.height = 'auto'; // Reset height to calculate new height based on content
                        e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Restrict height to 10 lines (about 300px)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevent default behavior (adding a new line)
                        handleSendMessage(); // Trigger the send message function
                        setNewMessage(''); // Clear the textarea field after sending
                        
                        // Reset the height of the textarea when user input is empty
                        e.currentTarget.style.height = '24px';
                        }
                    }}
                    placeholder="Type a message"
                    className="message-input"
                    rows={2}
                    />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? stopStreaming() : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>

                {/* 
                <div className="model-option-chat">
                    <select
                      value={selectedModel}
                      onChange={(e) => setSelectedModel(e.target.value as 'ChatGPT' | 'DeepSeek')}
                      >
                      <option value="ChatGPT">ChatGPT</option>
                      <option value="DeepSeek">DeepSeek</option>
                    </select>
                  </div>

                */}

                </div>

                </div>

                </>
              ) : (
                <p>Select an assistant to start chatting.</p>
              )
              
              
              }
            </div>

            {/* Add Assistant Options Window */}
            {showAddAssistantOptionWindow && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 className="modal-title">Add Assistant</h3>
                  <div className="buttonContainer">
                    <div style={{ marginBottom: '10px' }}>
                      <button
                        className="save-button"
                        onClick={() => {
                          setShowAddAssistantOptionWindow(false); // Close the options window
                          setShowAssistantWindow(true); // Open assistant creation window
                        }}
                      >
                        Add a Personal Assistant
                      </button>
                    </div>
                    {(plan === 'ProChat') && (
                    <div>
                      <button
                        className="save-button"
                        onClick={() => {
                          setShowAddAssistantOptionWindow(false); // Close the options window
                          setShowProfessionalSelectionWindow(true); // Open professional selection window
                        }}
                      >
                        Add a Professional
                      </button>
                    </div>
                    )}

                  </div>
                  <button className="cancel-button" onClick={closeAddAssistantOptionWindow}>
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {/* Professional Selection Window */}
            {showProfessionalSelectionWindow && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 className="modal-title">Select a Professional Assistant</h3>
                  <ul className="assistant-selection">
                    <li>
                      <button
                        className={`professional-button ${selectedProfessional === 'CADD Designer' ? 'selected' : ''}`}
                        style={{
                          backgroundColor: selectedProfessional === 'CADD Designer' ? 'darkblue' : 'inherit', // Change to dark blue if selected
                          color: selectedProfessional === 'CADD Designer' ? 'white' : 'inherit'
                        }}
                        onClick={() => handleSelectProfessional('CADD Designer')}
                      >
                        CADD Designer
                      </button>
                    </li>
                    <li>
                      <button
                        className={`professional-button ${selectedProfessional === 'Bridge Engineer' ? 'selected' : ''}`}
                        style={{
                          backgroundColor: selectedProfessional === 'Bridge Engineer' ? 'darkblue' : 'inherit', // Change to dark blue if selected
                          color: selectedProfessional === 'Bridge Engineer' ? 'white' : 'inherit'
                        }}
                        onClick={() => handleSelectProfessional('Bridge Engineer')}
                      >
                        Bridge Engineer
                      </button>
                    </li>
                  </ul>
                  <div className="buttonContainer">
                    <button
                      className="save-button"
                      style={{ marginRight: '10px' }} // Add margin to create a gap between the buttons
                      onClick={handleProfessionalAssistantAdd}
                    >
                      OK
                    </button>
                    <button className="cancel-button" onClick={closeProfessionalSelectionWindow}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Assistant Creation Window */}
            {showAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">New Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input" // Use this class to set width to 60%
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions}
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea" // Use this class for consistent styling
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={closeAssistantWindow}>
                    Cancel
                    </button>
                    <button className="save-button" onClick={handleCreateAssistant}>Save</button>
                </div>
                </div>
            </div>    
            )}
            
            {/* Professional Creation Window */}
            {
              showProfessionalCreationWindow && (
                <ProfessionalCreationWindow
                  assistants={assistants}
                  setAssistants={setAssistants}                  
                  selectedProfessional={selectedProfessional}
                  assistantName={assistantName}
                  setAssistantName={setAssistantName}
                  assistantInstructions={assistantInstructions}
                  setAssistantInstructions={setAssistantInstructions}
                  handleClose={() => setShowProfessionalCreationWindow(false)} // Close the window without saving
                />
              )
            }

            {showUpdateAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">Update Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input"
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions} // Ensure this is set correctly
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea"
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={() => {setShowUpdateAssistantWindow(false);setIsFullScreen(prevState => !prevState);}}>
                    Cancel
                    </button>
                    <button className="save-button"   
                      onClick={() => {
                        handleUpdateAssistant();
                        setIsFullScreen((prevState) => !prevState);
                      }}>
                    Save
                    </button>
                </div>
                </div>
            </div>
            )}

            {/* Assistant Removal Modal */}
            {isRemoveAssistantOpen && (
              <div className="modal-overlay">
                <div className="modal-content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <h3 className="modal-title">Remove Assistants</h3>
                  <div className="assistant-selection">
                    <ul className="assistant-remove-list">
                      {assistants.map(assistant => (
                        <li key={assistant.id} className="assistant-item">
                          <input
                            type="checkbox"
                            checked={selectedAssistants.includes(assistant.id)}
                            onChange={() => handleToggleAssistantSelection(assistant.id)}
                            className="assistant-checkbox"
                          />
                          <label className="assistant-checkbox-label">{assistant.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="actions">
                    <button className="cancel-button" onClick={() => setIsRemoveAssistantOpen(false)}>Cancel</button>
                    <button className="remove-button" onClick={handleRemoveSelectedAssistants}>Remove Selected</button>
                  </div>
                </div>
              </div>
            )}


            {/* Pop-up for larger image */}
            {showPopup && currentImage && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={currentImage}
                    alt="Larger Preview"
                    style={{ maxWidth: "90vw", maxHeight: "90vh" }}
                  />
                  <button
                    onClick={closePopup}
                    style={{
                      position: "absolute",
                      top: "-15px", // Move above the image
                      right: "-15px", // Move to the right outside the image
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: add some styling for a better look
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
