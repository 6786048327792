import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore'; 
import OpenAI from "openai";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

// Set the worker source for pdfjs-dist
GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
});

export const getScrollablePdfContainer = (container: HTMLElement): HTMLElement | null => {
    // Traverse child nodes to find a scrollable container
    for (const child of Array.from(container.children)) {
        const element = child as HTMLElement;
        const style = window.getComputedStyle(element);
        
        // Check for overflow properties indicating scrollability
        if (style.overflow === 'auto' || style.overflow === 'scroll') {
            return element;
        }
        
        // Recursively check deeper levels for a scrollable element
        const scrollableChild = getScrollablePdfContainer(element);
        if (scrollableChild) return scrollableChild;
    }
    return null;
};


export const uploadFileToOpenAI = async (file: File) => {
    return await retryRequest(async () => {
      const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
      return response.id;
    });
  };

 export const retryRequest = async (func: () => Promise<any>, retries = 3, defaultDelay = 1000): Promise<any> => {
    try {
      return await func();
    } catch (error: any) {
      if (error?.status === 429 && retries > 0) {
        const retryAfterMatch = error?.response?.data?.error?.message?.match(/after ([\d.]+) seconds/i);
        const retryAfter = retryAfterMatch ? parseFloat(retryAfterMatch[1]) * 1000 : defaultDelay;
        console.log(`Rate limit exceeded, retrying after ${retryAfter / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, retryAfter));
        return retryRequest(func, retries - 1, defaultDelay);
      } else {
        throw error;
      }
    }
  };

export const filterUnsupportedCharacters = (text: string): string => {
    // Replace specific problematic characters with placeholders or remove them
    const filteredText = text
      .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
      .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
      .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
      .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
      .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
      .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
      .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
      .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
      .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
      .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
      .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
      .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
      .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
      .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
      .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
      .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
      .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
      .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
      .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
      .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
      .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
      .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
      .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
      .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
      .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
      .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
      .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
      .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
      .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
      .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
      .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
      .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
      .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
      .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
      .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
      .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
      .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
      .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
      .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
      .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
      .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
      .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
      .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
      .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
      .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
      .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
      .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
      .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
      .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
      .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
      .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
      .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
    return filteredText;
  };
  
  // Helper function to transcribe each chunk using OpenAI Whisper
export const transcribeAudioChunkWithWhisper = async (audioFile: File, openai: OpenAI): Promise<string> => {
    try {
      const transcription = await openai.audio.transcriptions.create({
        file: audioFile,
        model: "whisper-1",
        response_format: "text",
      });
      return String(transcription);
    } catch (error) {
      console.error('Error during transcription:', error);
      return ''; // Return empty string if error occurs
    }
  };
  
  // Functions for motion audio player
export const fetchAudioFromProxy = async (firebaseAudioUrl: string) => {
    try {
      const proxyUrl = `/api/proxy-audio?url=${encodeURIComponent(firebaseAudioUrl)}`;
      const response = await fetch(proxyUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch audio from proxy');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching audio:', error);
      return null;
    }
  };
  
export const transcribeAudioWithOpenAI = async (audioFile: File): Promise<string> => {
    try {
      // Call OpenAI's Whisper API for transcription
      const transcription = await openai.audio.transcriptions.create({
        file: audioFile,
        model: "whisper-1",
        response_format: "text",
      });
  
     // console.log('transcription', transcription);
  
      // Convert the transcription to a string explicitly
      if (transcription && typeof transcription === 'string') {
        return transcription; // Return the transcription as a string
      } else if (transcription && transcription.text) {
        return String(transcription.text); // Safely convert the text to a string
      } else {
        throw new Error("No transcription text found in the response.");
      }
    } catch (error) {
      console.error("Error during transcription:", error);
      return "Error during transcription.";
    }
  };
  
  export const getNewFileNumber = async (): Promise<number> => {
    try {
      const firestore = getFirestore();
      const countersDocRef = doc(firestore, 'admin', 'counters');
      const countersDoc = await getDoc(countersDocRef);
  
      // Get the current lastFileNumber or default to 0 if not present
      const lastFileNumber = countersDoc.exists() ? countersDoc.data()?.lastFileNumber || 0 : 0;
  
      // Increment the file number
      const newFileNumber = lastFileNumber + 1;
  
      // Update the lastFileNumber in Firestore
      await updateDoc(countersDocRef, { lastFileNumber: newFileNumber });
  
      return newFileNumber;
    } catch (error) {
      console.error('Error fetching or updating lastFileNumber:', error);
      throw new Error('Unable to fetch or update the last file number.');
    }
  };

  // Function to extract text from a PDF file
export const extractTextFromPdf = async (pdfUrl: string) => {
  try {
    const loadingTask = getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    let textContent = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const text = await page.getTextContent();
      text.items.forEach((item: any) => {
        textContent += item.str + ' ';
      });
    }

    return textContent;
  } catch (error) {
    console.error('Error extracting text from PDF:', error);
    return '';
  }
};


// Function to extract text with formatting from a PDF file
export const extractTextWithFormatting = async (pdfUrl: string) => {
  try {
    const loadingTask = getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    let formattedText = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();

      let lastY = 0; // Track the Y position of the last text item
      textContent.items.forEach((item: any) => {
        const { transform, str } = item;
        const y = transform[5]; // Y position of the text item

        // Add a newline if the Y position changes significantly (indicating a new line)
        if (Math.abs(y - lastY) > 10) {
          formattedText += '\n';
        }
        formattedText += str + ' ';

        lastY = y; // Update the last Y position
      });

      // Add a paragraph break after each page
      formattedText += '\n\n';
    }

    return formattedText.trim(); // Remove trailing whitespace
  } catch (error) {
    console.error('Error extracting text from PDF:', error);
    return '';
  }
};

