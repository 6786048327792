import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc,getDocs, collection, query, onSnapshot, updateDoc,deleteDoc,QueryDocumentSnapshot,where, setDoc} from 'firebase/firestore';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll, StorageReference, uploadString,updateMetadata,getMetadata } from 'firebase/storage';
import { deleteUser,onAuthStateChanged, User } from 'firebase/auth';
import { FaUser, FaCreditCard, FaCog, FaTrash, FaBell,FaTools,FaSearch, FaSignOutAlt} from 'react-icons/fa'; 
import { MdOutlineListAlt } from 'react-icons/md'; 
import Logo from '../assets/logo.png';
import { useAuth } from '../authContext';
import './Base.css';

interface NavBarProps {
  userImage: string;
  onProfileLoad: (firstName: string, lastName: string, userImage: string, bio: string) => void;
}

interface AdminUser {
  emails: string[];
}

interface Notification {
  id: string;
  receiverId: string;
  senderId: string;
  fileUrl: string;
  fileName: string;
  fileType: string;
  message: string;
  status: string;
  createdAt: any;
  isRead: boolean; 
  question: string;
  answer: string;
  options?: { [key: string]: string }; // Change from array to object
}

interface QuestionType {
  question: string;
  options?: { [key: string]: string };
  answer: string;
}

const NavBar: React.FC<NavBarProps> = ({ userImage, onProfileLoad}) => {
  const navigate = useNavigate();
  const isProfileFetched = useRef<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
  const [userName, setUserName] = useState('');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false); // Added state for subject selection modal
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null); 
  const menuRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const [adminModalOpen, setAdminModalOpen] = useState(false); 
  const [anonymousUserCount, setAnonymousUserCount] = useState<number>(0);
  const [starterUserCount, setStarterUserCount] = useState<number>(0);
  const [premiumUserCount, setPremiumUserCount] = useState<number>(0);
  const [chatUserCount, setChatUserCount] = useState<number>(0);
  const [proChatUserCount, setProChatUserCount] = useState<number>(0);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  
  // Toggle Notification Panel
  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
    if (!showNotifications) {
      markAllNotificationsAsRead();
    }
  };

  // Toggle Navbar Menu
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menuClicked = menuRef.current && menuRef.current.contains(event.target as Node);
      const notificationClicked = notificationRef.current && notificationRef.current.contains(event.target as Node);
      
      // Close both menus if clicking outside
      if (!menuClicked && !notificationClicked) {
        setShowMenu(false);
        setShowNotifications(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, notificationRef]);
  

  const markAllNotificationsAsRead = async () => {
    const db = getFirestore();
    const updatedNotifications = notifications.map((notification) => {
      if (!notification.isRead) {
        updateDoc(doc(db, 'notifications', notification.id), { isRead: true });
      }
      return { ...notification, isRead: true };
    });
    setNotifications(updatedNotifications);
    setUnreadCount(0);
  };
  useEffect(() => {
    const fetchUserProfile = async (user: User) => {
      if (!user || user.isAnonymous || isProfileFetched.current) {
        return;
      }

      try {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const firstName = userData?.firstName || '';
          const lastName = userData?.lastName || '';
          const imageUrl = userData?.imageUrl || userImage;
          const bio = userData?.bio || '';
          const subscriptionStatus = userData?.subscriptionStatus ?? null;
          setUserName(`${firstName} ${lastName}`);
          setSubscriptionStatus(subscriptionStatus);
          onProfileLoad(firstName, lastName, imageUrl, bio);
          isProfileFetched.current = true;
        }

        // Check if the user is an admin
        const adminDocRef = doc(db, 'admin', 'auth');
        const adminDoc = await getDoc(adminDocRef);
        if (adminDoc.exists()) {
          const adminData = adminDoc.data() as AdminUser;
          if (adminData.emails.includes(user.email || '')) {
            setIsAdmin(true);

            // If the user is an admin, update the Firestore document
            await updateDoc(userDocRef, {
              subscriptionStatus: 'ProChat',
            });
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    // Listen to authentication state changes to refetch profile data upon reload
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserProfile(user);
      }
    });

    return () => unsubscribe();
  }, [userImage, onProfileLoad]);


  const openAdminModal = async () => {
    setAdminModalOpen(true);
    try {
        const db = getFirestore();
        
        // Get the count of anonymous users
        const anonymousUsersDocRef = doc(db, 'admin', 'anonymousUsers');
        const anonymousUsersDoc = await getDoc(anonymousUsersDocRef);
        if (anonymousUsersDoc.exists()) {
            const count = anonymousUsersDoc.data()?.counts || 0;
            setAnonymousUserCount(count);
        }

        // Get the counts of starter and premium users
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);

        let starterCount = 0;
        let premiumCount = 0;
        let chatCount = 0;
        let proChatCount = 0;

        usersSnapshot.docs.forEach((userDoc) => {
            const userData = userDoc.data();

            // Count premium users (subscriptionStatus is true)
            if (userData.subscriptionStatus === 'Starter') {
                starterCount++;
            }

            else if (userData.subscriptionStatus === 'Premium') {
                premiumCount++;
            }
            else if (userData.subscriptionStatus === 'Chat') {
              chatCount++;
            }
            else if (userData.subscriptionStatus === 'ProChat') {
              proChatCount++;
            }
        });

        // Set the counts
        setStarterUserCount(starterCount);
        setPremiumUserCount(premiumCount);
        setChatUserCount(chatCount);
        setProChatUserCount(proChatCount);

    } catch (error) {
        console.error('Error fetching user counts:', error);
    }
};


  const cleanAnonymousUserFiles = async () => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
  
    // Use getDocs to fetch multiple documents, not getDoc
    const usersSnapshot = await getDocs(usersCollection);
  
    const promises = usersSnapshot.docs.map(async (docSnapshot: QueryDocumentSnapshot) => {
      const userData = docSnapshot.data();
      const userUID = docSnapshot.id;
  
      if (userData.anonymousUser === true) {
        // Delete user's document
        await deleteDoc(doc(db, 'users', userUID));
  
        // Delete user's files in storage
        const folders = ['uploads', 'Q&A', 'library', 'summaries'];
        const deletionPromises = folders.map(async (folder) => {
          const folderRef = ref(storage, `${folder}/`);
          const res = await listAll(folderRef);
          const userFiles = res.items.filter((item) => item.name.includes(userUID));
  
          await Promise.all(
            userFiles.map(async (item: StorageReference) => {
              const fileRef = ref(storage, item.fullPath);
              await deleteObject(fileRef);
            })
          );
        });
  
        await Promise.all(deletionPromises);
      }
    });
  
    await Promise.all(promises);
    alert('Anonymous user files cleaned successfully!');
  };


  useEffect(() => {
    const fetchNotifications = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const db = getFirestore();
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef);

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedNotifications = snapshot.docs
          .map(doc => {
            const data = doc.data() as Notification;
            const createdAt = data.createdAt?.toDate ? data.createdAt.toDate() : new Date();
            return { ...data, id: doc.id, createdAt };
          })
          .filter((notification: Notification) => notification.receiverId === currentUser.uid);
      
        setNotifications(fetchedNotifications);

        const unreadCount = fetchedNotifications.filter(notification => !notification.isRead).length;
        setUnreadCount(unreadCount);
      });

      return () => unsubscribe();
    };

    fetchNotifications();
  }, []);


  const getNewFileNumber = async (): Promise<number> => {
    try {
      const firestore = getFirestore();
      const countersDocRef = doc(firestore, 'admin', 'counters');
      const countersDoc = await getDoc(countersDocRef);
  
      // Get the current lastFileNumber or default to 0 if not present
      const lastFileNumber = countersDoc.exists() ? countersDoc.data()?.lastFileNumber || 0 : 0;
  
      // Increment the file number
      const newFileNumber = lastFileNumber + 1;
  
      // Update the lastFileNumber in Firestore
      await updateDoc(countersDocRef, { lastFileNumber: newFileNumber });
  
      return newFileNumber;
    } catch (error) {
      console.error('Error fetching or updating lastFileNumber:', error);
      throw new Error('Unable to fetch or update the last file number.');
    }
  };


  const handleAcceptShare = async (
    notificationId: string,
    fileUrl: string,
    fileName: string,
    fileType: string
  ) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const receiverUID = currentUser.uid;
    const firestore = getFirestore();
  
    try {
      // Get metadata for the main file
      const sharedFileRef = ref(storage, fileUrl);
      const metadata = await getMetadata(sharedFileRef);
  
      if (!metadata.customMetadata) {
        alert('Metadata not found for the shared file.');
        return;
      }
  
      const customMetadata = metadata.customMetadata;
      const { size } = metadata; // Include file size
      const {
        QnaUrl = null,
        ThumbnailUrl = null,
        SummaryUrl = null,
        TranscriptUrl = null,
        Tags = null,
        YoutubeTitle = null,
        ShareWithPublic = 'No',
      } = customMetadata;
  
      // Define folder paths based on fileType
      const folderPath =
        fileType === 'Note'
          ? 'uploads'
          : fileType === 'Recording'
          ? 'recordings'
          : fileType === 'YouTube Video'
          ? 'videos'
          : null;
  
      if (!folderPath) {
        alert('Invalid file type.');
        return;
      }
  
      // Generate a unique file name if a duplicate exists
      const listRef = ref(storage, `${folderPath}/`);
      const res = await listAll(listRef);
      const existingFiles = res.items
        .filter((item) => item.name.startsWith(`${receiverUID}_`))
        .map((item) => item.name.replace(`${receiverUID}_`, ''));
  
      let uniqueFileName = fileName;
      let counter = 1;
      while (existingFiles.includes(uniqueFileName)) {
        const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
        const fileExt = fileName.substring(fileName.lastIndexOf('.'));
        uniqueFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
        counter++;
      }
  
      const newFileRefPath = `${folderPath}/${receiverUID}_${uniqueFileName}`;
      const newFileRef = ref(storage, newFileRefPath);
  
      // Copy the main file to the new location
      const fileDownloadUrl = await getDownloadURL(sharedFileRef);
      const response = await fetch(fileDownloadUrl);
      const sharedFileBlob = await response.blob();
      await uploadBytes(newFileRef, sharedFileBlob);
  
      const newFileNumber = await getNewFileNumber();
  
      // Copy associated files and update their URLs in the new metadata
      const renameAssociatedFile = async (url: string | null, folder: string, suffix: string) => {
        if (url) {
          const oldRef = ref(storage, url);
          const newRefPath = `${folder}/${receiverUID}_${uniqueFileName}_${suffix}`;
          const newRef = ref(storage, newRefPath);
  
          const downloadUrl = await getDownloadURL(oldRef);
          const res = await fetch(downloadUrl);
          const blob = await res.blob();
          await uploadBytes(newRef, blob);
  
          // Preserve metadata
          const oldMetadata = await getMetadata(oldRef);
          await updateMetadata(newRef, { customMetadata: oldMetadata.customMetadata });
  
          return await getDownloadURL(newRef);
        }
        return null;
      };
  
      const newThumbnailUrl = await renameAssociatedFile(ThumbnailUrl, 'thumbnails', 'thumbnail.png');
      const newQnaUrl = await renameAssociatedFile(QnaUrl, 'Q&A', 'qna.json');
      const newSummaryUrl =
        fileType === 'Note'
          ? await renameAssociatedFile(SummaryUrl, 'summaries', 'summary.pdf')
          : await renameAssociatedFile(SummaryUrl, 'summaries', 'transcript_summary.pdf');
      const newTranscriptUrl =
        fileType === 'Recording'
          ? await renameAssociatedFile(TranscriptUrl, 'recordingTranscript', 'transcript.pdf')
          : null;
  
      const newCustomMetadata = {
        viewCount: '0',
        UserID: receiverUID,
        NewFileNumber: String(newFileNumber),
        DocumentType: fileType,
        ShareWithPublic: 'No',
        Tags:Tags || '',
        ThumbnailUrl: newThumbnailUrl || '', // Replace null with an empty string
        QnaUrl: newQnaUrl || '', // Replace null with an empty string
        SummaryUrl: newSummaryUrl || '', // Replace null with an empty string
        TranscriptUrl: newTranscriptUrl || '', // Replace null with an empty string
        YoutubeTitle: YoutubeTitle || '',
      };
      
      await updateMetadata(newFileRef, { customMetadata: newCustomMetadata });
      const newUrl = await getDownloadURL(newFileRef);    
  
      // Update Firestore database
      await setDoc(doc(firestore, 'files', String(newFileNumber)), {
        createAt: new Date(),
        documentType: fileType,
        name: uniqueFileName,
        qnaUrl: newQnaUrl,
        refPath: newFileRefPath,
        shareWithPublic: ShareWithPublic,
        size,
        summaryUrl: newSummaryUrl,
        thumbnailUrl: newThumbnailUrl,
        transcriptUrl: newTranscriptUrl,
        url: newUrl,
        tags: Tags,
        youtubeTitle: YoutubeTitle,        
        userId: receiverUID,
        viewCount: 0,
        youtubeUrl: fileType === 'YouTube Video' ? fileUrl : null,
      });
  
      // Update the notification to mark as accepted
      await updateDoc(doc(firestore, 'notifications', notificationId), {
        status: 'accepted',
        message: `File "${uniqueFileName}" has been accepted and added to your ${fileType === 'Note' ? 'notes' : fileType === 'Recording' ? 'recordings' : 'videos'}.`,
      });
  
      alert(`File "${uniqueFileName}" accepted and added to your ${fileType === 'Note' ? 'notes' : fileType === 'Recording' ? 'recordings' : 'videos'}.`);
    } catch (error) {
      console.error('Error accepting shared file:', error);
      alert('Failed to accept the file. Please try again.');
    }
  };
  

  const handleRejectShare = async (notificationId: string) => {
    const firestore = getFirestore();
    try {
      // Update the notification status to 'rejected'
      await updateDoc(doc(firestore, 'notifications', notificationId), { status: 'rejected' });

      // Send notification back to the sender
      await updateDoc(doc(firestore, 'notifications', notificationId), {
        status: 'rejected',
        message:'The question has been rejected.',
      });

      alert('Question sharing rejected.');
    } catch (error) {
      console.error('Error rejecting question share:', error);
    }
  };


  const addQuestionToSubject = async ( notificationId: string,  question: string,options: { [key: string]: string } | undefined, answer: string) => {
    if (!selectedSubject) return;
  
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
  
    try {
      const url = await getDownloadURL(subjectRef);
      const response = await fetch(url);
      const existingData = await response.json();
      const firestore = getFirestore();
    
        const newQuestion: QuestionType = {
          question: question,
          answer: answer,
          options: options || {} // Handle case where options may be undefined
        };
    
        console.log('new question',newQuestion)
    
        const updatedData = [...existingData, newQuestion];
    
        await uploadString(subjectRef, JSON.stringify(updatedData), 'raw', {
          contentType: 'application/json',
        });
    
        alert('Question added to the library successfully!');
    
        await updateDoc(doc(firestore, 'notifications', notificationId), { status: 'accepted' });
    
        await updateDoc(doc(firestore, 'notifications', notificationId), {
          status: 'accepted',
          message: `Question "${question}" has been accepted and added to your library.`,
    
        });

      } catch (error) {
        console.error('Error adding question to library:', error);
        alert('Failed to add question to the library.');
      }
    
    setIsLibraryModalOpen(false);
  };

  const formatDate = (date: Date) => {
    return date.toLocaleString();
  };

  const handleRemoveAccount = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const confirmDelete = window.confirm(
      'Are you sure you want to delete your account? This action will remove all your files and cannot be undone.'
    );
  
    if (confirmDelete) {
      try {
        const userUID = currentUser.uid;
        const storagePaths = [
          'uploads',
          'library',
          'Q&A',
          'summaries',
          'chatImages',
          'recordingTranscript',
          'recordings',
          'thumbnails',
          'videos',
          'videosTranscript',
        ];
        const db = getFirestore();
  
        // Function to delete files from a specific storage folder
        const deleteFilesInFolder = async (folderPath: string) => {
          const folderRef = ref(storage, folderPath);
          const res = await listAll(folderRef);
  
          // Filter files that belong to the user and delete them
          const userFiles = res.items.filter((item: StorageReference) => item.name.includes(userUID));
          await Promise.all(
            userFiles.map(async (item: StorageReference) => {
              const fileRef = ref(storage, item.fullPath);
              await deleteObject(fileRef).catch((error) =>
                console.error(`Error deleting ${item.name} from ${folderPath}:`, error)
              );
            })
          );
        };
  
        // Iterate through each folder and delete user's files
        await Promise.all(storagePaths.map(deleteFilesInFolder));
  
        // Remove associated files in the Firestore `files` collection
        const filesQuery = query(collection(db, 'files'), where('userId', '==', userUID));
        const querySnapshot = await getDocs(filesQuery);
  
        await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            await deleteDoc(docSnapshot.ref).catch((error) =>
              console.error(`Error deleting file document ${docSnapshot.id}:`, error)
            );
          })
        );
  
        console.log('Associated file documents deleted');
  
        // Remove the user's document from Firestore
        await deleteDoc(doc(db, 'users', userUID));
        console.log('User document deleted');
  
        // Delete the user from Firebase Authentication
        await deleteUser(currentUser);
  
        navigate('/');
      } catch (error) {
        console.error('Error removing account:', error);
        alert('Failed to delete account. Please try again.');
      }
    }
  };
  
  const handleLogout = async () => {
    try {
      const firestore = getFirestore(); // Initialize Firestore
      const currentUser = auth.currentUser;
      
      if (currentUser?.isAnonymous) {
        const userUID = currentUser.uid;
  
        // Delete uploaded files
        const listRef = ref(storage, 'uploads/');
        const res = await listAll(listRef);
        const userFiles = res.items.filter(item => item.name.includes(userUID));
  
        for (const file of userFiles) {
          await deleteObject(ref(storage, file.fullPath));
        }
  
        // Delete generated Q&A files
        const listRef1 = ref(storage, 'Q&A/');
        const res1 = await listAll(listRef1);
        const userFiles1 = res1.items.filter(item => item.name.includes(userUID));
  
        for (const file of userFiles1) {
          await deleteObject(ref(storage, file.fullPath));
        }
  
        // Remove the Firestore document for the anonymous user
        const userDocRef = doc(firestore, 'users', userUID);
        await deleteDoc(userDocRef);
        
        await currentUser.delete(); // Delete the anonymous user
      }
  
      await auth.signOut();
      setIsLoggedIn(false);
      navigate('/'); 
  
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred during logout. Please try again.');
    }
  };
  
  const handleLoginButtonClick = () => {
    navigate('/front'); // Redirect to the /front page for login
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img 
          src={Logo} 
          alt="App Logo" 
          className="navbar-logo"
          onClick={() => navigate('/home')} 
        />
        <h1 className="navbar-title" onClick={() => navigate('/home')}>
          CompreQnA
        </h1>
      </div>

      <div className="navbar-right">
        {!auth.currentUser?.isAnonymous && auth.currentUser && (
          <>
            <div className="notification-wrapper"  title="Notifications" >
              <FaBell 
                className="navbar-notification-icon"
                size={32} 
                onClick={toggleNotifications}
              />
              {unreadCount > 0 && (
                <span className="notification-count">
                  {unreadCount}
                </span>
              )}
            </div>

            {showNotifications && (
              <div className="notification-panel" ref={notificationRef}>
                {notifications.length === 0 ? (
                  <p>No new notifications</p>
                ) : (
                  notifications.map(notification => (
                    <div 
                      key={notification.id} 
                      className={`notification-item ${!notification.isRead ? 'unread-notification' : ''}`}
                    >
                      <p><strong>{notification.message}</strong></p>
                      <p className="notification-date">{formatDate(notification.createdAt)}</p>

                      {isLibraryModalOpen && (
                          <div className="modal-overlay">
                            <div className="modal-content stylish-modal">
                              <h3 className="modal-title">Select Subject to Add Question</h3>

                              <select
                                value={selectedSubject || ''}
                                onChange={(e) => setSelectedSubject(e.target.value)}
                                className="stylish-select"
                              >
                                <option value="" disabled>Select a subject</option>
                                {subjects.sort().map((subject, index) => (
                                  <option key={index} value={subject}>{subject}</option>
                                ))}
                              </select>

                              <div className="modal-actions">
                                <button className="cancel-button stylish-button" onClick={() => setIsLibraryModalOpen(false)}>
                                  Cancel
                                </button>
                                <button className="add-button stylish-button" onClick={() => addQuestionToSubject(notification.id, notification.question,notification.options,notification.answer)}>
                                  Add to Library
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                      {notification.status === 'pending' && (
                        <div>
                          <button onClick={() => handleAcceptShare(notification.id, notification.fileUrl, notification.fileName, notification.fileType)}>Accept</button>
                          <button onClick={() => handleRejectShare(notification.id)}>Reject</button>
                        </div>
                      )}
                      {notification.status !== 'pending' && <p>Status: {notification.status}</p>}
                    </div>
                  ))
                )}
              </div>
            )}

            <img
              src={userImage}
              alt="User Thumbnail"
              className="navbar-user-image"
              onClick={toggleMenu}
            />
            {showMenu && (
              <div className="navbar-dropdown-menu" ref={menuRef}>

                <p className="navbar-dropdown-item" onClick={() => navigate('/profile')}>
                  <FaUser className="navbar-dropdown-icon" />
                  <strong>{userName}</strong>
                </p>

                
                <p className="navbar-dropdown-item" onClick={() => navigate('/my-plan')}>
                  <MdOutlineListAlt className="navbar-dropdown-icon" />
                  My Plan
                </p>

                {(subscriptionStatus === 'Premium'|| subscriptionStatus === 'Chat' || subscriptionStatus === 'ProChat')  && (
                  <p className="navbar-dropdown-item" onClick={() => navigate('/billing-account')}>
                    <FaCreditCard className="navbar-dropdown-icon" />
                    Subscriptions
                  </p>
                )}

               {isAdmin && (
                  <p className="navbar-dropdown-item" onClick={openAdminModal}>
                    <FaTools className="navbar-dropdown-icon" />
                    Admin
                  </p>
                )}


              <p className="navbar-dropdown-item" onClick={handleLogout} >
                  <FaSignOutAlt className="navbar-dropdown-icon" />
                  Log Out
              </p>

               {/* Babylon.js canvas
                <p className="navbar-dropdown-item" onClick={() => navigate('/settings')}>
                  <FaCog className="navbar-dropdown-icon" />
                  Settings
                </p>
                */}

                <p className="navbar-dropdown-item remove-account" onClick={handleRemoveAccount}>
                  <FaTrash className="navbar-dropdown-icon" />
                  Remove Account
                </p>

              </div>         
            )}
            {adminModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content admin-modal">
                        <h2 style={{ color: 'white' }}>Admin Management</h2>
                        <p style={{ color: 'white' }}>Counts of Starter Plan Users: {starterUserCount}</p>
                        <p style={{ color: 'white' }}>Counts of Premium Plan Users: {premiumUserCount}</p>  
                        <p style={{ color: 'white' }}>Counts of Chat Plan Users: {chatUserCount}</p>                                          
                        <button onClick={() => setAdminModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}
          </>
        )}

         {!isLoggedIn &&(
              <button
                className="login-button"
                onClick={handleLoginButtonClick}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                title="Login"
              >
                Login
              </button>
            )}

      </div>
    </div>
  );
};

export default NavBar;
